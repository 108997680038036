import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Card,
	Col,
	FormControl,
	InputGroup,
	Row,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import useToast from "../../../../hooks/Toast";
import { useAuth } from "../../../../contexts/AuthContext";
import DashTitle from "../../components/DashTitle";
import { isEmail, isPhoneNumber, maskPhone } from "../../../../utils/string";
import UserService from "../../../../modules/security/user/UserService";
import { Role } from "../../../../modules/security/permission/enum/role.enum";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const service = new UserService();

const NewUser: React.FC = () => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();
	const navigate = useNavigate();

	const [enableSaveUser, setEnableSaveUser] = useState(true);

	const [loading, setLoading] = useState(false);
	const [blocked, setBlocked] = useState(false);

	const [user_name, setUser_name] = useState("");
	const [user_email, setUser_email] = useState("");
	const [user_phoneNumber, setUser_phoneNumber] = useState("");

	const handleCreateUser = async () => {
		if (!hasPermission(Role.ADM_CREATE_USER)) {
			showError("Permissão negada.");
			return;
		}
		if (!user_name) {
			showError("Nome");
			return;
		}
		setLoading(true);
		try {
			const { id } = await service.createUser({
				name: user_name,
				email: user_email,
				phoneNumber: user_phoneNumber.replace(/\D/g, "")
					? `55${user_phoneNumber.replace(/\D/g, "")}`
					: undefined,
			});
			setLoading(false);
			navigate(`/administration/user/${id}`);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const verifyUser = useCallback(() => {
		// E-mail
		if (user_email && !isEmail(user_email)) return false;
		// Telefone
		if (user_phoneNumber && !isPhoneNumber(user_phoneNumber)) return false;

		return true;
	}, [user_email, user_phoneNumber]);

	useEffect(
		() => setBlocked(!hasPermission(Role.ADM_CREATE_USER)),
		[hasPermission]
	);

	/**
	 * Verifica se os dados do usuário são válidos e habilita o botão de salvar.
	 */
	useEffect(() => {
		setEnableSaveUser(verifyUser());
	}, [verifyUser]);

	return (
		<div>
			<DashTitle title="Usuário" />
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<InputGroup className="mb-3">
								<InputGroup.Text>Nome</InputGroup.Text>
								<FormControl
									value={user_name}
									onChange={(e) => setUser_name(e.target.value)}
									disabled={loading || blocked}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>E-mail</InputGroup.Text>
								<FormControl
									value={user_email}
									onChange={(e) => setUser_email(e.target.value)}
									disabled={loading || blocked}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Telefone</InputGroup.Text>
								<FormControl
									value={user_phoneNumber}
									onChange={(e) => setUser_phoneNumber(maskPhone(e.target.value))}
									disabled={loading || blocked}
								/>
							</InputGroup>
							<Button
								disabled={!enableSaveUser}
								variant="danger"
								onClick={() => handleCreateUser()}
							>
								Salvar
							</Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{loading && <LoadingSpinner />}
		</div>
	);
};

export default NewUser;
