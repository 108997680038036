import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import { logEvent, setCurrentScreen } from "firebase/analytics";
import { getAnalytics } from "../../utils/firebase";
import AppStoreBadge from "../../components/AppStoreBadge";
import GooglePlayBadge from "../../components/GooglePlayBadge";
import {
	composeAppStoreUrl,
	composePlayStoreUrl,
} from "../../utils/mobile-store-util";
import VividusCardPage from "../../components/VividusCardPage";
import FooterBar from "../DashboardPage/components/FooterBar/FooterBar";

const analytics = getAnalytics();

const ReferralCodePage: React.FC = () => {
	const [search] = useSearchParams();

	const getReferralCode = useCallback(
		() => search.get("code") || "SITE",
		[search]
	);

	useEffect(() => {
		if (isIOS) {
			logEvent(analytics, "referral_redirect", {
				store: "app-store",
				referralCode: getReferralCode(),
			});
			window.location.replace(composeAppStoreUrl());
		} else if (isAndroid) {
			logEvent(analytics, "referral_redirect", {
				store: "google-play",
				referralCode: getReferralCode(),
			});
			window.location.replace(composePlayStoreUrl());
		}
	}, [getReferralCode]);

	useEffect(() => setCurrentScreen(analytics, "ReferralCodePage"), []);

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "#D3D0DC" }}>
			<VividusCardPage>
				<div className="text-center">
					<div className="btn">
						<GooglePlayBadge referralCode={getReferralCode()} />
					</div>
					<div className="btn">
						<AppStoreBadge referralCode={getReferralCode()} />
					</div>
				</div>
			</VividusCardPage>
			<FooterBar type="summary" />
		</div>
	);
};

export default ReferralCodePage;
