import React, { useEffect, useCallback, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import AccessProfileService from "../../../../modules/security/access-profile/AccessProfileService";
import AccessProfile from "../../../../modules/security/access-profile/entities/AccessProfile";
import PaginationTable from "../../../../components/PaginationTable";
import PaginationRow from "../../../../components/PaginationTable/PaginationRow";
import PaginationColumn from "../../../../components/PaginationTable/PaginationColumn";
import ResponsePaginationDto from "../../../../apis/VividusAPI/dto/ResponsePaginationDto";

const service = new AccessProfileService();

const AccessProfilePanel: React.FC = () => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] =
		useState<ResponsePaginationDto<AccessProfile>>();

	const fetchData = useCallback(() => {
		setLoading(true);
		service
			.list({ take: limit, page, search })
			.then((documents) => {
				setPagination(documents);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [page, limit, showError, search]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Container fluid>
			<DashTitle title="Perfil de Acesso" />
			{loading && <LoadingSpinner />}
			{pagination && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<Row className="mb-2">
									{hasPermission("adm-create-access-profile") && (
										<Col sm={5} hidden>
											<Button type="button" variant="danger" className="mb-2">
												<i className="mdi mdi-plus-circle me-2" /> Novo
											</Button>
										</Col>
									)}
								</Row>
								<Row className="mb-2">
									<PaginationTable
										idTable="access-profile-table"
										headers={["Nome", "Descrição"]}
										onLimitChange={setLimit}
										onSearchChange={setSearch}
										onPageChange={(p) => setPage(p)}
										meta={pagination?.meta}
										limit={limit}
									>
										{pagination.data.map((item) => (
											<PaginationRow
												key={`${item.id!}`}
												link={`/administration/access-profile/${item.id}`}
											>
												<PaginationColumn>{item.name}</PaginationColumn>
												<PaginationColumn>{item.description}</PaginationColumn>
											</PaginationRow>
										))}
									</PaginationTable>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default AccessProfilePanel;
