import React from "react";

export interface BasicColumnProps {
	colSpan?: number;
	rowSpan?: number;
}

const BasicColumn: React.FC<BasicColumnProps> = ({
	children,
	colSpan,
	rowSpan,
}) => (
	<td colSpan={colSpan || 1} rowSpan={rowSpan || 1}>
		{children}
	</td>
);

BasicColumn.defaultProps = {
	colSpan: 1,
	rowSpan: 1,
};

export default BasicColumn;
