import React from "react";
import { Button, Modal } from "react-bootstrap";

interface ModalStatusUserProps {
	show: boolean;
	setShow: (loading: boolean) => void;
	handleConfirm: () => Promise<void>;
}

const ModalSyncAuth: React.FC<ModalStatusUserProps> = ({
	show,
	setShow,
	handleConfirm,
}) => (
	<Modal show={show} onHide={() => setShow(false)}>
		<Modal.Header closeButton>
			<Modal.Title>Deseja vincular o usuário ao Authentication?</Modal.Title>
		</Modal.Header>
		<Modal.Footer>
			<Button variant="secondary" onClick={() => setShow(false)}>
				Cancelar
			</Button>
			<Button variant="primary" onClick={() => handleConfirm()}>
				Prosseguir
			</Button>
		</Modal.Footer>
	</Modal>
);

export default ModalSyncAuth;
