import VividusAPI from "../../../apis/VividusAPI";
import BodyPatchBillingLotDto from "../../../apis/VividusAPI/dto/BodyPatchBillingLotDto";
import QueryGetBillingLotDto from "../../../apis/VividusAPI/dto/QueryGetBillingLotDto";
import QueryGetBillingLotPaginatedDto from "../../../apis/VividusAPI/dto/QueryGetBillingLotPaginatedDto";

export default class BillingLotService {
	list(query: QueryGetBillingLotPaginatedDto = {}) {
		return VividusAPI.getBillingLotPaginated(query);
	}

	createBillingLot(startDate: Date, endDate: Date) {
		return VividusAPI.postBillingLot({ startDate, endDate });
	}

	getDraftBillingLot(id: number) {
		return VividusAPI.getDraftBillingLot(id);
	}

	fetchById(id: number, params: QueryGetBillingLotDto = {}) {
		return VividusAPI.getBillingLot(id, params);
	}

	addItemBillingLot(id: number, transaction_id: number) {
		return VividusAPI.postAddBillingLotItem(id, { transaction_id });
	}

	removeItemBillingLot(billingLot_id: number, billingLotItem_id: number) {
		return VividusAPI.deleteRemoveBillingLotItem(
			billingLot_id,
			billingLotItem_id
		);
	}

	updateBillingLot(id: number, body: BodyPatchBillingLotDto) {
		return VividusAPI.patchBillingLot(id, body);
	}

	deleteBillingLot(id: number) {
		return VividusAPI.deleteBillingLot(id);
	}

	closeBillingLot(id: number) {
		return VividusAPI.putCloseBillingLot(id);
	}

	writeOffBillingLot(id: number) {
		return VividusAPI.putWriteOffBillingLot(id);
	}
}
