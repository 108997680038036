import React from "react";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";

export type ExportCSVData = string | Data | (() => string | Data);

const ExportCSVButton: React.FC<{
	filename?: string;
	data?: ExportCSVData;
	handleExport?: () => boolean;
}> = ({ filename = "export", data = [[]], handleExport }) => (
	<CSVLink
		data={data}
		type="button"
		separator=";"
		data-bs-container="#tooltip-pdf"
		data-bs-toggle="tooltip"
		data-bs-placement="bottom"
		title="Exportar para CSV"
		style={{ textDecoration: "none" }}
		onClick={() => {
			if (handleExport) return handleExport();
			return true;
		}}
		filename={`${filename}.csv`}
		className="btn btn-success mb-2 me-1 mdi mdi-file-table"
	/>
);

export default ExportCSVButton;
