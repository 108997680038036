enum Gender {
	Male = "M",
	Female = "F",
}

export default Gender;

export const getGender = (gender: string) => {
	if (!gender) return null;
	return gender as Gender;
};
