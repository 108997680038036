import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import PaginationTable from "../../../../components/PaginationTable";
import PaginationRow from "../../../../components/PaginationTable/PaginationRow";
import PaginationColumn from "../../../../components/PaginationTable/PaginationColumn";
import ResponsePaginationDto from "../../../../apis/VividusAPI/dto/ResponsePaginationDto";
import BillingLotService from "../../../../modules/finance/billing-lot/BillingLotService";
import { BillingLot } from "../../../../modules/finance/billing-lot/entities/BillingLot";
import { getBillingLotStatusName } from "../../../../modules/finance/billing-lot/enum/status.enum";

const service = new BillingLotService();

const BillingLotPanel: React.FC = () => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] =
		useState<ResponsePaginationDto<BillingLot>>();

	const fetchData = useCallback(() => {
		setLoading(true);
		service
			.list({ take: limit, page, search })
			.then((documents) => {
				setPagination(documents);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [page, limit, showError, search]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Container fluid>
			<DashTitle title="Lotes de Faturamento" />
			{loading && <LoadingSpinner />}
			{pagination && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<Row className="mb-2">
									<PaginationTable
										idTable="billing-lot-table"
										headers={[
											"Lote",
											"Início",
											"Fim",
											"Status",
											"D. Fechado",
											"D. Faturado",
										]}
										onLimitChange={setLimit}
										onSearchChange={setSearch}
										onPageChange={(p) => setPage(p)}
										meta={pagination?.meta}
										limit={limit}
									>
										{pagination.data.map((item) => (
											<PaginationRow
												key={`${item.id!}`}
												link={`/billing/billing-lot/${item.id}`}
											>
												<PaginationColumn>{item.lotNumber}</PaginationColumn>
												<PaginationColumn>
													{DateTime.fromISO(item.startDate).toFormat("dd/MM/yyyy")}
												</PaginationColumn>
												<PaginationColumn>
													{DateTime.fromISO(item.endDate).toFormat("dd/MM/yyyy")}
												</PaginationColumn>
												<PaginationColumn>
													{getBillingLotStatusName(item.status)}
												</PaginationColumn>
												<PaginationColumn>
													{item.closeDate
														? DateTime.fromISO(item.closeDate).toFormat("dd/MM/yyyy")
														: ""}
												</PaginationColumn>
												<PaginationColumn>
													{item.writeOffDate
														? DateTime.fromISO(item.writeOffDate).toFormat("dd/MM/yyyy")
														: ""}
												</PaginationColumn>
											</PaginationRow>
										))}
									</PaginationTable>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default BillingLotPanel;
