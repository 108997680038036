import React, { useCallback, useEffect, useState } from "react";
import {
	Alert,
	Card,
	Col,
	Container,
	Form,
	FormControl,
	InputGroup,
	Row,
} from "react-bootstrap";
import { useMatch } from "react-router-dom";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import AccessProfile from "../../../../modules/security/access-profile/entities/AccessProfile";
import AccessProfileService from "../../../../modules/security/access-profile/AccessProfileService";
import PermissionsGroupService from "../../../../modules/security/permission-group/PermissionsGroupService";
import BasicTable from "../../../../components/BasicTable";
import PermissionsGroup from "../../../../modules/security/permission-group/entities/PermissionsGroup";
import BasicRow from "../../../../components/BasicTable/BasicRow";
import BasicColumn from "../../../../components/BasicTable/BasicColumn";
import { Role } from "../../../../modules/security/permission/enum/role.enum";

const AccessProfileModalPanel: React.FC = () => {
	const match = useMatch("/administration/access-profile/:id");
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [permissionsGroups, setPermissionsGroups] =
		useState<PermissionsGroup[]>();

	const [loading, setLoading] = useState(true);

	const [notFinded, setNotFinded] = useState(false);

	const [accessProfile_name, setAccessProfile_name] = useState("");
	const [accessProfile_description, setAccessProfile_description] = useState("");
	const [permissions, setPermissions] = useState<Role[]>([]);

	const getId = useCallback(() => match?.params.id || "", [match?.params.id]);

	const updatePermission = async (permission: Role, value: boolean) => {
		if (!getId()) return;
		if (value) {
			setPermissions([...permissions, permission]);
			await new AccessProfileService().addPermission(+getId(), permission);
		} else {
			setPermissions(permissions.filter((perm) => perm !== permission));
			await new AccessProfileService().removePermission(+getId(), permission);
		}
	};

	const setAccessProfile = (accessProfile: AccessProfile) => {
		setAccessProfile_name(accessProfile.name || "");
		setAccessProfile_description(accessProfile.description || "");
		setPermissions(accessProfile.permissions?.map((p) => p.code) || []);
	};

	useEffect(() => {
		if (!hasPermission("adm-read-access-profile")) {
			showError("Permissão negada.");
			return;
		}
		const id = getId();
		if (!id) {
			showError("Registro não informado.");
			return;
		}
		new AccessProfileService()
			.fetchById(+id, { loadPermission: true, loadPermissionsGroup: true })
			.then(async (profile) => {
				if (!profile) {
					setNotFinded(true);
					return;
				}
				setPermissionsGroups(
					await new PermissionsGroupService()
						.list({ loadPermission: true })
						.then((pagination) => pagination.data)
						.catch((error) => {
							showError(error);
							return [];
						})
				);
				setAccessProfile(profile);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [match, hasPermission, showError, getId]);

	return (
		<Container fluid>
			<DashTitle title="Perfil de Acesso" showGoBackButton />
			{loading && <LoadingSpinner />}
			{notFinded && <Alert variant="info">Registro não encontrado.</Alert>}
			{!loading && !notFinded && (
				<>
					<Row>
						<Col d={12}>
							<Card>
								<Card.Body>
									<InputGroup className="mb-3">
										<InputGroup.Text>Nome</InputGroup.Text>
										<FormControl
											value={accessProfile_name || ""}
											onChange={(e) => setAccessProfile_name(e.target.value)}
											disabled={loading}
										/>
									</InputGroup>
									<InputGroup className="mb-3">
										<InputGroup.Text>Descrição</InputGroup.Text>
										<FormControl
											value={accessProfile_description || ""}
											onChange={(e) => setAccessProfile_description(e.target.value)}
											disabled={loading}
										/>
									</InputGroup>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{permissionsGroups && (
						<Row>
							<Col md={12}>
								<Card>
									<Card.Body>
										<BasicTable headers={["Código", "Nome", "Descrição", "Ativo"]}>
											{permissionsGroups.map((item) => (
												<>
													<BasicRow key={`group-${item.id!}`} dark>
														<BasicColumn colSpan={4}>
															<strong>{item.name}</strong>
														</BasicColumn>
													</BasicRow>
													{item.permissions.map((permission) => (
														<BasicRow key={`perm-${permission.id!}`}>
															<BasicColumn>{permission.id}</BasicColumn>
															<BasicColumn>{permission.name}</BasicColumn>
															<BasicColumn>{permission.description}</BasicColumn>
															<BasicColumn>
																<Form.Check
																	id={`check-${permission.id}`}
																	checked={permissions.includes(permission.code)}
																	onChange={(e) =>
																		updatePermission(permission.code, e.target.checked)
																	}
																/>
															</BasicColumn>
														</BasicRow>
													))}
												</>
											))}
										</BasicTable>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					)}
				</>
			)}
		</Container>
	);
};

export default AccessProfileModalPanel;
