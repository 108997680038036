import React from "react";

export interface BasicRowProps {
	dark?: boolean;
}

const BasicRow: React.FC<BasicRowProps> = ({ children, dark }) => (
	<tr className={dark ? "table-dark" : ""}>{children}</tr>
);

BasicRow.defaultProps = {
	dark: false,
};

export default BasicRow;
