import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import VividusCardPage from "../../components/VividusCardPage";

const ForbiddenPage: React.FC = () => {
	const navigate = useNavigate();
	return (
		<VividusCardPage>
			<div className="text-center">
				<h1 className="text-error">403</h1>
				<h4 className="text-uppercase text-danger mt-3">Não autorizado</h4>
				<p>
					<Button
						variant="primary"
						className="mt-2"
						type="button"
						onClick={(e) => {
							e.preventDefault();
							navigate("/");
						}}
					>
						Voltar para o início
					</Button>
				</p>
			</div>
		</VividusCardPage>
	);
};

export default ForbiddenPage;
