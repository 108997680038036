import { VividusError } from "../errors";

export type ErrorDetails = {
	message: string;
	status?: number;
	data?: any;
};

export const getErrorDetails = (
	message: string | unknown
): [ErrorDetails, string] => {
	if (typeof message === "string") {
		return [
			{
				message,
			},
			"",
		];
	}
	if (message instanceof VividusError) {
		return [
			{
				status: message.status,
				message: message.message,
				data: message.data,
			},
			"VividusError",
		];
	}
	if (message instanceof Error) {
		return [
			{
				message: message.message,
			},
			message.name || "Erro Interno",
		];
	}
	return [
		{
			message: JSON.stringify(message),
		},
		"",
	];
};
