import { AuthDto } from "../modules/security/auth/dto/AuthDto";
import VividusAPI from "./VividusAPI";

export const clearAPIS = () => {
	VividusAPI.clear();
};

export const initAPIS = (auth?: AuthDto) => {
	if (!auth || !auth.vividusJwt) {
		clearAPIS();
		return;
	}
	VividusAPI.init(auth);
};
