import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	FormControl,
	InputGroup,
	Form,
	Card,
	Row,
	Col,
	Accordion,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import useToast from "../../../../../../../../hooks/Toast";
import { Subscription } from "../../../../../../../../modules/finance/subscription/entities/Subscription";
import SubscriptionService from "../../../../../../../../modules/finance/subscription/SubscriptionService";
import { Role } from "../../../../../../../../modules/security/permission/enum/role.enum";
import {
	convertCodePlanToCodeIuguPlan,
	getPlanRecurrenceText,
	getSubscriptionPaymentTypeText,
	getSubscriptionTypeText,
} from "../../../../../../../../utils/string";
import ModalChangePlan from "../ModalChangePlan";

const subscriptionService = new SubscriptionService();

interface SubscriptionPanelProps {
	subscription: Subscription;
	eventKey: string;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	handleBeforeUpdate: () => void;
}

const SubscriptionPanel: React.FC<SubscriptionPanelProps> = ({
	subscription,
	eventKey,
	loading,
	setLoading,
	handleBeforeUpdate,
}) => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [subscription_iugu_id, setSubscription_iugu_id] = useState("");

	const handleChangeSubscription = async () => {
		if (!hasPermission(Role.ADM_UPD_SUBSCRIPTION)) {
			showError("Permissão negada.");
			return;
		}
		setShowModal(true);
	};

	const handleUpdateSubscription = async () => {
		if (!hasPermission(Role.ADM_UPD_SUBSCRIPTION)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await subscriptionService.update(subscription.id, {
				iugu_id: subscription_iugu_id,
			});
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const showUpdateSubscriberDetails = useCallback(
		() => hasPermission(Role.ADM_UPD_SUBSCRIPTION),
		[hasPermission]
	);

	const showUpdateButton = useCallback(
		(sub: Subscription) => showUpdateSubscriberDetails() && sub.id,
		[showUpdateSubscriberDetails]
	);

	useEffect(() => {
		setSubscription_iugu_id(subscription.iugu_id || "");
	}, [subscription]);

	return (
		<Accordion.Item eventKey={eventKey}>
			<Accordion.Header style={{ marginTop: "0px" }}>
				{subscription.plan.name}
			</Accordion.Header>
			<Accordion.Body>
				<ModalChangePlan
					subscription={subscription}
					setShow={setShowModal}
					show={showModal}
					handleBeforeUpdate={handleBeforeUpdate}
				/>
				<Form>
					<InputGroup className="mb-3">
						<InputGroup.Text>Plano</InputGroup.Text>
						<FormControl value={subscription.plan.name} readOnly />
					</InputGroup>
					<Row className="mb-3">
						<InputGroup as={Col}>
							<InputGroup.Text>Código Interno</InputGroup.Text>
							<FormControl value={subscription.plan.code} readOnly />
						</InputGroup>
						<InputGroup as={Col}>
							<InputGroup.Text>Código Externo</InputGroup.Text>
							<FormControl
								value={convertCodePlanToCodeIuguPlan(subscription.plan.code)}
								readOnly
							/>
						</InputGroup>
					</Row>
					<InputGroup>
						<InputGroup.Text>Recorrência</InputGroup.Text>
						<FormControl
							value={getPlanRecurrenceText(subscription.plan.code)}
							readOnly
						/>
					</InputGroup>
				</Form>
				<hr />
				<Form>
					<fieldset disabled={loading}>
						<InputGroup className="mb-3">
							<InputGroup.Text>Forma de Pagamento Padrão</InputGroup.Text>
							<FormControl
								value={getSubscriptionPaymentTypeText(subscription.paymentType)}
								readOnly
							/>
						</InputGroup>
						{!subscription.business && (
							<InputGroup className="mb-3">
								<InputGroup.Text>Código Iugu</InputGroup.Text>
								<FormControl
									value={subscription_iugu_id}
									onChange={(e) => setSubscription_iugu_id(e.target.value)}
								/>
								<OverlayTrigger
									delay={{ show: 250, hide: 400 }}
									placement="left"
									overlay={<Tooltip>Código da Assinatura na plataforma Iugu</Tooltip>}
								>
									<Button variant="secondary">
										<span className="mdi mdi-information" />
									</Button>
								</OverlayTrigger>
							</InputGroup>
						)}
						<Row className="mb-3">
							<InputGroup as={Col}>
								<InputGroup.Text>Tipo</InputGroup.Text>
								<FormControl
									readOnly
									value={getSubscriptionTypeText(subscription.business)}
								/>
							</InputGroup>
							<InputGroup as={Col}>
								<InputGroup.Text>Recorrência</InputGroup.Text>
								<FormControl
									readOnly
									value={getPlanRecurrenceText(subscription.plan.code)}
								/>
							</InputGroup>
						</Row>
						{showUpdateButton(subscription) && (
							<>
								<Button variant="primary" onClick={() => handleUpdateSubscription()}>
									Atualizar Assinatura
								</Button>{" "}
								<Button variant="primary" onClick={() => handleChangeSubscription()}>
									Trocar Plano
								</Button>
							</>
						)}
					</fieldset>
				</Form>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default SubscriptionPanel;
