import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const GoBackButton: React.FC = () => {
	const navigate = useNavigate();
	return (
		<Button
			variant="info"
			className="mb-2 me-1"
			type="button"
			data-bs-container="#tooltip-pdf"
			data-bs-toggle="tooltip"
			data-bs-placement="bottom"
			title="Voltar"
			onClick={(e) => {
				e.preventDefault();
				navigate(-1);
			}}
		>
			Voltar
		</Button>
	);
};

export default GoBackButton;
