enum UF {
	AC = "AC",
	AL = "AL",
	AP = "AP",
	AM = "AM",
	BA = "BA",
	CE = "CE",
	DF = "DF",
	ES = "ES",
	GO = "GO",
	MA = "MA",
	MT = "MT",
	MS = "MS",
	MG = "MG",
	PA = "PA",
	PB = "PB",
	PR = "PR",
	PE = "PE",
	PI = "PI",
	RJ = "RJ",
	RN = "RN",
	RS = "RS",
	RO = "RO",
	RR = "RR",
	SC = "SC",
	SP = "SP",
	SE = "SE",
	TO = "TO",
}

export default UF;

export const getUFName = (uf: UF) => {
	switch (uf) {
		case UF.AC:
			return "Acre";
		case UF.AL:
			return "Alagoas";
		case UF.AP:
			return "Amapá";
		case UF.AM:
			return "Amazonas";
		case UF.BA:
			return "Bahia";
		case UF.CE:
			return "Ceará";
		case UF.DF:
			return "Distrito Federal";
		case UF.ES:
			return "Espírito Santo";
		case UF.GO:
			return "Goiás";
		case UF.MA:
			return "Maranhão";
		case UF.MT:
			return "Mato Grosso";
		case UF.MS:
			return "Mato Grosso do Sul";
		case UF.MG:
			return "Minas Gerais";
		case UF.PA:
			return "Pará";
		case UF.PB:
			return "Paraíba";
		case UF.PR:
			return "Paraná";
		case UF.PE:
			return "Pernambuco";
		case UF.PI:
			return "Piauí";
		case UF.RJ:
			return "Rio de Janeiro";
		case UF.RN:
			return "Rio Grande do Norte";
		case UF.RS:
			return "Rio Grande do Sul";
		case UF.RO:
			return "Rondônia";
		case UF.RR:
			return "Roraima";
		case UF.SC:
			return "Santa Catarina";
		case UF.SP:
			return "São Paulo";
		case UF.SE:
			return "Sergipe";
		case UF.TO:
			return "Tocantins";
		default:
			return "";
	}
};
