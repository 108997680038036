import { Content, ContentText } from "pdfmake/interfaces";
import { DateTime } from "luxon";
import Dinero from "dinero.js";
import BaseReportService from "../BaseReportService";
import { maskCpf } from "../../../utils/string";
import { formatToLocaleCurrency } from "../../../utils/currency";
import ResponseSellerCommissionReportDto from "../../../apis/VividusAPI/dto/ResponseSellerCommissionReportDto";
import QueryGetSellerCommissionReportDto from "../../../apis/VividusAPI/dto/QueryGetSellerCommissionReportDto";
import VividusAPI from "../../../apis/VividusAPI";

export default class SellerCommissionReportService extends BaseReportService<
	ResponseSellerCommissionReportDto,
	QueryGetSellerCommissionReportDto
> {
	constructor() {
		super("Comissão de Vendedores");
	}

	getReport(
		query: QueryGetSellerCommissionReportDto
	): Promise<ResponseSellerCommissionReportDto[]> {
		return VividusAPI.getReportSellerCommission(query);
	}

	getDisplayUserType(userType: string) {
		switch (userType) {
			case "holder":
				return "Titular";
			case "dependent":
				return "Dependente";
			default:
				return "";
		}
	}

	protected getContent(data: ResponseSellerCommissionReportDto[]): Content {
		const header: Content = [
			{ text: "Nome", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Tipo de Usuário", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "CPF", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Vendedor", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Data", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Valor", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Parcela", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Parc. Atual", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Tipo de Plano", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{ text: "Comissão (%)", bold: true, fontSize: 9, margin: [0, 4, 0, 0] },
			{
				text: "Comissão (R$)",
				bold: true,
				fontSize: 9,
				margin: [0, 4, 0, 0],
			},
		];
		const body: ContentText[][] = data.map((i) => [
			{ text: i.userName, fontSize: 8 },
			{ text: this.getDisplayUserType(i.userType), fontSize: 8 },
			{ text: maskCpf(i.userCpf), fontSize: 8 },
			{ text: i.sellerName, fontSize: 8 },
			{
				text: DateTime.fromISO(i.registerDate).toFormat("dd/MM/yyyy HH:mm"),
				fontSize: 8,
			},
			{ text: formatToLocaleCurrency(i.value), fontSize: 8 },
			{ text: `${i.installment}`, fontSize: 8 },
			{ text: `${i.currentInstallment}`, fontSize: 8 },
			{ text: i.planType, fontSize: 8 },
			{ text: `${Math.round(i.commission * 100)}`, fontSize: 8 },
			{ text: formatToLocaleCurrency(i.valueCommission), fontSize: 8 },
		]);
		const { value: val, valueCommission: valCom } = data
			.map(({ value, valueCommission }) => ({ value, valueCommission }))
			.reduce((prev, current) => ({
				value: Dinero({ amount: prev?.value ?? 0 })
					.add(Dinero({ amount: current.value }))
					.getAmount(),
				valueCommission: Dinero({ amount: prev?.valueCommission ?? 0 })
					.add(Dinero({ amount: current.valueCommission }))
					.getAmount(),
			}));
		body.push([
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: formatToLocaleCurrency(val), fontSize: 8, bold: true },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: "", fontSize: 8 },
			{ text: formatToLocaleCurrency(valCom), fontSize: 8, bold: true },
		]);

		return {
			layout: "noBorders",
			table: {
				headerRows: 1,
				widths: ["*", 45, 60, 45, 45, 40, 40, 40, 45, 45, 40],

				body: [header, ...body],
			},
		};
	}
}
