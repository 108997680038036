import React, { useContext, useState, createContext, useMemo } from "react";

export interface AppContextData {
	lang: string;
}

export const AppContext = createContext<AppContextData>({} as any);

export const AppProvider: React.FC = ({ children }) => {
	const [lang] = useState("pt_br");

	const value = useMemo<AppContextData>(
		() => ({
			lang,
		}),
		[lang]
	);

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useApp() {
	return useContext(AppContext);
}
