import React, { useState } from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VividusCardPage from "../../components/VividusCardPage";
import { useAuth } from "../../contexts/AuthContext";
import useToast from "../../hooks/Toast";
import { isEmail } from "../../utils/string";
import FooterBar from "../DashboardPage/components/FooterBar/FooterBar";

const invalidBorder = "1px solid #f44336";
const validBorder = "1px solid #4caf50";

const ResetPasswordPage: React.FC = () => {
	const { sendEmailResetPassword, loaded } = useAuth();
	const { showError } = useToast();
	const navigate = useNavigate();

	const [emailBorder, setEmailBorder] = useState("1px solid #dee2e6");

	const [email, setEmail] = useState("");

	const [loading, setLoading] = useState(false);

	const handleChangeEmail = (value: string) => {
		if (!value || !isEmail(value)) {
			setEmailBorder(invalidBorder);
		} else {
			setEmailBorder(validBorder);
		}
		setEmail(value);
	};

	const sendEmail = async () => {
		if (!isEmail(email)) {
			toast.error("E-mail inválido.");
			return;
		}
		setLoading(true);
		try {
			await sendEmailResetPassword(email);
			toast.success("E-mail enviado.");
			navigate("/login", { replace: true });
		} catch (error: any) {
			setLoading(false);
			if (error.code === "auth/user-not-found") {
				showError("Usuário não encontrado.");
				return;
			}
			if (["auth/invalid-email", "auth/wrong-password"].includes(error.code)) {
				showError("E-mail ou senha inválidos.");
				return;
			}
			showError(error);
		}
	};

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "#D3D0DC" }}>
			{loading || !loaded ? (
				<Container
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: "100vh" }}
				>
					<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Container>
			) : (
				<VividusCardPage>
					<Card.Body className="p-4">
						<Form>
							<div className="text-center w-75 m-auto mb-3">
								<h4 className="text-dark-50 text-center mt-0 fw-bold">
									Recuperar senha
								</h4>
							</div>
							<Form.Group className="mb-3">
								<Form.Label>E-mail</Form.Label>
								<Form.Control
									type="email"
									required
									placeholder="Informe seu email"
									autoComplete="on"
									value={email}
									onChange={(e) => handleChangeEmail(e.target.value)}
									style={{
										border: emailBorder,
									}}
								/>
							</Form.Group>

							<div className="mb-3 mb-0 text-center">
								<Button
									type="button"
									variant="primary"
									className="btn btn-primary"
									onClick={(event) => {
										event.preventDefault();
										sendEmail();
									}}
								>
									{" "}
									Recuperar senha{" "}
								</Button>
							</div>
						</Form>
					</Card.Body>
				</VividusCardPage>
			)}
			<FooterBar type="summary" />
		</div>
	);
};

export default ResetPasswordPage;
