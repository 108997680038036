import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	createContext,
	useMemo,
} from "react";
import {
	getAuth,
	signOut,
	setPersistence,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	browserSessionPersistence,
	browserLocalPersistence,
	onAuthStateChanged,
	User as AuthUser,
} from "firebase/auth";
import UserService from "../modules/security/user/UserService";
import { UnauthorizedError, UserNotFoundError } from "../errors";
import { initAPIS as initAPIs } from "../apis";
import { AuthDto } from "../modules/security/auth/dto/AuthDto";
import AuthService from "../modules/security/auth/AuthService";

export type Permission = string | RegExp | (string | RegExp)[];

export interface AuthContextData {
	signed: boolean;
	loaded: boolean;
	auth?: AuthDto;
	currentUser?: AuthUser;
	login(
		email: string,
		password: string,
		rememberMe: boolean
	): Promise<AuthDto | undefined>;
	sendEmailResetPassword(email: string): Promise<void>;
	logout(): Promise<void>;
	hasPermission(permissions: Permission): boolean;
	containsPermission(permissions: Permission): boolean;
	isMaster(): boolean;
}

export const AuthContext = createContext<AuthContextData>({} as any);

const userService = new UserService();
const authService = new AuthService();

export const AuthProvider: React.FC = ({ children }) => {
	const [auth, setAuth] = useState<AuthDto>();
	const [signed, setSigned] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<AuthUser>();
	/**
	 * Utilizado apenas para indicar quando o contexto foi carregado.
	 */
	const [loaded, setLoaded] = useState<boolean>(false);

	const logout = () => signOut(getAuth());

	const setAuthUser = useCallback(
		async (user?: AuthUser): Promise<AuthDto | undefined> => {
			try {
				if (user?.uid) {
					if (currentUser && user.uid === currentUser.uid) return auth;
					const { exists } = await authService.getVerifyByEmail(user.email!);
					if (!exists) {
						logout();
						return undefined;
					}
					const { id, accesstoken, isMaster, permissions } =
						await authService.fetchToken(user.uid);
					if (!isMaster && !permissions.includes("web-app-access")) {
						logout();
						throw new UnauthorizedError();
					}
					const userLocal = await userService.fetchById(id, accesstoken);
					const authLocal: AuthDto = {
						vividusJwt: accesstoken,
						permissions,
						isMaster,
						user: {
							id,
							name: userLocal.name,
							avatar: userLocal.avatarUrl,
						},
					};
					if (userLocal.seller)
						authLocal.seller = {
							id: userLocal.seller.id,
							group_id: userLocal.seller.sellerGroup?.id,
						};
					setCurrentUser(user);
					setAuth(authLocal);
					initAPIs(authLocal);
					setLoaded(true);
					return authLocal;
				}
			} catch (error) {
				// Busca o usuário no firestore
				setCurrentUser(undefined);
				setAuth(undefined);
				initAPIs(undefined);
				setLoaded(true);
				throw error;
			}
			// Busca o usuário no firestore
			setCurrentUser(undefined);
			setAuth(undefined);
			initAPIs(undefined);
			setLoaded(true);
			return undefined;
		},
		[auth, currentUser]
	);

	const login = useCallback(
		async (
			email: string,
			password: string,
			rememberMe: boolean
		): Promise<AuthDto | undefined> => {
			const authFirebase = getAuth();
			await setPersistence(
				authFirebase,
				rememberMe ? browserLocalPersistence : browserSessionPersistence
			);
			const crendetials = await signInWithEmailAndPassword(
				authFirebase,
				email,
				password
			);

			if (crendetials?.user?.email) {
				// Verifica se o usuário existe no firestore
				const { exists } = await authService.getVerifyByEmail(
					crendetials.user.email
				);
				if (!exists) throw new UserNotFoundError("registerFirestore");
				const authLocal = await setAuthUser(crendetials.user);
				return authLocal;
			}
			throw new UserNotFoundError("block");
		},
		[setAuthUser]
	);

	const sendEmailResetPassword = async (email: string) =>
		sendPasswordResetEmail(getAuth(), email);

	/**
	 * Valida se o usuário possui a permissão sem validar se o usuário for master.
	 */
	const containsPermission = useCallback(
		(permissions: Permission): boolean => {
			if (!auth?.permissions) return false;
			if (!Array.isArray(permissions)) permissions = [permissions];
			return permissions.every((permission) =>
				typeof permission === "string"
					? auth.permissions.includes(permission)
					: auth.permissions.some((p) => permission.test(p))
			);
		},
		[auth?.permissions]
	);

	/**
	 * Valida se o usuário possui a permissão. Sempre retorna verdadeira se o usuário for master.
	 */
	const hasPermission = useCallback(
		(permissions: Permission): boolean => {
			if (auth?.isMaster) return true;
			return containsPermission(permissions);
		},
		[containsPermission, auth?.isMaster]
	);

	const isMaster = useCallback(
		(): boolean => !!auth?.isMaster,
		[auth?.isMaster]
	);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), (user) =>
			setAuthUser(user || undefined).catch(() => {})
		);

		return () => unsubscribe();
	}, [setAuthUser]);

	useEffect(() => {
		setSigned(!!auth && !!currentUser);
	}, [auth, currentUser]);

	const value = useMemo<AuthContextData>(
		() => ({
			signed,
			loaded,
			auth,
			currentUser,
			login,
			sendEmailResetPassword,
			logout,
			hasPermission,
			containsPermission,
			isMaster,
		}),
		[
			signed,
			loaded,
			auth,
			currentUser,
			login,
			hasPermission,
			isMaster,
			containsPermission,
		]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
	return useContext(AuthContext);
}
