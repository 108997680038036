import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import PaginationTable from "../../../../components/PaginationTable";
import PaginationRow from "../../../../components/PaginationTable/PaginationRow";
import PaginationColumn from "../../../../components/PaginationTable/PaginationColumn";
import ResponsePaginationDto from "../../../../apis/VividusAPI/dto/ResponsePaginationDto";
import { User } from "../../../../modules/security/user/entities/User";
import UserService from "../../../../modules/security/user/UserService";
import { maskPhone } from "../../../../utils/string";
import AccessProfileService from "../../../../modules/security/access-profile/AccessProfileService";
import AccessProfile from "../../../../modules/security/access-profile/entities/AccessProfile";

const service = new UserService();
const accessProfileService = new AccessProfileService();

const UserPanel: React.FC = () => {
	const { showError } = useToast();

	// Geral
	const [loading, setLoading] = useState(true);

	// Paginação
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const [pagination, setPagination] = useState<ResponsePaginationDto<User>>();

	// Filtros
	const [search, setSearch] = useState("");

	const [accessProfileList, setAccessProfileList] = useState<AccessProfile[]>(
		[]
	);

	const [selectedAccessProfile, setSelectedAccessProfile] = useState<number>();

	const fetchDataAccessProfile = useCallback(() => {
		setLoading(true);
		accessProfileService
			.list({ take: 0 })
			.then((documents) => {
				setAccessProfileList(documents.data);
			})
			.catch((error) => showError(error));
	}, [showError]);

	const fetchData = useCallback(() => {
		setLoading(true);
		service
			.list({ take: limit, page, search, accessProfile_id: selectedAccessProfile })
			.then((documents) => {
				setPagination(documents);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [page, limit, showError, search, selectedAccessProfile]);

	useEffect(() => {
		fetchDataAccessProfile();
	}, [fetchDataAccessProfile]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Container fluid>
			<DashTitle title="Usuários" />
			{loading && <LoadingSpinner />}
			{pagination && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<Row className="mb-2">
									<Col md={4}>
										<Form.Group>
											<Form.Label>Perfil de acesso</Form.Label>
											<Form.Select
												onChange={(e) => setSelectedAccessProfile(+e.target.value)}
												disabled={loading}
											>
												<option value={0}> </option>
												{accessProfileList.map((accessProfile) => (
													<option key={accessProfile.id} value={accessProfile.id}>
														{accessProfile.name}
													</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row className="mb-2">
									<PaginationTable
										idTable="users-table"
										headers={["Nome", "E-mail", "Telefone", "Criado em"]}
										onLimitChange={setLimit}
										onSearchChange={setSearch}
										onPageChange={(p) => setPage(p)}
										meta={pagination?.meta}
										limit={limit}
									>
										{pagination.data.map((item) => (
											<PaginationRow
												key={`${item.id!}`}
												link={`/administration/user/${item.id}`}
											>
												<PaginationColumn>{item.name}</PaginationColumn>
												<PaginationColumn>{item.email || ""}</PaginationColumn>
												<PaginationColumn>
													{maskPhone(item.phoneNumber || "")}
												</PaginationColumn>
												<PaginationColumn>
													{DateTime.fromISO(item.createdAt).toFormat("dd/MM/yyyy HH:mm")}
												</PaginationColumn>
											</PaginationRow>
										))}
									</PaginationTable>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default UserPanel;
