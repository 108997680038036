import React from "react";
import { Button } from "react-bootstrap";

const ExportPDFButton: React.FC<{ handleExport?: () => Promise<void> }> = ({
	handleExport,
}) => (
	<Button
		variant="info"
		className="mb-2 me-1"
		type="button"
		data-bs-container="#tooltip-pdf"
		data-bs-toggle="tooltip"
		data-bs-placement="bottom"
		title="Exportar para PDF"
		onClick={(e) => {
			e.preventDefault();
			if (handleExport) handleExport();
		}}
	>
		<i className="mdi mdi-file-pdf-box" />
	</Button>
);

export default ExportPDFButton;
