import VividusAPI from "../../../apis/VividusAPI";
import QueryGetSellerGroupPaginatedDto from "../../../apis/VividusAPI/dto/QueryGetSellerGroupPaginatedDto";
import QueryGetSellerGroupDto from "../../../apis/VividusAPI/dto/QueryGetSellerGroupDto";

export default class SellerGroupService {
	fetchById(id: number, params: QueryGetSellerGroupDto = {}) {
		return VividusAPI.getSellerGroup(id, params);
	}

	async list(query: QueryGetSellerGroupPaginatedDto = {}) {
		return VividusAPI.getSellerGroupPaginated(query);
	}
}
