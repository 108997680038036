import React from "react";
import { Button, Modal } from "react-bootstrap";

interface ModalStatusUserProps {
	active: boolean;
	isSubscriber: boolean;
	show: boolean;
	setShow: (loading: boolean) => void;
	handleConfirm: () => Promise<void>;
}

const ModalStatusUser: React.FC<ModalStatusUserProps> = ({
	active,
	isSubscriber,
	show,
	setShow,
	handleConfirm,
}) => (
	<Modal show={show} onHide={() => setShow(false)}>
		<Modal.Header closeButton>
			<Modal.Title>Deseja {active ? "inativar" : "ativar"} o usuário?</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<p>
				Ao {active ? "inativar" : "ativar"}, os dados serão sincronizados nas APIs,
				como a Healthmap e Iugu.
			</p>
			{isSubscriber && active && (
				<p>
					Caso o usuário tenha assinaturas ou compras ativas, elas serão suspensas.
				</p>
			)}
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={() => setShow(false)}>
				Cancelar
			</Button>
			<Button variant="primary" onClick={() => handleConfirm()}>
				Prosseguir
			</Button>
		</Modal.Footer>
	</Modal>
);

export default ModalStatusUser;
