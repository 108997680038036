import React, { useCallback, useEffect, useState } from "react";
import { Alert, Container, Tab, Tabs } from "react-bootstrap";
import { useMatch } from "react-router-dom";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import { Role } from "../../../../modules/security/permission/enum/role.enum";
import UserService from "../../../../modules/security/user/UserService";
import { User } from "../../../../modules/security/user/entities/User";
import AccessProfileService from "../../../../modules/security/access-profile/AccessProfileService";
import TabBasicData from "./tabs/TabBasicData";
import AccessProfile from "../../../../modules/security/access-profile/entities/AccessProfile";
import TabProvider from "./tabs/TabProvider";
import TabSubscriber from "./tabs/TabSubscriber";

const service = new UserService();
const accessProfileService = new AccessProfileService();

const UserModalPanel: React.FC = () => {
	const match = useMatch("/administration/user/:id");
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [loading, setLoading] = useState(true);

	const [user, setUser] = useState<User>();

	const [notFinded, setNotFinded] = useState(false);

	const [accessProfiles, setAccessProfiles] = useState<AccessProfile[]>();

	const getId = useCallback(() => match?.params.id || "", [match?.params.id]);

	const fetchUser = useCallback(async () => {
		if (typeof accessProfiles === "undefined") return;
		if (!hasPermission(Role.ADM_READ_USER)) {
			showError("Permissão negada.");
			return;
		}
		const id = getId();
		if (!id) {
			showError("Registro não informado.");
			return;
		}
		service
			.fetchById(+id)
			.then(async (userLocal) => {
				if (!userLocal) {
					setNotFinded(true);
					return;
				}
				if (typeof userLocal?.subscriptions !== "undefined") {
					userLocal.subscriptions?.forEach((sub) => {
						sub.user = userLocal;
					});
				}
				setUser(userLocal);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [hasPermission, showError, getId, accessProfiles]);

	const fetchAccessProfile = () =>
		accessProfileService
			.list({ take: 0 })
			.then((pagination) => setAccessProfiles(pagination.data))
			.catch(() => setAccessProfiles([]));

	const showTabSubscriber = (userLocal?: User) => {
		if (!hasPermission(Role.ADM_READ_SUBSCRIPTION)) {
			return false;
		}
		return (
			userLocal?.roles?.some((r) =>
				r.permissions.some((p) => p.code === Role.IS_MEMBER)
			) ||
			(typeof userLocal?.subscriptions !== "undefined" &&
				userLocal.subscriptions.length > 0)
		);
	};

	const showTabProvider = (userLocal?: User) => {
		if (!hasPermission(Role.ADM_UPD_USER)) return false;
		return (
			typeof user?.provider?.id !== "undefined" ||
			userLocal?.roles?.some((r) =>
				r.permissions.some((p) => p.code === Role.IS_PROVIDER)
			)
		);
	};

	useEffect(() => {
		fetchAccessProfile();
	}, []);

	useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	return (
		<Container fluid>
			<DashTitle title="Usuário" showGoBackButton />
			{loading && <LoadingSpinner />}
			{notFinded && <Alert variant="info">Registro não encontrado.</Alert>}
			{!notFinded && (
				<Tabs defaultActiveKey="basic">
					<Tab eventKey="basic" title="Dados básicos">
						<TabBasicData
							user={user}
							handleBeforeUpdate={() => fetchUser()}
							setLoading={setLoading}
							loading={loading}
							accessProfileList={accessProfiles}
						/>
					</Tab>
					{showTabSubscriber(user) && (
						<Tab eventKey="subscriber" title="Assinante">
							<TabSubscriber
								user={user}
								setLoading={setLoading}
								loading={loading}
								subscriptions={user?.subscriptions || []}
								handleBeforeUpdate={() => fetchUser()}
							/>
						</Tab>
					)}
					{showTabProvider(user) && (
						<Tab eventKey="provider" title="Parceiro">
							<TabProvider
								user={user}
								provider={user?.provider}
								setLoading={setLoading}
								loading={loading}
								handleBeforeUpdate={() => fetchUser()}
							/>
						</Tab>
					)}
				</Tabs>
			)}
		</Container>
	);
};

export default UserModalPanel;
