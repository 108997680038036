import VividusAPI from "../../../apis/VividusAPI";
import BodyPatchProviderDto from "../../../apis/VividusAPI/dto/BodyPatchProviderDto";
import BodyPostProviderDto from "../../../apis/VividusAPI/dto/BodyPostProviderDto";

export default class ProviderService {
	createProvider(body: BodyPostProviderDto) {
		return VividusAPI.postProvider(body);
	}

	updateProvider(id: number, body: BodyPatchProviderDto) {
		return VividusAPI.patchProvider(id, body);
	}
}
