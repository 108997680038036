import React from "react";
import { Card } from "react-bootstrap";
import logo from "../../assets/images/logo.svg";

const VividusCardLogo: React.FC = () => (
	<Card.Header className="pt-4 pb-4 text-center bg-primary">
		<a href="https://www.vividus.com.br/" rel="noreferrer" target="_blank">
			<img src={logo} alt="Vívidus Logo" />
		</a>
	</Card.Header>
);

export default VividusCardLogo;
