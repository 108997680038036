import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthContext";
import TopBarDropdown from "./TopBarDropdown";
import TopBarDropdownItem from "./TopBarDropdownItem";
import TopBarProfile from "./TopBarProfile";

const TopBar: React.FC = () => {
	const { auth, logout } = useAuth();
	const [name, setName] = useState("");
	const [avatar, setAvatar] = useState(
		"https://res.cloudinary.com/vividus/image/upload/v1637364629/staff/Screen_Shot_2021-11-19_at_20.29.37_qemn0v.png"
	);

	useEffect(() => {
		setName(auth?.user?.name || "");
		setAvatar(
			auth?.user?.avatar ||
				"https://res.cloudinary.com/vividus/image/upload/v1637364629/staff/Screen_Shot_2021-11-19_at_20.29.37_qemn0v.png"
		);
	}, [auth?.user?.name, auth?.user?.avatar]);

	return (
		<div className="navbar-custom">
			<Button
				type="button"
				className="button-menu-mobile open-left"
				onClick={() => {
					const compactMode = document.body.getAttribute(
						"data-leftbar-compact-mode"
					);
					if (compactMode === "condensed") {
						document.body.setAttribute("data-leftbar-compact-mode", "expanded");
					} else {
						document.body.setAttribute("data-leftbar-compact-mode", "condensed");
					}
				}}
			>
				<i className="mdi mdi-menu" />
			</Button>
			<Container fluid>
				<ul className="list-unstyled topbar-menu float-end mb-0">
					<li className="dropdown notification-list">
						<TopBarProfile name={name} avatar={avatar} />
						<TopBarDropdown>
							<TopBarDropdownItem
								onClick={logout}
								title="Logout"
								icon="mdi mdi-logout me-1"
							/>
						</TopBarDropdown>
					</li>
				</ul>
			</Container>
		</div>
	);
};

export default TopBar;
