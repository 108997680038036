import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { Plan } from "../../../../../../../../modules/finance/plan/entities/Plan";
import { getPlanRecurrenceText } from "../../../../../../../../utils/string";

enum Type {
	B2B = 1,
	B2C = 2,
}

interface ModalCreateSubscriptionProps {
	plans: Plan[];
	show: boolean;
	setShow: (loading: boolean) => void;
	loading: boolean;
	handleCreateSubscription: (
		plan_id: number,
		business: boolean
	) => Promise<void>;
}

const ModalCreateSubscription: React.FC<ModalCreateSubscriptionProps> = ({
	plans,
	show,
	setShow,
	loading,
	handleCreateSubscription,
}) => {
	const [selectedType, setSelectedType] = useState(Type.B2B);

	const [selectedPlan, setSelectedPlan] = useState<number>(0);

	return (
		<Modal
			show={show}
			onHide={() => setShow(false)}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Nova assinatura</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Será criada uma nova assinatura para o usuário. Certifique-se de que ele
					possua um e-mail e CPF cadastrados para prosseguir.
				</p>
				<hr />
				<Form>
					<Form.Text hidden={selectedType === Type.B2B}>
						<strong>Usuários B2C possuem um vínculo na plataforma Iugu.</strong>
					</Form.Text>
					<Col md={4}>
						<InputGroup className="mb-3">
							<InputGroup.Text>Tipo</InputGroup.Text>
							<Form.Select
								onChange={(e) => setSelectedType(+e.target.value as Type)}
								disabled={loading}
								value={selectedType}
							>
								<option value={Type.B2B}>B2B</option>
								<option value={Type.B2C}>B2C</option>
							</Form.Select>
						</InputGroup>
					</Col>
					<InputGroup className="mb-3">
						<InputGroup.Text>Plano</InputGroup.Text>
						<Form.Select
							onChange={(e) => setSelectedPlan(+e.target.value)}
							disabled={loading}
							value={selectedPlan}
						>
							<option value={0}> </option>
							{plans
								.sort((a, b) => a.code.localeCompare(b.code))
								.map((plan) => (
									<option key={plan.id} value={plan.id}>
										{`${plan.name} (${getPlanRecurrenceText(plan.code)})`}
									</option>
								))}
						</Form.Select>
					</InputGroup>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => setShow(false)}
					disabled={loading}
				>
					Cancelar
				</Button>
				<Button
					disabled={loading || selectedPlan === 0}
					variant="primary"
					onClick={() =>
						handleCreateSubscription(selectedPlan, selectedType === Type.B2B)
					}
				>
					Criar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalCreateSubscription;
