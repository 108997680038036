import React from "react";

const PaginationRow: React.FC<{ link?: string }> = ({ link, children }) => {
	const childrenWithProps = React.Children.map(children, (childRow) => {
		if (!link || !React.isValidElement(childRow)) return childRow;
		if (!childRow?.props?.children) return childRow;
		// Update link for each row
		const childrenLocal = Array.isArray(childRow.props.children)
			? childRow.props.children
			: [childRow.props.children];
		return React.cloneElement(
			childRow,
			{
				link,
			} as any,
			...childrenLocal
		);
	});

	return <tr>{childrenWithProps}</tr>;
};

export default PaginationRow;
