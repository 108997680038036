/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import logo from "../../../../assets/images/adaptive-icon.png";
import SideBarMenu from "./SideBarMenu";
import SideBarTitle from "./SideBarTitle";
import SideBarItem from "./SideBarItem";
import { Role } from "../../../../modules/security/permission/enum/role.enum";

const SideBar: React.FC = () => (
	<div className="leftside-menu menuitem-active">
		<div className="logo text-center">
			<span className="logo-lg">
				<img src={logo} alt="" height="40" />
			</span>
			<span className="logo-sm">
				<img src={logo} alt="" height="40" />
			</span>
		</div>
		<div
			className="h-100 show"
			id="leftside-menu-container"
			data-simplebar="init"
		>
			<div className="simplebar-wrapper" style={{ margin: "0px" }}>
				<div className="simplebar-height-auto-observer-wrapper">
					<div className="simplebar-height-auto-observer" />
				</div>
				<div className="simplebar-mask">
					<div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
						<div
							className="simplebar-content-wrapper"
							tabIndex={0}
							role="region"
							aria-label="scrollable content"
							style={{ height: "100%", overflow: "hidden scroll" }}
						>
							<div className="simplebar-content" style={{ padding: "0px" }}>
								<ul className="side-nav">
									<SideBarItem icon="mdi mdi-home" title="Início" url="/" root />
									<SideBarTitle title="Sistema">
										<SideBarItem
											title="Configurações do Sistema"
											icon="mdi mdi-cog"
											url="/system/configuration"
											permissions={[Role.ADM_READ_SYS_CONFIG]}
											root
										/>
									</SideBarTitle>
									<SideBarTitle title="Administração">
										<SideBarItem
											title="Perfil de Acesso"
											icon="mdi mdi-account-lock"
											url="/administration/access-profile"
											permissions={[/adm-.*-access-profile/]}
											root
										/>
										<SideBarMenu id="sidebarUser" title="Usuário" icon="mdi mdi-account">
											<SideBarItem
												title="Novo Usuário"
												url="/administration/user/new"
												permissions={[Role.ADM_CREATE_USER]}
											/>
											<SideBarItem
												title="Usuários"
												url="/administration/user"
												permissions={[/adm-.*-user/]}
											/>
										</SideBarMenu>
									</SideBarTitle>
									<SideBarTitle title="Financeiro">
										<SideBarMenu
											id="sidebarBilling"
											title="Faturamento"
											icon="mdi mdi-cash"
										>
											<SideBarItem
												title="Novo Lote de Faturamento"
												url="/billing/billing-lot/new"
												permissions={[Role.ADM_CREATE_BILLING_LOT]}
											/>
											<SideBarItem
												title="Lotes de Faturamento"
												url="/billing/billing-lot"
												permissions={[/adm-.*-billing-lot/]}
											/>
										</SideBarMenu>
									</SideBarTitle>
									<SideBarTitle title="Gestão">
										<SideBarMenu
											id="sidebarReports"
											title="Relatórios"
											icon="mdi mdi-book"
										>
											<SideBarItem
												title="Comissão de Vendedores"
												url="/reports/seller-commission"
												permissions={[Role.REPORT_SELLER_COMMISSION]}
											/>
										</SideBarMenu>
									</SideBarTitle>
								</ul>
								<div className="clearfix" />
							</div>
						</div>
					</div>
				</div>
				<div
					className="simplebar-placeholder"
					style={{ width: "auto", height: "1928px" }}
				/>
			</div>
			<div
				className="simplebar-track simplebar-horizontal"
				style={{ visibility: "hidden" }}
			>
				<div
					className="simplebar-scrollbar"
					style={{ width: "0px", display: "none" }}
				/>
			</div>
			<div
				className="simplebar-track simplebar-vertical"
				style={{ visibility: "visible" }}
			>
				<div
					className="simplebar-scrollbar"
					style={{
						height: "271px",
						transform: "translate3d(0px, 452px, 0px)",
						display: "block",
					}}
				/>
			</div>
		</div>
	</div>
);

export default SideBar;
