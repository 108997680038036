import { useCallback } from "react";
import { toast } from "react-toastify";
import { getErrorDetails } from "../../utils/error";

type ToastOptions = {
	onDidDismiss?: () => void;
};

const useToast = () => {
	const log = useCallback(
		(message: string | unknown, type: "danger" | "success" | "dark") => {
			const [details] = getErrorDetails(message);
			switch (type) {
				case "danger": {
					console.error(message);
					break;
				}
				case "success":
				case "dark":
				default:
					console.log(message);
					break;
			}
			return details.message;
		},
		[]
	);

	const show = useCallback(
		(
			message: string | unknown,
			type: "danger" | "success" | "dark",
			options?: ToastOptions
		) => {
			const displayMessage = log(message, type);
			// toast.error({
			// 	message: displayMessage,
			// 	color: type,
			// 	position: "top",
			// 	duration: 2500,
			// 	onDidDismiss: options?.onDidDismiss,
			// });
			switch (type) {
				case "danger": {
					toast.error(displayMessage, { onClose: options?.onDidDismiss });
					break;
				}
				case "success": {
					toast.success(displayMessage, { onClose: options?.onDidDismiss });
					break;
				}
				default:
					toast.info(displayMessage, { onClose: options?.onDidDismiss });
					break;
			}
		},
		[log]
	);

	const showSuccess = useCallback(
		(message: string | unknown, options?: ToastOptions) =>
			show(message, "success", options),
		[show]
	);

	const showDefault = useCallback(
		(message: string | unknown, options?: ToastOptions) =>
			show(message, "dark", options),
		[show]
	);

	const showError = useCallback(
		(message: string | unknown, options?: ToastOptions) =>
			show(message, "danger", options),
		[show]
	);

	return { showError, showSuccess, showDefault };
};

export default useToast;
