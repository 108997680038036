import React from "react";
import { Navigate } from "react-router-dom";
import { Permission, useAuth } from "../../contexts/AuthContext";

interface PrivatePageProps {
	permissions: Permission;
}

const PrivatePage: React.FC<PrivatePageProps> = ({ permissions, children }) => {
	const { hasPermission } = useAuth();
	return hasPermission(permissions) ? (
		<div>{children}</div>
	) : (
		<Navigate to="/forbidden" />
	);
};

export default PrivatePage;
