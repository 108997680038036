import SellerGroup from "../../modules/finance/seller-group/entities/SellerGroup";
import AccessProfile from "../../modules/security/access-profile/entities/AccessProfile";
import PermissionsGroup from "../../modules/security/permission-group/entities/PermissionsGroup";
import { Role } from "../../modules/security/permission/enum/role.enum";
import { User } from "../../modules/security/user/entities/User";
import BaseAPI from "../BaseAPI";
import BodyPostBillingLotDto from "./dto/BodyPostBillingLotDto";
import QueryGetAccessProfileDto from "./dto/QueryGetAccessProfileDto";
import QueryGetAccessProfilePaginatedDto from "./dto/QueryGetAccessProfilePaginatedDto";
import QueryGetSellerGroupPaginatedDto from "./dto/QueryGetSellerGroupPaginatedDto";
import QueryGetPermissionsGroupPaginatedDto from "./dto/QueryGetPermissionsGroupPaginatedDto";
import QueryGetSellerCommissionReportDto from "./dto/QueryGetSellerCommissionReportDto";
import QueryGetSellerGroupDto from "./dto/QueryGetSellerGroupDto";
import QueryGetUserDto from "./dto/QueryGetUserDto";
import QueryGetVerifyMemberDto from "./dto/QueryGetVerifyMemberDto";
import ResponseGetVerifyMemberDto from "./dto/ResponseGetVerifyMemberDto";
import ResponsePaginationDto from "./dto/ResponsePaginationDto";
import ResponsePostLoginTokenDto from "./dto/ResponsePostLoginTokenDto";
import ResponseSellerCommissionReportDto from "./dto/ResponseSellerCommissionReportDto";
import { BillingLot } from "../../modules/finance/billing-lot/entities/BillingLot";
import QueryGetBillingLotPaginatedDto from "./dto/QueryGetBillingLotPaginatedDto";
import ResponseGetDraftBillingLotDto from "./dto/ResponseGetDraftBillingLotDto";
import BodyPostAddBillingLotItemDto from "./dto/BodyPostAddBillingLotItemDto";
import BodyPatchBillingLotDto from "./dto/BodyPatchBillingLotDto";
import { BillingLotItem } from "../../modules/finance/billing-lot-item/entities/BillingLotItem";
import QueryGetBillingLotDto from "./dto/QueryGetBillingLotDto";
import BodyPostUserDto from "./dto/BodyPostUserDto";
import QueryGetUserPaginatedDto from "./dto/QueryGetUserPaginatedDto";
import BodyPatchUserDto from "./dto/BodyPatchUserDto";
import BodyPatchProviderDto from "./dto/BodyPatchProviderDto";
import { Provider } from "../../modules/marketplace/provider/entities/Provider";
import BodyPostProviderDto from "./dto/BodyPostProviderDto";
import QueryGetConfigurationPaginatedDto from "./dto/QueryGetConfigurationPaginatedDto";
import { Configuration } from "../../modules/system/configuration/entities/Configuration";
import QueryGetConfigurationDto from "./dto/QueryGetConfigurationDto";
import BodyPatchParameterDto from "./dto/BodyPatchParameterDto";
import { Parameter } from "../../modules/system/parameter/entities/Parameter";
import QueryGetCustomerPaginatedDto from "./dto/QueryGetCustomerPaginatedDto";
import { Customer } from "../../modules/security/customer/entities/Customer";
import { Plan } from "../../modules/finance/plan/entities/Plan";
import { Subscription } from "../../modules/finance/subscription/entities/Subscription";
import BodyPostSubscriptionAdminDto from "./dto/BodyPostSubscriptionAdminDto";
import BodyPatchSubscriptionDto from "./dto/BodyPatchSubscriptionDto";
import ResponseGetAddress from "./dto/ResponseGetAddressDto";
import ResponseGetChangePlanSimulationDto from "./dto/ResponseGetChangePlanSimulationDto";
import ResponsePostSubscriptionDto from "./dto/ResponsePostSubscriptionDto";
import { BodyPostChangePlanDto } from "./dto/BodyPostChangePlanDto";

class VividusAPI extends BaseAPI {
	private static instance: VividusAPI;

	constructor() {
		super("AXIOS_VIVIDUS", process.env.REACT_APP_VIVIDUS_BACKEND || "");
		this.api.defaults.headers.common["x-vividus-origin"] = "ADMIN";
	}

	static getInstance() {
		if (typeof VividusAPI.instance === "undefined") {
			VividusAPI.instance = new VividusAPI();
		}
		return VividusAPI.instance;
	}

	putSyncFirebaseAuth(user_id: number) {
		return this.api
			.put<ResponsePostLoginTokenDto>(
				`api/auth/sync-firebase-auth/${user_id}`,
				undefined,
				{
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	postLoginToken(auth_id: string) {
		return this.api
			.post<ResponsePostLoginTokenDto>(
				"api/auth/login/token",
				{ auth_id },
				{
					headers: {
						authorization: this.getBasicAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	getVerifyMember(params: QueryGetVerifyMemberDto) {
		return this.api
			.get<ResponseGetVerifyMemberDto>("api/auth/verify/member", {
				params,
				headers: {
					authorization: this.getBasicAuth(),
				},
			})
			.then((response) => response.data);
	}

	getUser(id: number, params: QueryGetUserDto, accesstoken?: string) {
		return this.api
			.get<User>(`api/security/user/${id}`, {
				params,
				headers: {
					authorization: accesstoken ? `Bearer ${accesstoken}` : this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getUserPaginated(params: QueryGetUserPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<User>>("api/security/user", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	postUserActivate(id: number) {
		return this.api
			.post(`api/security/user/${id}/activate`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	deleteUserInactivate(id: number) {
		return this.api
			.delete(`api/security/user/${id}/inactivate`, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	postAccessProfileUser(id: number, user_id: number) {
		return this.api
			.post(`api/security/access-profile/${id}/user/${user_id}`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	deleteAccessProfileUser(id: number, user_id: number) {
		return this.api
			.delete(`api/security/access-profile/${id}/user/${user_id}`, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	postAccessProfilePermission(id: number, role: Role) {
		return this.api
			.post(`api/security/access-profile/${id}/permission/${role}`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	deleteAccessProfilePermission(id: number, role: Role) {
		return this.api
			.delete(`api/security/access-profile/${id}/permission/${role}`, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	getAccessProfile(id: number, params: QueryGetAccessProfileDto) {
		return this.api
			.get(`api/security/access-profile/${id}`, {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getAccessProfilePaginated(params: QueryGetAccessProfilePaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<AccessProfile>>("api/security/access-profile", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getSellerGroup(id: number, params: QueryGetSellerGroupDto) {
		return this.api
			.get<SellerGroup>(`api/finance/seller-group/${id}`, {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getSellerGroupPaginated(params: QueryGetSellerGroupPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<SellerGroup>>("api/finance/seller-group", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getPermissionsGroupPaginated(params: QueryGetPermissionsGroupPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<PermissionsGroup>>(
				"api/security/permissions-group",
				{
					params,
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	getBillingLot(id: number, params: QueryGetBillingLotDto) {
		return this.api
			.get<BillingLot>(`api/finance/billing-lot/${id}`, {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getDraftBillingLot(id: number) {
		return this.api
			.get<ResponseGetDraftBillingLotDto>(`api/finance/billing-lot/${id}/draft`, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getBillingLotPaginated(params: QueryGetBillingLotPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<BillingLot>>("api/finance/billing-lot", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	postAddBillingLotItem(id: number, body: BodyPostAddBillingLotItemDto) {
		return this.api
			.post<BillingLotItem>(`api/finance/billing-lot/${id}/item`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	deleteRemoveBillingLotItem(billingLot_id: number, billingLotItem_id: number) {
		return this.api
			.delete(
				`api/finance/billing-lot/${billingLot_id}/item/${billingLotItem_id}`,
				{
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then(() => { });
	}

	postBillingLot(body: BodyPostBillingLotDto) {
		return this.api
			.post<BillingLot>("api/finance/billing-lot", body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	patchBillingLot(id: number, body: BodyPatchBillingLotDto) {
		return this.api
			.patch<BillingLot>(`api/finance/billing-lot/${id}`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	deleteBillingLot(id: number) {
		return this.api
			.delete(`api/finance/billing-lot/${id}`, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	putCloseBillingLot(id: number) {
		return this.api
			.put(`api/finance/billing-lot/${id}/close`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	putWriteOffBillingLot(id: number) {
		return this.api
			.put(`api/finance/billing-lot/${id}/write-off`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then(() => { });
	}

	getReportSellerCommission(params: QueryGetSellerCommissionReportDto) {
		return this.api
			.get<ResponseSellerCommissionReportDto[]>(
				"api/report/seller-commission-report",
				{
					params,
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	postUser(body: BodyPostUserDto) {
		return this.api
			.post<User>("api/security/user", body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	patchUser(id: number, body: BodyPatchUserDto) {
		return this.api
			.patch<User>(`api/security/user/${id}`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	putSyncHealthmap(id: number) {
		return this.api
			.put<User>(`api/security/user/${id}/sync-healthmap`, undefined, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	postProvider(body: BodyPostProviderDto) {
		return this.api
			.post<Provider>("api/marketplace/provider", body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	patchProvider(id: number, body: BodyPatchProviderDto) {
		return this.api
			.patch<Provider>(`api/marketplace/provider/${id}`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getConfiguration(id: number, params: QueryGetConfigurationDto) {
		return this.api
			.get<Configuration>(`api/system/configuration/${id}`, {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getConfigurationPaginated(params: QueryGetConfigurationPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<Configuration>>("api/system/configuration", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	patchParameter(id: number, body: BodyPatchParameterDto) {
		return this.api
			.patch<Parameter>(`api/system/parameters/${id}`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getCustomerPaginated(params: QueryGetCustomerPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<Customer>>("api/security/customer", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getPlanPaginated(params: QueryGetCustomerPaginatedDto) {
		return this.api
			.get<ResponsePaginationDto<Plan>>("api/finance/plan", {
				params,
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	postSubscriptionAdmin(body: BodyPostSubscriptionAdminDto) {
		return this.api
			.post<Subscription>("api/finance/subscription/admin", body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	patchSubscription(id: number, body: BodyPatchSubscriptionDto) {
		return this.api
			.patch<Subscription>(`api/finance/subscription/${id}`, body, {
				headers: {
					authorization: this.getJWTAuth(),
				},
			})
			.then((response) => response.data);
	}

	getAddress(zipcode: string) {
		return this.api
			.get<ResponseGetAddress>(`api/security/user/${zipcode}/address`, {
				headers: {
					authorization: this.getBasicAuth(),
				},
			})
			.then((response) => response.data);
	}

	postPlanChange(user_id: number, body: BodyPostChangePlanDto) {
		return this.api
			.post<ResponsePostSubscriptionDto>(
				`api/finance/subscription/user/${user_id}/change_plan`,
				body,
				{
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
	}

	getSimulatePlanChange(user_id: number, iuguPlan_id: string) {
		return this.api
			.get<ResponseGetChangePlanSimulationDto>(
				`api/finance/subscription/user/${user_id}/change_plan_simulation/${iuguPlan_id}`,
				{
					headers: {
						authorization: this.getJWTAuth(),
					},
				}
			)
			.then((response) => response.data);
	}
}

export default VividusAPI.getInstance();
