import React, { useCallback, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Card,
	Col,
	Form,
	FormControl,
	InputGroup,
	Row,
	Table,
} from "react-bootstrap";
import { useMatch } from "react-router";
import BasicTable from "../../../../components/BasicTable";
import BasicColumn from "../../../../components/BasicTable/BasicColumn";
import BasicRow from "../../../../components/BasicTable/BasicRow";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useAuth } from "../../../../contexts/AuthContext";
import useToast from "../../../../hooks/Toast";
import { Role } from "../../../../modules/security/permission/enum/role.enum";
import ConfigurationService from "../../../../modules/system/configuration/ConfigurationService";
import { Configuration } from "../../../../modules/system/configuration/entities/Configuration";
import { CodeConfiguration } from "../../../../modules/system/configuration/enum/CodeConfiguration";
import { Parameter } from "../../../../modules/system/parameter/entities/Parameter";
import DashTitle from "../../components/DashTitle";
import ParameterRow from "./ParameterRow";

const service = new ConfigurationService();

const ConfigurationModalPanel: React.FC = () => {
	const match = useMatch("/system/configuration/:id");
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [loading, setLoading] = useState(true);

	const [notFinded, setNotFinded] = useState(false);

	const getId = useCallback(() => match?.params.id || "", [match?.params.id]);

	const [configuration_code, setConfiguration_code] =
		useState<CodeConfiguration>();
	const [configuration_active, setConfiguration_active] = useState(false);
	const [configuration_description, setConfiguration_description] = useState("");

	const [configuration_parameters, setConfiguration_parameters] = useState<
		Parameter[]
	>([]);

	const setConfiguration = (config: Configuration) => {
		setConfiguration_code(config.code || "");
		setConfiguration_active(config.active);
		setConfiguration_description(config.description || "");
		setConfiguration_parameters(
			config.parameters?.sort((a, b) => a.id - b.id) || []
		);
	};

	useEffect(() => {
		if (!hasPermission(Role.ADM_READ_SYS_CONFIG)) {
			showError("Permissão negada.");
			return;
		}
		const id = getId();
		if (!id) {
			showError("Registro não informado.");
			return;
		}
		service
			.fetchById(+id, {
				loadParameters: true,
			})
			.then(async (config) => {
				if (!config) {
					setNotFinded(true);
					return;
				}
				setConfiguration(config);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [match, hasPermission, showError, getId]);

	return (
		<div>
			<DashTitle title="Configurações Globais" />
			{loading && <LoadingSpinner />}
			{notFinded && <Alert variant="info">Registro não encontrado.</Alert>}
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<InputGroup className="mb-3">
								<InputGroup.Text>Código</InputGroup.Text>
								<FormControl disabled value={configuration_code} />
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Descrição</InputGroup.Text>
								<FormControl disabled value={configuration_description} />
							</InputGroup>
							<Row>
								<Col md={12}>
									<Card>
										<Card.Body>
											<BasicTable headers={["Código", "Descrição", "Valor"]}>
												{configuration_parameters.map((param) => (
													<ParameterRow
														key={`profile-${param.id!}`}
														parameter={param}
														onWillSave={() => setLoading(true)}
														onDidSave={() => setLoading(false)}
													/>
												))}
											</BasicTable>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ConfigurationModalPanel;
