import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	FormControl,
	InputGroup,
	Form,
	Card,
	Row,
	Col,
	Figure,
	OverlayTrigger,
	Tooltip,
	Alert,
	ButtonGroup,
	Popover,
	ListGroup,
} from "react-bootstrap";
import { DateTime } from "luxon";
import useToast from "../../../../../../hooks/Toast";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { Role } from "../../../../../../modules/security/permission/enum/role.enum";
import UserService from "../../../../../../modules/security/user/UserService";
import { User } from "../../../../../../modules/security/user/entities/User";
import {
	isEmail,
	isPhoneNumber,
	maskCpf,
	maskDate,
	maskPhone,
	maskZipcode,
	unformatCpf,
	validateCPF,
} from "../../../../../../utils/string";
import AccessProfile from "../../../../../../modules/security/access-profile/entities/AccessProfile";
import AccessProfileService from "../../../../../../modules/security/access-profile/AccessProfileService";
import BasicTable from "../../../../../../components/BasicTable";
import BasicRow from "../../../../../../components/BasicTable/BasicRow";
import BasicColumn from "../../../../../../components/BasicTable/BasicColumn";
import Gender, {
	getGender,
} from "../../../../../../modules/security/user/enum/Gender";
import ModalStatusUser from "./components/ModalStatusUser";
import ModalSyncAuth from "./components/ModalSyncAuth";
import AuthService from "../../../../../../modules/security/auth/AuthService";
import UF from "../../../../../../modules/common/enum/uf.enum";

const service = new UserService();
const authService = new AuthService();
const accessProfileService = new AccessProfileService();

enum ValidationResult {
	SUCCESS,
	WARN,
	ERROR,
	INFO,
}

interface TabBasicDataProps {
	user?: User;
	accessProfileList?: AccessProfile[];
	loading: boolean;
	setLoading: (loading: boolean) => void;
	handleBeforeUpdate: () => Promise<void>;
}

const TabBasicData: React.FC<TabBasicDataProps> = ({
	user,
	accessProfileList,
	loading,
	setLoading,
	handleBeforeUpdate,
}) => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [enableSaveUser, setEnableSaveUser] = useState(true);
	const [showModalStatusUser, setShowModalStatusUser] = useState(false);
	const [showModalSyncAuth, setShowModalSyncAuth] = useState(false);

	const [user_name, setUser_name] = useState("");
	const [user_email, setUser_email] = useState("");
	const [user_phoneNumber, setUser_phoneNumber] = useState("");
	const [user_cpf, setUser_cpf] = useState("");
	const [user_birthdate, setUser_birthdate] = useState("");
	const [user_zipcode, setUser_zipcode] = useState("");
	const [user_street, setUser_street] = useState("");
	const [user_district, setUser_district] = useState("");
	const [user_state, setUser_state] = useState("");
	const [user_addressNumber, setUser_addressNumber] = useState("");
	const [user_city, setUser_city] = useState("");
	const [user_complement, setUser_complement] = useState("");
	const [user_gender, setUser_gender] = useState("");
	const [user_avatarUrl, setUser_avatarUrl] = useState("");
	const [user_hmap_id, setUser_hmap_id] = useState("");
	const [user_hmapExterno, setUser_hmapExterno] = useState("");
	const [user_iugu_id, setUser_iugu_id] = useState("");

	const [user_accessProfiles, setUser_accessProfiles] = useState<number[]>([]);

	const [user_auth_id, setUser_auth_id] = useState("");
	const [user_active, setUser_active] = useState(false);
	const [user_pendent, setUser_pendent] = useState(false);

	const [updated_street, setUpdated_street] = useState(false);
	const [updated_district, setUpdated_district] = useState(false);
	const [updated_state, setUpdated_state] = useState(false);
	const [updated_city, setUpdated_city] = useState(false);
	const [updated_complement, setUpdated_complement] = useState(false);

	const [subscriberFieldsAlert, setSubscriberFieldsAlert] = useState<
		[(verfySubscriber?: boolean) => ValidationResult, string][]
	>([]);

	const isHolder = typeof user?.holder?.id === "undefined";

	const handleFindAddress = async () => {
		if (!user_zipcode.replace(/\D/g, "").trim()) return;
		setLoading(true);
		try {
			const address = await service.fetchAddress(
				user_zipcode.replace(/\D/g, "").trim()
			);
			if (address?.city) {
				setUser_city(address.city);
				setUpdated_city(true);
			}
			if (address?.complement) {
				setUser_complement(address.complement);
				setUpdated_complement(true);
			}
			if (address?.district) {
				setUser_district(address.district);
				setUpdated_district(true);
			}
			if (address?.state) {
				setUser_state(address.state);
				setUpdated_state(true);
			}
			if (address?.street) {
				setUser_street(address.street);
				setUpdated_street(true);
			}
			setTimeout(() => {
				setUpdated_street(false);
				setUpdated_state(false);
				setUpdated_district(false);
				setUpdated_complement(false);
				setUpdated_city(false);
			}, 1500);
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const isSubscriber = useCallback(() => {
		if (!user?.roles) return true;
		return user.roles.some((r) =>
			r.permissions.some((p) => p.code === Role.IS_MEMBER)
		);
	}, [user?.roles]);

	const classAlertField = (validationFn: () => ValidationResult) => {
		switch (validationFn()) {
			case ValidationResult.WARN:
				return "border border-warning";
			case ValidationResult.ERROR:
				return "border border-danger";
			case ValidationResult.INFO:
				return "border border-success";
			case ValidationResult.SUCCESS:
			default:
				return "";
		}
	};

	const updateAccessProfile = async (profile_id: number, value: boolean) => {
		if (!user?.id) return;
		try {
			setLoading(true);
			if (value) {
				await accessProfileService.addRole(profile_id, user.id);
			} else {
				await accessProfileService.deleteRole(profile_id, +user.id);
			}
			await handleBeforeUpdate();
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSyncAuth = async () => {
		if (!user?.id) return;
		if (!hasPermission(Role.ADM_UPD_USER)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await authService.syncFirebaseAuth(user.id);
			setShowModalSyncAuth(false);
			await handleBeforeUpdate();
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSyncHealthmap = async () => {
		if (!user?.id) return;
		if (!hasPermission(Role.ADM_UPD_USER)) {
			showError("Permissão negada.");
			return;
		}
		if (!user_name) {
			showError("Nome não informado.");
			return;
		}
		setLoading(true);
		try {
			const birthdate = user_birthdate.trim()
				? DateTime.fromFormat(user_birthdate, "dd/MM/yyyy").toJSDate()
				: null;
			await service.updateUser(user.id, {
				name: user_name.trim(),
				email: user_email.trim(),
				phoneNumber: user_phoneNumber.replace(/\D/g, "")
					? `55${user_phoneNumber.replace(/\D/g, "")}`.trim()
					: null,
				birthdate,
				cpf: unformatCpf(user_cpf),
				zipcode: user_zipcode.replace(/\D/g, "").trim(),
				street: user_street.trim(),
				district: user_district.trim(),
				state: user_state.trim(),
				addressNumber: user_addressNumber.trim(),
				city: user_city.trim(),
				complement: user_complement.trim(),
				gender: getGender(user_gender),
				avatarUrl: user_avatarUrl,
				auth_id: user_auth_id.trim(),
				active: user_active,
				pendent: user_pendent,
				hmap_id: user_hmap_id,
				hmapExterno: user_hmapExterno,
				iugu_id: user_iugu_id,
			});
			await service.syncHealthmap(user.id);
			await handleBeforeUpdate();
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSaveUser = async () => {
		if (!user?.id) return;
		if (!hasPermission(Role.ADM_UPD_USER)) {
			showError("Permissão negada.");
			return;
		}
		if (!user_name) {
			showError("Nome não informado.");
			return;
		}
		setLoading(true);
		try {
			const birthdate = user_birthdate.trim()
				? DateTime.fromFormat(user_birthdate, "dd/MM/yyyy").toJSDate()
				: null;
			await service.updateUser(user.id, {
				name: user_name.trim(),
				email: user_email.trim(),
				phoneNumber: user_phoneNumber.replace(/\D/g, "")
					? `55${user_phoneNumber.replace(/\D/g, "")}`.trim()
					: null,
				birthdate,
				cpf: unformatCpf(user_cpf),
				zipcode: user_zipcode.replace(/\D/g, "").trim(),
				street: user_street.trim(),
				district: user_district.trim(),
				state: user_state.trim(),
				addressNumber: user_addressNumber.trim(),
				city: user_city.trim(),
				complement: user_complement.trim(),
				gender: getGender(user_gender),
				avatarUrl: user_avatarUrl,
				auth_id: user_auth_id.trim(),
				active: user_active,
				pendent: user_pendent,
				hmap_id: user_hmap_id,
				hmapExterno: user_hmapExterno,
				iugu_id: user_iugu_id,
			});
			await handleBeforeUpdate();
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const changeUserStatus = async () => {
		if (!user?.id) return;
		setLoading(true);
		try {
			if (!user_active) {
				await service.activate(user.id);
			} else {
				await service.inactivate(user.id);
			}
			setShowModalStatusUser(false);
			await handleBeforeUpdate();
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const getHmapWarningButton = (field: string) =>
		!field && isSubscriber() ? (
			<OverlayTrigger
				delay={{ show: 250, hide: 400 }}
				placement="bottom"
				overlay={<Tooltip>Assinante sem código Healthmap definido</Tooltip>}
			>
				<Button variant="warning">
					<span className="mdi mdi-information" />
				</Button>
			</OverlayTrigger>
		) : null;

	// #################################################################
	// Validação de campos - Início
	// #################################################################
	const verifyUserName = useCallback(
		(): ValidationResult =>
			!user_name.trim() ? ValidationResult.ERROR : ValidationResult.SUCCESS,
		[user_name]
	);

	const verifyUserEmail = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber) {
				if (!isHolder) return ValidationResult.SUCCESS;
				return isSubscriber() && (!user_email.trim() || !isEmail(user_email))
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			}

			if (!isHolder) {
				if (!user_email.trim()) return ValidationResult.SUCCESS;
				return !isEmail(user_email)
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			}

			return !user_email.trim() || !isEmail(user_email)
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_email]
	);

	const verifyUserGender = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_gender.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_gender.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_gender]
	);

	const verifyUserPhone = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber) {
				if (!isHolder) return ValidationResult.SUCCESS;
				return isSubscriber() &&
					(!user_phoneNumber.trim() || !isPhoneNumber(user_phoneNumber))
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			}
			if (!isHolder) {
				if (!user_phoneNumber.trim()) return ValidationResult.SUCCESS;
				return !isPhoneNumber(user_phoneNumber)
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			}
			return !user_phoneNumber.trim() || !isPhoneNumber(user_phoneNumber)
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_phoneNumber]
	);

	const verifyUserBirthdate = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_birthdate.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_birthdate.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_birthdate]
	);

	const verifyUserCPF = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber) {
				if (isSubscriber()) {
					if (!user_cpf.trim()) return ValidationResult.WARN;
				}
				if (!validateCPF(user_cpf)) return ValidationResult.ERROR;
				return ValidationResult.SUCCESS;
			}
			if (!user_cpf.trim()) return ValidationResult.WARN;
			if (!validateCPF(user_cpf)) return ValidationResult.ERROR;
			return ValidationResult.SUCCESS;
		},
		[isSubscriber, user_cpf]
	);

	const verifyUserZipcode = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_zipcode.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_zipcode.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_zipcode]
	);

	const verifyUserStreet = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (updated_street) return ValidationResult.INFO;
			if (verfySubscriber)
				return isSubscriber() && !user_street.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_street.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_street, updated_street]
	);

	const verifyUserDistrict = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (updated_district) return ValidationResult.INFO;
			if (verfySubscriber)
				return isSubscriber() && !user_district.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_district.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_district, updated_district]
	);

	const verifyUserCity = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (updated_city) return ValidationResult.INFO;
			if (verfySubscriber)
				return isSubscriber() && !user_city.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_city.trim() ? ValidationResult.WARN : ValidationResult.SUCCESS;
		},
		[isSubscriber, user_city, updated_city]
	);

	const verifyUserState = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (updated_state) return ValidationResult.INFO;
			if (verfySubscriber)
				return isSubscriber() && !user_state.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_state.trim() ? ValidationResult.WARN : ValidationResult.SUCCESS;
		},
		[isSubscriber, user_state, updated_state]
	);

	const verifyUserComplement = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (updated_complement) return ValidationResult.INFO;
			return ValidationResult.SUCCESS;
		},
		[isSubscriber, updated_complement]
	);

	const verifyUserAddressNumber = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_addressNumber.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_addressNumber.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_addressNumber]
	);

	const verifyUserHealthmapID = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_hmap_id.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_hmap_id.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_hmap_id]
	);

	const verifyUserHealthmapExterno = useCallback(
		(verfySubscriber = true): ValidationResult => {
			if (verfySubscriber)
				return isSubscriber() && !user_hmapExterno.trim()
					? ValidationResult.WARN
					: ValidationResult.SUCCESS;
			return !user_hmapExterno.trim()
				? ValidationResult.WARN
				: ValidationResult.SUCCESS;
		},
		[isSubscriber, user_hmapExterno]
	);

	const verifyUser = useCallback(() => {
		// E-mail
		if (user_email && !isEmail(user_email)) return false;
		// Telefone
		if (user_phoneNumber && !isPhoneNumber(user_phoneNumber)) return false;

		return true;
	}, [user_email, user_phoneNumber]);
	// #################################################################
	// Validação de campos - Fim
	// #################################################################

	useEffect(() => {
		setUser_name(user?.name || "");
		setUser_email(user?.email || "");
		setUser_phoneNumber(maskPhone(user?.phoneNumber || ""));
		if (user?.birthdate) {
			setUser_birthdate(
				DateTime.fromJSDate(new Date(user?.birthdate)).toFormat("dd/MM/yyyy")
			);
		} else {
			setUser_birthdate("");
		}
		setUser_gender(user?.gender || "");
		setUser_cpf(maskCpf(user?.cpf || ""));
		setUser_zipcode(maskZipcode(user?.zipcode || ""));
		setUser_street(user?.street || "");
		setUser_district(user?.district || "");
		setUser_state(user?.state || "");
		setUser_addressNumber(user?.addressNumber || "");
		setUser_city(user?.city || "");
		setUser_complement(user?.complement || "");
		setUser_avatarUrl(user?.avatarUrl || "");
		setUser_hmap_id(user?.hmap_id || "");
		setUser_hmapExterno(user?.hmapExterno || "");
		setUser_iugu_id(user?.iugu_id || "");

		setUser_accessProfiles(user?.roles?.map((p) => p.id) || []);

		setUser_auth_id(user?.auth_id || "");
		setUser_active(user?.active ?? false);
		setUser_pendent(user?.pendent ?? false);
	}, [user]);

	/**
	 * Verifica se os dados do usuário são válidos e habilita o botão de salvar.
	 */
	useEffect(() => {
		setEnableSaveUser(verifyUser());
	}, [verifyUser]);

	/**
	 * Verifica se deve exibir o alerta para preencher os dados para a Healthmap.
	 */
	useEffect(() => {
		setSubscriberFieldsAlert([
			[verifyUserName, "Nome"],
			[verifyUserEmail, "E-mail"],
			[verifyUserGender, "Gênero"],
			[verifyUserPhone, "Telefone"],
			[verifyUserBirthdate, "Data de nascimento"],
			[verifyUserCPF, "CPF"],
			[verifyUserZipcode, "CEP"],
			[verifyUserStreet, "Logradouro"],
			[verifyUserDistrict, "Bairro"],
			[verifyUserCity, "Cidade"],
			[verifyUserState, "Estado"],
			[verifyUserAddressNumber, "Número"],
		]);
	}, [
		verifyUserBirthdate,
		verifyUserCPF,
		verifyUserEmail,
		verifyUserGender,
		verifyUserName,
		verifyUserPhone,
		verifyUserZipcode,
		verifyUserStreet,
		verifyUserDistrict,
		verifyUserCity,
		verifyUserState,
		verifyUserAddressNumber,
	]);

	const filteredSubscriberFieldsAlert = subscriberFieldsAlert.filter(
		(tp) =>
			tp[0]() !== ValidationResult.SUCCESS && tp[0]() !== ValidationResult.INFO
	);

	const isSubscriberErrorAlert = subscriberFieldsAlert.some(
		(tp) => tp[0]() === ValidationResult.ERROR
	);

	return (
		<>
			<Row>
				<ModalStatusUser
					active={user_active}
					isSubscriber={isSubscriber()}
					show={showModalStatusUser}
					setShow={setShowModalStatusUser}
					handleConfirm={changeUserStatus}
				/>
				<ModalSyncAuth
					show={showModalSyncAuth}
					setShow={setShowModalSyncAuth}
					handleConfirm={handleSyncAuth}
				/>
				<Col md={12}>
					<Card>
						<Card.Body>
							{!loading &&
								isSubscriber() &&
								(filteredSubscriberFieldsAlert.length > 0 ||
									!user_hmap_id.trim() ||
									!user_hmapExterno.trim()) && (
									<Alert variant={isSubscriberErrorAlert ? "danger" : "warning"}>
										{filteredSubscriberFieldsAlert.length > 0 && (
											<>
												<h5>Assinante com campos incompletos</h5>
												Os dados são necessários para atualizar na Healthmap e Iugu.
												<ul>
													{filteredSubscriberFieldsAlert.map((tp) => (
														<li key={tp[1]}>{tp[1]}</li>
													))}
												</ul>
											</>
										)}
										{(!user_hmap_id.trim() || !user_hmapExterno.trim()) && (
											<>
												{filteredSubscriberFieldsAlert.length > 0 && <hr />}
												Dados da Healthmap incompletos.
												<ul>
													{!user_hmap_id.trim() && <li>Healthmap ID</li>}
													{!user_hmapExterno.trim() && <li>Código Externo</li>}
												</ul>
											</>
										)}
									</Alert>
								)}
							<InputGroup className="mb-3">
								<InputGroup.Text>Nome</InputGroup.Text>
								<FormControl
									className={`${classAlertField(verifyUserName)}`}
									value={user_name}
									onChange={(e) => setUser_name(e.target.value)}
									disabled={loading}
									required
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>E-mail</InputGroup.Text>
								<FormControl
									className={`${classAlertField(verifyUserEmail)}`}
									value={user_email}
									onChange={(e) => setUser_email(e.target.value)}
									disabled={loading}
								/>
							</InputGroup>
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>Telefone</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserPhone)}`}
										value={user_phoneNumber}
										onChange={(e) => setUser_phoneNumber(maskPhone(e.target.value))}
										disabled={loading}
										placeholder="Ex: (55) 12345-6789"
									/>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>CPF</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserCPF)}`}
										value={user_cpf}
										onChange={(e) => setUser_cpf(maskCpf(e.target.value))}
										disabled={loading}
									/>
									{verifyUserCPF() === ValidationResult.ERROR && (
										<OverlayTrigger
											delay={{ show: 250, hide: 400 }}
											placement="right"
											overlay={<Tooltip>CPF está inválido</Tooltip>}
										>
											<Button variant="danger">
												<span className="mdi mdi-information" />
											</Button>
										</OverlayTrigger>
									)}
								</InputGroup>
							</Row>
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>Gênero</InputGroup.Text>
									<Form.Select
										className={`${classAlertField(verifyUserGender)}`}
										value={user_gender}
										onChange={(e) => setUser_gender(e.target.value)}
										disabled={loading}
									>
										<option value=""> </option>
										<option value={Gender.Male}>Masculino</option>
										<option value={Gender.Female}>Feminino</option>
									</Form.Select>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>Data de nascimento</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserBirthdate)}`}
										value={user_birthdate}
										onChange={(e) => setUser_birthdate(maskDate(e.target.value, 8))}
										disabled={loading}
									/>
								</InputGroup>
							</Row>
							<hr />
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>CEP</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserZipcode)}`}
										value={user_zipcode}
										onChange={(e) => setUser_zipcode(maskZipcode(e.target.value))}
										disabled={loading}
										onKeyPress={(e) => {
											if (e.key === "Enter" && !user_city) handleFindAddress();
										}}
									/>
									<Button variant="secondary" onClick={() => handleFindAddress()}>
										<span className="mdi mdi-magnify" />
									</Button>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text color="succes">Logradouro</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserStreet)}`}
										value={user_street}
										onChange={(e) => setUser_street(e.target.value)}
										disabled={loading}
									/>
								</InputGroup>
							</Row>
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>Bairro</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserDistrict)}`}
										value={user_district}
										onChange={(e) => setUser_district(e.target.value)}
										disabled={loading}
									/>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>Cidade</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserCity)}`}
										value={user_city}
										onChange={(e) => setUser_city(e.target.value)}
										disabled={loading}
									/>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>Estado</InputGroup.Text>
									<Form.Select
										className={`${classAlertField(verifyUserState)}`}
										onChange={(e) => setUser_state(e.target.value)}
										disabled={loading}
										value={user_state}
									>
										<option value=""> </option>
										{Object.keys(UF).map((state) => (
											<option key={state} value={state}>
												{state}
											</option>
										))}
									</Form.Select>
									{/* <FormControl
										value={user_state}
										onChange={(e) => setUser_state(e.target.value)}
										disabled={loading}
									/> */}
								</InputGroup>
							</Row>
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>Número</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserAddressNumber)}`}
										value={user_addressNumber}
										onChange={(e) =>
											setUser_addressNumber(e.target.value.replace(/[^0-9]+/, ""))
										}
										disabled={loading}
									/>
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>Complemento</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserComplement)}`}
										value={user_complement}
										onChange={(e) => setUser_complement(e.target.value)}
										disabled={loading}
									/>
								</InputGroup>
							</Row>
							<hr />
							<h4>Avatar</h4>
							<InputGroup className="mb-3">
								<InputGroup.Text>URL</InputGroup.Text>
								<FormControl
									value={user_avatarUrl}
									onChange={(e) => setUser_avatarUrl(e.target.value)}
									disabled={loading}
								/>
							</InputGroup>
							{user_avatarUrl && (
								<Figure>
									<Figure.Image width={171} height={180} src={user_avatarUrl} />
								</Figure>
							)}
							<hr />
							<h4>Healthmap</h4>
							<Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>Healthmap ID</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserHealthmapID)}`}
										value={user_hmap_id}
										onChange={(e) => setUser_hmap_id(e.target.value)}
										disabled={loading}
									/>
									{getHmapWarningButton(user_hmap_id)}
								</InputGroup>
								<InputGroup as={Col}>
									<InputGroup.Text>Código Externo</InputGroup.Text>
									<FormControl
										className={`${classAlertField(verifyUserHealthmapExterno)}`}
										value={user_hmapExterno}
										onChange={(e) => setUser_hmapExterno(e.target.value)}
										disabled={loading}
									/>
									{getHmapWarningButton(user_hmapExterno)}
								</InputGroup>
							</Row>
							{(!user_hmap_id.trim() || !user_hmapExterno.trim()) && (
								<ButtonGroup>
									<Button
										variant="info"
										onClick={() => handleSyncHealthmap()}
										disabled={
											!enableSaveUser ||
											loading ||
											subscriberFieldsAlert.some(
												(i) => i[0](false) !== ValidationResult.SUCCESS
											)
										}
									>
										Salvar e vincular na Healthmap
									</Button>
									<OverlayTrigger
										delay={{ show: 250, hide: 400 }}
										placement="right"
										overlay={
											<Popover>
												<Popover.Header as="h5">
													Os seguintes dados devem estar preenchidos
												</Popover.Header>
												<Popover.Body>
													<ListGroup>
														{subscriberFieldsAlert.map((i) => (
															<ListGroup.Item
																variant={`${
																	i[0](false) !== ValidationResult.SUCCESS ? "danger" : "success"
																}`}
																className="mini-list-item"
																key={i[1]}
															>
																{i[1]}
															</ListGroup.Item>
														))}
													</ListGroup>
												</Popover.Body>
											</Popover>
										}
									>
										<Button variant="info">
											<span className="mdi mdi-information-outline" />
										</Button>
									</OverlayTrigger>
								</ButtonGroup>
							)}
							<hr />
							<Row>
								<h4>
									Iugu{" "}
									<OverlayTrigger
										delay={{ show: 250, hide: 400 }}
										placement="right"
										overlay={
											<Popover>
												<Popover.Header as="h5">
													Os seguintes dados são atualizados na plataforma
												</Popover.Header>
												<Popover.Body>
													<ListGroup>
														<ListGroup.Item
															className="mini-list-item"
															variant={`${
																verifyUserName() !== ValidationResult.SUCCESS
																	? "danger"
																	: "success"
															}`}
														>
															Nome*
														</ListGroup.Item>
														<ListGroup.Item
															className="mini-list-item"
															variant={`${
																verifyUserEmail(false) !== ValidationResult.SUCCESS
																	? "danger"
																	: "success"
															}`}
														>
															E-mail*
														</ListGroup.Item>
														<ListGroup.Item
															className="mini-list-item"
															variant={`${
																verifyUserCPF(false) !== ValidationResult.SUCCESS
																	? "warning"
																	: "success"
															}`}
														>
															CPF
														</ListGroup.Item>
														<ListGroup.Item
															className="mini-list-item"
															variant={`${
																verifyUserPhone(false) !== ValidationResult.SUCCESS
																	? "warning"
																	: "success"
															}`}
														>
															Telefone
														</ListGroup.Item>
													</ListGroup>
													<br />
													<strong>*Obrigatório</strong>
												</Popover.Body>
											</Popover>
										}
									>
										<span className="mdi mdi-information-outline" />
									</OverlayTrigger>
								</h4>
							</Row>
							<InputGroup className="mb-3">
								<InputGroup.Text>Iugu ID</InputGroup.Text>
								<FormControl
									value={user_iugu_id}
									onChange={(e) => setUser_iugu_id(e.target.value)}
									disabled={loading}
								/>
								<OverlayTrigger
									delay={{ show: 250, hide: 400 }}
									placement="left"
									overlay={<Tooltip>Código do cliente na plataforma Iugu</Tooltip>}
								>
									<Button variant="secondary">
										<span className="mdi mdi-information" />
									</Button>
								</OverlayTrigger>
							</InputGroup>
							<hr />
							<h4>Firebase</h4>
							<InputGroup className="mb-3">
								<InputGroup.Text>UID Authentication</InputGroup.Text>
								<FormControl
									value={user_auth_id}
									onChange={(e) => setUser_auth_id(e.target.value)}
									disabled={loading}
								/>
								<OverlayTrigger
									delay={{ show: 250, hide: 400 }}
									placement="left"
									overlay={
										<Tooltip>
											UID do usuário no Authentication. Deve possuir e-mail preenchido
										</Tooltip>
									}
								>
									<Button variant="secondary">
										<span className="mdi mdi-information" />
									</Button>
								</OverlayTrigger>
							</InputGroup>
							{!user_auth_id.trim() && (
								<Button
									variant="info"
									onClick={() => setShowModalSyncAuth(true)}
									disabled={
										!enableSaveUser ||
										loading ||
										verifyUserEmail(false) !== ValidationResult.SUCCESS
									}
								>
									Vincular Authentication
								</Button>
							)}
							<hr />
							<Button
								variant="primary"
								onClick={() => handleSaveUser()}
								disabled={!enableSaveUser || loading || isSubscriberErrorAlert}
							>
								Salvar
							</Button>{" "}
							<Button
								variant="primary"
								onClick={() => setShowModalStatusUser(true)}
								disabled={!enableSaveUser || loading || isSubscriberErrorAlert}
							>
								{user_active ? "Inativar" : "Ativar"}
							</Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{accessProfileList && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<BasicTable headers={["Código", "Nome", "Descrição", "Ativo"]}>
									{accessProfileList.map((profile) => (
										<BasicRow key={`profile-${profile.id!}`}>
											<BasicColumn>{profile.id}</BasicColumn>
											<BasicColumn>{profile.name}</BasicColumn>
											<BasicColumn>{profile.description}</BasicColumn>
											<BasicColumn>
												<Form.Check
													id={`check-${profile.id}`}
													checked={user_accessProfiles.includes(profile.id)}
													onChange={(e) => updateAccessProfile(profile.id, e.target.checked)}
													disabled={loading}
												/>
											</BasicColumn>
										</BasicRow>
									))}
								</BasicTable>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</>
	);
};

export default TabBasicData;
