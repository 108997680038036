import React from "react";

const TopBarDropdownItem: React.FC<{
	onClick: () => void;
	title: string;
	icon?: string;
}> = ({ onClick, title, icon }) => (
	<span
		onClick={onClick}
		role="button"
		tabIndex={0}
		className="dropdown-item notify-item"
		style={{ cursor: "pointer" }}
	>
		{icon && <i className={icon} />}
		<span>{title}</span>
	</span>
);

TopBarDropdownItem.defaultProps = {
	icon: undefined,
};

export default TopBarDropdownItem;
