import React from "react";
import Dinero from "dinero.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import Routes from "./routes";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/icons.css";
import "./assets/css/app-modern.css";

import "./utils/firebase";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";

/* Theme variables */
import "./theme/variables.css";
import "./theme/fonts.css";
import "./theme/global.css";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

Dinero.defaultCurrency = "BRL";
Dinero.defaultPrecision = 2;
Dinero.defaultAmount = 0;
Dinero.globalLocale = "pt-BR";

registerLocale("pt-BR", ptBr);
setDefaultLocale("pt-BR");

const App: React.FC = () => (
	<AppProvider>
		<AuthProvider>
			<ToastContainer />
			<Router>
				<Routes />
			</Router>
		</AuthProvider>
	</AppProvider>
);

export default App;
