import pdfMake from "pdfmake/build/pdfmake";
import { Content, DynamicContent } from "pdfmake/interfaces";

export default abstract class BaseReportService<M, Q> {
	readonly title: string;

	constructor(title: string) {
		this.title = title;
	}

	abstract getReport(query: Q): Promise<M[]>;

	protected abstract getContent(data: M[]): Content;

	public constructReport(data: M[], title = this.title) {
		return pdfMake.createPdf({
			pageSize: "A4",
			pageMargins: [14, 53, 14, 48],
			header: this.getHeader(title),
			footer: this.getFooter(),
			content: this.getContent(data),
			styles: {
				reportName: {
					fontSize: 9,
					bold: true,
					alignment: "center",
					margin: [0, 4, 0, 0],
				},
			},
		});
	}

	private getHeader(title = this.title): DynamicContent {
		return (currentPage, pageCount, pageSize) => [
			{
				margin: [14, 12, 14, 0],
				layout: "noBorders",
				table: {
					widths: ["*"],
					body: [[{ text: title, style: "reportName" }]],
				},
			},
			{
				canvas: [
					{
						type: "line",
						x1: 10,
						y1: 10,
						x2: pageSize.width - 10,
						y2: 10,
						lineWidth: 1,
					},
				],
			},
		];
	}

	private getFooter(): DynamicContent {
		return (currentPage, pageCount, pageSize) => [
			{
				canvas: [
					{
						type: "line",
						x1: 10,
						y1: 10,
						x2: pageSize.width - 10,
						y2: 10,
						lineWidth: 1,
					},
				],
			},
			{
				columns: [
					{
						text: "",
						fontSize: 7,
						alignment: "left",
						margin: [0, 15, 0, 0],
					},
					{
						text: `${new Date().getFullYear()} © Vívidus`,
						fontSize: 7,
						alignment: "center",
						margin: [0, 15, 0, 0],
					},
					{
						text: `Página ${currentPage.toString()} de ${pageCount.toString()}`,
						alignment: "right",
						margin: [0, 15, 10, 0],
						fontSize: 7,
					},
				],
			},
		];
	}
}
