/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
	signed: boolean;
	redirectPath?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
	signed,
	redirectPath = "/login",
}) => {
	if (!signed) {
		localStorage.setItem("original-path", window.location.pathname);
		return <Navigate to={redirectPath} />;
	}
	return <Outlet />;
};

PrivateRoute.defaultProps = {
	redirectPath: "/login",
};

export default PrivateRoute;
