import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";

const SideBarTitle: React.FC<{ title: string }> = ({ title, children }) => {
	const { hasPermission } = useAuth();

	const hasLocalPermission = () => {
		if (!children) return false;
		const childrenLocal = Array.isArray(children) ? children : [children];
		const childHasPermission = (childMenu: any): boolean => {
			const childMenuArray = Array.isArray(childMenu) ? childMenu : [childMenu];
			return childMenuArray.some((childItem) => {
				if (!childItem.props.permissions) {
					if (childItem.props.children) {
						return childHasPermission(childItem.props.children);
					}
					return true;
				}
				return hasPermission(childItem.props.permissions);
			});
		};

		return childrenLocal.some((childMenu) => childHasPermission(childMenu));
	};
	return hasLocalPermission() ? (
		<>
			<li className="side-nav-title side-nav-item">{title}</li>
			{children}
		</>
	) : null;
};

export default SideBarTitle;
