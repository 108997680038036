import VividusAPI from "../../../apis/VividusAPI";
import QueryGetConfigurationDto from "../../../apis/VividusAPI/dto/QueryGetConfigurationDto";
import QueryGetConfigurationPaginatedDto from "../../../apis/VividusAPI/dto/QueryGetConfigurationPaginatedDto";

export default class ConfigurationService {
	fetchById(id: number, body: QueryGetConfigurationDto = {}) {
		return VividusAPI.getConfiguration(id, body);
	}

	list(body: QueryGetConfigurationPaginatedDto = {}) {
		return VividusAPI.getConfigurationPaginated(body);
	}
}
