import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";

const SideBarMenu: React.FC<{
	id: string;
	title: string;
	icon?: string;
	level?: "second" | "third" | "forth";
}> = ({ id, title, icon, level, children }) => {
	const { hasPermission } = useAuth();

	const hasLocalPermission = () => {
		if (!children) return false;
		const childrenLocal = Array.isArray(children) ? children : [children];
		return childrenLocal.some((child) =>
			hasPermission(child.props.permissions || [])
		);
	};

	const getLevelClassName = () => {
		switch (level) {
			case "second":
				return "side-nav-second-level";
			case "third":
				return "side-nav-third-level";
			case "forth":
				return "side-nav-forth-level";
			default:
				return "";
		}
	};

	return hasLocalPermission() ? (
		<li className="side-nav-item">
			<a
				href={`#${id}`}
				data-bs-toggle="collapse"
				aria-controls={id}
				className="side-nav-link"
			>
				{icon && <i className={icon} />}
				<span> {title} </span>
				<span className="menu-arrow" />
			</a>
			<div className="collapse" id={id}>
				<ul className={`${getLevelClassName()}`}>{children}</ul>
			</div>
		</li>
	) : null;
};

SideBarMenu.defaultProps = {
	icon: undefined,
	level: "second",
};

export default SideBarMenu;
