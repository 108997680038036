import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	InputGroup,
	Form,
	Card,
	Row,
	Col,
	Accordion,
	Alert,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import useToast from "../../../../../../hooks/Toast";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { User } from "../../../../../../modules/security/user/entities/User";
import { Subscription } from "../../../../../../modules/finance/subscription/entities/Subscription";
import { Role } from "../../../../../../modules/security/permission/enum/role.enum";
import { Customer } from "../../../../../../modules/security/customer/entities/Customer";
import CustomerService from "../../../../../../modules/security/customer/CustomerService";
import SubscriptionPanel from "./components/SubscriptionPanel";
import ModalCreateSubscription from "./components/ModalCreateSubscription";
import UserService from "../../../../../../modules/security/user/UserService";
import { Plan } from "../../../../../../modules/finance/plan/entities/Plan";
import PlanService from "../../../../../../modules/finance/plan/PlanService";
import SubscriptionService from "../../../../../../modules/finance/subscription/SubscriptionService";

const userService = new UserService();
const planSevice = new PlanService();
const customerService = new CustomerService();
const subscriptionService = new SubscriptionService();

interface TabSubscriberProps {
	user?: User;
	subscriptions?: Subscription[];
	loading: boolean;
	setLoading: (loading: boolean) => void;
	handleBeforeUpdate: () => Promise<void>;
}

const TabSubscriber: React.FC<TabSubscriberProps> = ({
	user,
	subscriptions,
	loading,
	setLoading,
	handleBeforeUpdate,
}) => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [planList, setPlanList] = useState<Plan[]>([]);
	const [customerList, setCustomerList] = useState<Customer[]>([]);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedCustomer, setSelectedCustomer] = useState<number>();

	const handleCreateSubscription = useCallback(
		async (plan_id: number, business: boolean) => {
			if (!user?.id) return;
			if (!hasPermission(Role.ADM_CREATE_SUBSCRIPTION)) {
				showError("Permissão negada.");
				return;
			}
			if (!user?.cpf) {
				showError("Usuário sem CPF cadastrado.");
				return;
			}
			if (!user?.email) {
				showError("Usuário sem E-mail cadastrado.");
				return;
			}
			setLoading(true);
			try {
				await subscriptionService.create({
					user_id: user.id,
					plan_id,
					business,
				});
				setShowModal(false);
				await handleBeforeUpdate();
			} catch (error) {
				showError(error);
			} finally {
				setLoading(false);
			}
		},
		[
			handleBeforeUpdate,
			hasPermission,
			setLoading,
			showError,
			user?.cpf,
			user?.email,
			user?.id,
		]
	);

	const handleUpdateUserSubscription = useCallback(async () => {
		if (!user?.id) return;
		if (!hasPermission(Role.ADM_UPD_SUBSCRIPTION)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await userService.updateUser(+user.id, {
				customer_id: selectedCustomer,
			});
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	}, [hasPermission, setLoading, showError, user, selectedCustomer]);

	const handleOpenModalSubscription = useCallback(() => {
		if (!hasPermission(Role.ADM_UPD_SUBSCRIPTION)) return;
		setShowModal(true);
	}, [hasPermission]);

	const showUpdateSubscriberDetails = useCallback(
		() => hasPermission(Role.ADM_UPD_SUBSCRIPTION),
		[hasPermission]
	);

	const showCreateButton = useCallback(
		() =>
			hasPermission(Role.ADM_CREATE_SUBSCRIPTION) &&
			subscriptions?.length === 0 &&
			!user?.holder?.id,
		[hasPermission, subscriptions, user]
	);

	useEffect(() => {
		customerService
			.list({ take: 0 })
			.then((documents) => {
				setCustomerList(documents.data);
			})
			.catch((error) => showError(error));
	}, [showError]);

	useEffect(() => {
		planSevice
			.list({ take: 0 })
			.then((documents) => {
				setPlanList(documents.data);
			})
			.catch((error) => showError(error));
	}, [showError]);

	useEffect(() => {
		if (!customerList.map((c) => c.id).includes(user?.customer?.id ?? 0))
			setSelectedCustomer(0);
		setSelectedCustomer(user?.customer?.id ?? 0);
	}, [user, customerList]);

	const filterPlanList = (plan: Plan) => {
		if (!user?.id) return true;
		try {
			const [, , family] = plan.code.split("_");
			const userQuantity = 1 + (user?.dependents || []).length;

			// Indivivual
			if (userQuantity === 1) return typeof family === "undefined";

			// Família
			if (typeof family === "undefined") return false;
			const depsNumber = parseInt(family.charAt(1));
			return depsNumber === userQuantity - 1;
		} catch (error) {
			return false;
		}
	};

	const filteredPlanList = planList.filter(filterPlanList);

	return (
		<Row>
			<ModalCreateSubscription
				plans={filteredPlanList}
				loading={loading}
				setShow={setShowModal}
				show={showModal}
				handleCreateSubscription={handleCreateSubscription}
			/>
			<Col md={12}>
				<Card>
					<Card.Body>
						{user?.roles &&
							subscriptions?.some((s) => s.active) &&
							!user.roles.some((r) =>
								r.permissions.some((p) => p.code === Role.IS_MEMBER)
							) && (
								<Alert variant="warning">
									Assinante sem o perfil de acesso definido.{" "}
									<OverlayTrigger
										delay={{ show: 250, hide: 400 }}
										placement="bottom"
										overlay={
											<Tooltip>
												Assinante possui assinaturas ativas, mas não possui o perfil de
												Assinante ativo.
											</Tooltip>
										}
									>
										<span className="mdi mdi-information" />
									</OverlayTrigger>
								</Alert>
							)}
						<Form>
							<fieldset disabled={loading || !showUpdateSubscriberDetails()}>
								<Col md={4}>
									<InputGroup className="mb-3">
										<InputGroup.Text>Empresa</InputGroup.Text>
										<Form.Select
											onChange={(e) => setSelectedCustomer(+e.target.value)}
											disabled={loading}
											value={selectedCustomer}
										>
											<option value={0}> </option>
											{customerList.map((customer) => (
												<option key={customer.id} value={customer.id}>
													{customer.name}
												</option>
											))}
										</Form.Select>
									</InputGroup>
								</Col>
								{showUpdateSubscriberDetails() && (
									<Button
										variant="primary"
										onClick={() => handleUpdateUserSubscription()}
									>
										Atualizar
									</Button>
								)}
							</fieldset>
						</Form>
						{subscriptions && subscriptions.length > 0 && (
							<>
								<hr />
								<Accordion defaultActiveKey="0" alwaysOpen>
									{subscriptions.map((sub, index) => (
										<SubscriptionPanel
											key={sub.id}
											eventKey={index.toString()}
											subscription={sub}
											loading={loading}
											setLoading={setLoading}
											handleBeforeUpdate={handleBeforeUpdate}
										/>
									))}
								</Accordion>
							</>
						)}
						{showCreateButton() && (
							<>
								<hr />
								<Button
									disabled={loading}
									variant="primary"
									onClick={() => handleOpenModalSubscription()}
								>
									Criar Assinatura
								</Button>
							</>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default TabSubscriber;
