import VividusAPI from "../../../apis/VividusAPI";
import BodyPatchSubscriptionDto from "../../../apis/VividusAPI/dto/BodyPatchSubscriptionDto";
import BodyPostSubscriptionAdminDto from "../../../apis/VividusAPI/dto/BodyPostSubscriptionAdminDto";
import { SubscriptionPaymentType } from "./enum/SubscriptionPaymentType";

export default class SubscriptionService {
	create(body: BodyPostSubscriptionAdminDto) {
		return VividusAPI.postSubscriptionAdmin(body);
	}

	update(id: number, body: BodyPatchSubscriptionDto) {
		return VividusAPI.patchSubscription(id, body);
	}

	fetchSimulatePlanChange(user_id: number, iuguPlan_id: string) {
		return VividusAPI.getSimulatePlanChange(user_id, iuguPlan_id);
	}

	changePlan(
		user_id: number,
		planCode: string,
		method: SubscriptionPaymentType
	) {
		return VividusAPI.postPlanChange(user_id, {
			planCode,
			method,
		});
	}
}
