import { SubscriptionPaymentType } from "../modules/finance/subscription/enum/SubscriptionPaymentType";

export const isEmail = (text: string) =>
	/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
		text
	);

/**
 * (16) 91231-6165
 */
export const isPhoneNumber = (text: string) =>
	/^(\(\d{2}\)\s)(\d{4}-\d{4})$/g.test(text) ||
	/^(\(\d{2}\)\s)(\d{5}-\d{4})$/g.test(text);

/**
 * 00000000000
 *
 * 00000000000000
 *
 * 000.000.000-00
 *
 * 00.000.000/0000-00
 *
 * 000000000-00
 *
 * 00000000/0000-00
 */
export const isCpfOrCnpj = (text: string) =>
	/^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/g.test(
		text
	);

export const isZipcodeForProvider = (text: string) =>
	/^\*$/g.test(text) ||
	/^[0-9]{8}$/g.test(text) ||
	/^\*$/g.test(text) ||
	/^\*-\*$/g.test(text) ||
	/^\*-[0-9]{8}$/g.test(text) ||
	/^[0-9]{8}-\*$/g.test(text) ||
	/^[0-9]{8}-[0-9]{8}$/g.test(text);

export const maskPhone = (phone: string) => {
	if (!phone) return "";
	phone = phone.replace(/\D/g, "");
	if (phone.length === 13 || phone.length === 12) phone = phone.slice(2);
	if (phone.length < 8) return phone;
	if (phone.length > 11) {
		phone = phone.slice(1);
	}
	if (phone.length === 11) {
		return phone.replace(
			/(\d{2})(\d{5})(\d{4})/,
			(regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`
		);
	}
	if (phone.length === 10) {
		return phone.replace(
			/(\d{2})(\d{4})(\d{4})/,
			(regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`
		);
	}
	if (phone.length === 9) {
		return phone.replace(
			/(\d{5})(\d{4})/,
			(regex, arg1, arg2) => `${arg1}-${arg2}`
		);
	}
	if (phone.length === 8) {
		return phone.replace(
			/(\d{4})(\d{4})/,
			(regex, arg1, arg2) => `${arg1}-${arg2}`
		);
	}
	return phone;
};

export const unformatCpf = (cpf: string) => {
	if (!cpf) return "";
	return cpf.replace(/\D/g, "");
};

export const maskCpf = (cpf: string): string => {
	if (!cpf) return "";
	cpf = cpf.replace(/\D/g, "");
	if (cpf.length < 4) return cpf;
	if (cpf.length > 11) {
		cpf = cpf.slice(0, cpf.length - 1);
	}
	if (cpf.length <= 6) {
		return cpf.replace(
			/(\d{1,3})(\d{3})/,
			(regex, arg1, arg2) => `${arg1}.${arg2}`
		);
	}
	if (cpf.length <= 9) {
		return cpf.replace(
			/(\d{1,3})(\d{3})(\d{3})/,
			(regex, arg1, arg2, arg3) => `${arg1}.${arg2}.${arg3}`
		);
	}
	if (cpf.length <= 11) {
		return cpf.replace(
			/(\d{1,3})(\d{3})(\d{3})(\d{2})/,
			(regex, arg1, arg2, arg3, arg4) => `${arg1}.${arg2}.${arg3}-${arg4}`
		);
	}
	return cpf;
};

export const maskCnpj = (cnpj: string): string => {
	if (!cnpj) return "";
	cnpj = cnpj.replace(/\D/g, "");
	if (cnpj.length < 3) return cnpj;
	if (cnpj.length > 14) {
		cnpj = cnpj.slice(0, cnpj.length - 1);
	}
	if (cnpj.length <= 5) {
		return cnpj.replace(
			/(\d{1,2})(\d{3})/,
			(regex, arg1, arg2) => `${arg1}.${arg2}`
		);
	}
	if (cnpj.length <= 8) {
		return cnpj.replace(
			/(\d{1,2})(\d{3})(\d{3})/,
			(regex, arg1, arg2, arg3) => `${arg1}.${arg2}.${arg3}`
		);
	}
	if (cnpj.length <= 12) {
		return cnpj.replace(
			/(\d{1,2})(\d{3})(\d{3})(\d{4})/,
			(regex, arg1, arg2, arg3, arg4) => `${arg1}.${arg2}.${arg3}/${arg4}`
		);
	}
	if (cnpj.length <= 14) {
		return cnpj.replace(
			/(\d{1,2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			(regex, arg1, arg2, arg3, arg4, arg5) =>
				`${arg1}.${arg2}.${arg3}/${arg4}-${arg5}`
		);
	}
	return cnpj;
};

export const validateCPF = (cpf: string) => {
	if (!cpf) return true;
	cpf = unformatCpf(cpf);
	cpf = cpf.replace(/\D/g, "");
	if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
	let result = true;
	[9, 10].forEach((j) => {
		let soma = 0;
		let r;
		cpf
			.split(/(?=)/)
			.splice(0, j)
			.forEach((e, i) => {
				soma += parseInt(e) * (j + 2 - (i + 1));
			});
		r = soma % 11;
		r = r < 2 ? 0 : 11 - r;
		if (r !== parseInt(cpf.substring(j, j + 1))) result = false;
	});
	return result;
};

export const maskDate = (date: string, limit = 12) => {
	if (!date) return "";
	date = date.replace(/\D/g, "");
	if (date.length <= 2) return date;
	if (date.length > limit) {
		date = date.slice(0, date.length - 1);
	}
	if (date.length <= 4) {
		return date.replace(
			/(\d{1,2})(\d{2})/,
			(regex: any, arg1: any, arg2: any) => `${arg1}/${arg2}`
		);
	}
	if (date.length <= 8) {
		return date.replace(
			/(\d{1,2})(\d{2})(\d{2})/,
			(regex: any, arg1: any, arg2: any, arg3: any) => `${arg1}/${arg2}/${arg3}`
		);
	}
	return date;
};

export const maskPercentage = (percentage: string) => {
	if (!percentage) percentage = "0";
	percentage = parseInt(percentage.replace(/[^0-9]/g, "")).toString();
	if (percentage.length > 4)
		percentage = percentage.slice(0, percentage.length - 1);
	return percentage
		.padStart(4, "0")
		.replace(
			/(\d{1})(\d{1})(\d{1})(\d{1})/,
			(regex: any, arg1: any, arg2: any, arg3: any, arg4: any) =>
				arg1 === "0" ? `${arg2},${arg3}${arg4}` : `${arg1}${arg2},${arg3}${arg4}`
		);
};

export const convertCodePlanToCodeIuguPlan = (
	codePlan: string,
	installments = 1
) => {
	if (codePlan.length === 0) return "";
	const [type, recurrence, numberOfDependents] = codePlan.split("_");
	const numberOfUsers =
		parseInt((numberOfDependents || "0").replace(/[^0-9]/g, "")) + 1;
	if (numberOfUsers > 1) {
		return `${type}_${recurrence}_${installments}_${numberOfDependents.replace(
			/[^0-9]/g,
			""
		)}`;
	}
	return `${type}_${recurrence}_${installments}`;
};

export const getPlanRecurrenceText = (codePlan: string) => {
	if (codePlan.includes("monthly")) return "Mensal";
	if (codePlan.includes("annual")) return "Anual";
	return "";
};

export const getSubscriptionTypeText = (business: boolean) =>
	business ? "B2B" : "B2C";

export const getSubscriptionPaymentTypeText = (
	paymentType?: SubscriptionPaymentType
) => {
	switch (paymentType) {
		case SubscriptionPaymentType.PIX:
			return "PIX";
		case SubscriptionPaymentType.CARD:
			return "Cartão";
		default:
			return "";
	}
};

export const maskZipcode = (zipcode: string) => {
	if (!zipcode) return "";
	zipcode = zipcode.replace(/\D/g, "");
	if (zipcode.length < 6) return zipcode;
	if (zipcode.length > 8) {
		zipcode = zipcode.slice(0, zipcode.length - 1);
	}
	return zipcode.replace(
		/(\d{1,5})(\d{3})/,
		(regex, arg1, arg2) => `${arg1}-${arg2}`
	);
};

export const convertIdArtifactPlanToIdIuguPlan = (
	idArtifactPlan: string,
	installments = 1
) => {
	if (idArtifactPlan.length === 0) return "";
	const [type, recurrence, numberOfDependents] = idArtifactPlan.split("_");
	const numberOfUsers =
		parseInt((numberOfDependents || "0").replace(/[^0-9]/g, "")) + 1;
	if (numberOfUsers > 1) {
		return `${type}_${recurrence}_${installments}_${numberOfDependents.replace(
			/[^0-9]/g,
			""
		)}`;
	}
	return `${type}_${recurrence}_${installments}`;
};