import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Form,
	FormControl,
	InputGroup,
	Modal,
} from "react-bootstrap";
import ResponseGetChangePlanSimulationDto from "../../../../../../../../apis/VividusAPI/dto/ResponseGetChangePlanSimulationDto";
import LoadingSpinner from "../../../../../../../../components/LoadingSpinner";
import useToast from "../../../../../../../../hooks/Toast";
import { Plan } from "../../../../../../../../modules/finance/plan/entities/Plan";
import PlanService from "../../../../../../../../modules/finance/plan/PlanService";
import { Subscription } from "../../../../../../../../modules/finance/subscription/entities/Subscription";
import { SubscriptionPaymentType } from "../../../../../../../../modules/finance/subscription/enum/SubscriptionPaymentType";
import SubscriptionService from "../../../../../../../../modules/finance/subscription/SubscriptionService";
import { formatToLocaleCurrency } from "../../../../../../../../utils/currency";
import {
	convertIdArtifactPlanToIdIuguPlan,
	getPlanRecurrenceText,
} from "../../../../../../../../utils/string";

interface ModalChangePlanProps {
	subscription: Subscription;
	show: boolean;
	setShow: (show: boolean) => void;
	handleBeforeUpdate: () => void;
}

const subscriptionService = new SubscriptionService();
const planSevice = new PlanService();

const ModalChangePlan: React.FC<ModalChangePlanProps> = ({
	subscription,
	show,
	setShow,
	handleBeforeUpdate,
}) => {
	const { showError } = useToast();

	const [planList, setPlanList] = useState<Plan[]>([]);
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [responseSimulation, setResponseSimulation] =
		useState<ResponseGetChangePlanSimulationDto>();

	const [selectedPlan, setSelectedPlan] = useState<string>("0");

	const handleChangePlan = async (plan: string) => {
		if (plan === "0") return;
		try {
			setShowLoading(true);
			const localDetails = await subscriptionService.changePlan(
				subscription.user.id,
				selectedPlan,
				subscription.paymentType || SubscriptionPaymentType.PIX
			);
			setResponseSimulation(localDetails);
			handleBeforeUpdate();
			setShow(false);
		} catch (error) {
			showError(error);
		} finally {
			setShowLoading(false);
		}
	};

	const handleChangeSubscriptionPayment = useCallback(async () => {
		if (
			selectedPlan === "0" ||
			selectedPlan === subscription.plan.code ||
			subscription.business
		)
			return;
		try {
			setShowLoading(true);
			const localDetails = await subscriptionService.fetchSimulatePlanChange(
				subscription.user.id,
				convertIdArtifactPlanToIdIuguPlan(selectedPlan)
			);
			setResponseSimulation(localDetails);
		} catch (error) {
			showError(error);
		} finally {
			setShowLoading(false);
		}
	}, [
		selectedPlan,
		showError,
		subscription.user.id,
		subscription.plan.code,
		subscription.business,
	]);

	useEffect(() => {
		handleChangeSubscriptionPayment();
	}, [handleChangeSubscriptionPayment]);

	useEffect(() => {
		planSevice
			.list({ take: 0 })
			.then((documents) => {
				setPlanList(documents.data);
			})
			.catch((error) => showError(error));
	}, [showError]);

	const filterPlanList = (plan: Plan) => {
		if (plan.code === subscription.plan.code) return false;
		if (!subscription.user?.id) return true;
		try {
			const [, , family] = plan.code.split("_");
			const userQuantity = 1 + (subscription.user?.dependents || []).length;

			// Indivivual
			if (userQuantity === 1) return typeof family === "undefined";

			// Família
			if (typeof family === "undefined") return false;
			const depsNumber = parseInt(family.charAt(1));
			return depsNumber === userQuantity - 1;
		} catch (error) {
			return false;
		}
	};

	const filteredPlanList = planList.filter(filterPlanList);

	return (
		<Modal
			show={show}
			onHide={() => setShow(false)}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Alteração de plano</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<InputGroup className="mb-3">
						<InputGroup.Text>Plano</InputGroup.Text>
						<Form.Select
							onChange={(e) => setSelectedPlan(e.target.value)}
							disabled={showLoading}
							value={selectedPlan}
						>
							<option value="0"> </option>
							{filteredPlanList
								.sort((a, b) => a.code.localeCompare(b.code))
								.map((plan) => (
									<option key={plan.code} value={plan.code}>
										{`${plan.name} (${getPlanRecurrenceText(plan.code)})`}
									</option>
								))}
						</Form.Select>
					</InputGroup>
					{showLoading && <LoadingSpinner />}
					{!showLoading && !subscription.business && responseSimulation && (
						<>
							<hr />
							<h4>Revise as informações do novo plano</h4>
							<p>
								Será gerada uma nova cobrança para o plano escolhido, com o desconto
								proporcional do valor já pago do plano atual.
							</p>
							<p>O usuário receberá uma notificação da alteração de plano.</p>
							<InputGroup className="mb-3">
								<InputGroup.Text>Plano atual</InputGroup.Text>
								<FormControl value={responseSimulation.old_plan?.name ?? ""} readOnly />
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Novo plano</InputGroup.Text>
								<FormControl value={responseSimulation.new_plan?.name ?? ""} readOnly />
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Primeira cobrança</InputGroup.Text>
								<FormControl
									value={formatToLocaleCurrency(responseSimulation.cost || 0)}
									readOnly
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Desconto</InputGroup.Text>
								<FormControl
									value={formatToLocaleCurrency(responseSimulation.discount || 0)}
									readOnly
								/>
							</InputGroup>
						</>
					)}
					{!showLoading && subscription.business && (
						<>
							<hr />
							<h4>Revise as informações do novo plano</h4>
							<p>O usuário receberá uma notificação da alteração de plano.</p>
							<InputGroup className="mb-3">
								<InputGroup.Text>Plano atual</InputGroup.Text>
								<FormControl value={subscription.plan.name ?? ""} readOnly />
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text>Novo plano</InputGroup.Text>
								<FormControl
									value={planList.find((plan) => plan.code === selectedPlan)?.name ?? ""}
									readOnly
								/>
							</InputGroup>
						</>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => setShow(false)}
					disabled={showLoading}
				>
					Cancelar
				</Button>
				<Button
					disabled={showLoading || selectedPlan === "0"}
					variant="primary"
					onClick={() => handleChangePlan(selectedPlan)}
				>
					Atualizar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalChangePlan;
