import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import { Data } from "react-csv/components/CommonPropTypes";
import { MetaDto } from "../../apis/VividusAPI/dto/ResponsePaginationDto";
import ExportCSVButton from "../ExportCSVButton";
import "./index.css";

const getValueOfCell = (child: any): any | string => {
	if (typeof child === "undefined") return "";
	if (typeof child === "string" || typeof child === "number") return child;
	if (child?.props?.children) return getValueOfCell(child.props.children);
	return "";
};
export interface PaginationTableProps {
	idTable: string;
	headers: string[];
	limit: number;
	meta: MetaDto | undefined;
	onLimitChange: (limit: number) => void;
	onSearchChange: (text: string) => void;
	onPageChange: (page: number) => void;
}

const PaginationTable: React.FC<PaginationTableProps> = ({
	headers,
	idTable,
	meta,
	limit,
	onLimitChange,
	onSearchChange,
	onPageChange,
	children,
}) => {
	const [dataCSV, setDataCSV] = useState<Data>();
	const [search, setSearch] = useState("");

	const changePage = (page: number) => {
		if (page < 1 || page > (meta?.pageCount || 1)) return;
		onPageChange(page);
	};

	const composeArrayInt = (start: number, end: number) => {
		if (start < 1) start = 1;
		const array = [];
		for (let i = start; i < end; i++) {
			if (i === meta?.page || i > (meta?.pageCount || 1)) break;
			array.push(i);
		}
		return (
			<>
				{array.map((i) => (
					<Pagination.Item key={i} onClick={() => changePage(i)}>
						{i}
					</Pagination.Item>
				))}
			</>
		);
	};

	const composeFooterText = (itemCount: number, take: number, page: number) => {
		if (take === 0) return `Mostrando ${itemCount} itens`;
		return `Mostrando ${page * take - take + 1} a
		${page * take >= itemCount ? itemCount : page * take} 
		de ${itemCount}`;
	};

	useEffect(() => {
		const childrenLocal = Array.isArray(children) ? children : [children];
		const data = childrenLocal?.map((childItem) => {
			if (!childItem.props.children) return [];
			const childItemLocal = Array.isArray(children)
				? childItem.props.children
				: [childItem.props.children];
			return (childItemLocal as any[]).map((childItemChild) =>
				getValueOfCell(childItemChild)
			);
		});
		setDataCSV([headers, ...data]);
	}, [headers, children]);

	return (
		<div className="table-responsive">
			<Row>
				<Col md={4}>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Buscar"
							aria-controls={idTable}
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									changePage(1);
									onSearchChange(search);
								}
							}}
						/>
					</InputGroup>
				</Col>
				<Col md={4} />
				<Col md={3}>
					<InputGroup className="mb-3">
						<InputGroup.Text>Mostrar</InputGroup.Text>
						<Form.Select onChange={(e) => onLimitChange(Number(e.target.value))}>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
							<option value={0}>Todos ⚠️</option>
						</Form.Select>
					</InputGroup>
				</Col>
				<Col md={1}>
					<ExportCSVButton
						handleExport={() => !!dataCSV}
						data={dataCSV}
						filename={idTable}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table hover striped id={idTable}>
						<thead>
							<tr>
								{headers.map((header) => (
									<th key={header}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>{children}</tbody>
					</Table>
				</Col>
			</Row>
			{meta && (
				<Row>
					<div className="text-center mb-1">
						<Col>{composeFooterText(meta.itemCount, meta.take, meta.page)}</Col>
					</div>
					{limit !== 0 && (
						<Col style={{ display: "flex", justifyContent: "center" }}>
							<Pagination>
								{meta.hasPreviousPage && (
									<>
										<Pagination.First onClick={() => changePage(1)} />
										<Pagination.Prev onClick={() => changePage(meta.page - 1)}>
											Anterior
										</Pagination.Prev>
									</>
								)}
								{composeArrayInt(meta.page - 3, meta?.page)}
								<Pagination.Item active>
									<strong>{meta?.page}</strong>
								</Pagination.Item>
								{composeArrayInt(meta.page + 1, meta.page + 4)}
								{meta.hasNextPage && (
									<>
										<Pagination.Next onClick={() => changePage(meta.page + 1)}>
											Próximo
										</Pagination.Next>
										<Pagination.Last onClick={() => changePage(meta.pageCount)} />
									</>
								)}
							</Pagination>
						</Col>
					)}
				</Row>
			)}
		</div>
	);
};

export default PaginationTable;
