/* eslint-disable global-require */
import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	AxiosError as AError,
} from "axios";
import { Buffer } from "buffer";
import { VividusError } from "../errors";
import { AuthDto } from "../modules/security/auth/dto/AuthDto";

export default abstract class BaseAPI {
	protected api: AxiosInstance;

	private jwt = "";

	protected constructor(readonly name: string, baseURL: string) {
		this.api = axios.create({
			baseURL,
		});

		this.createDebugAxios();
		this.api.interceptors.response.use(
			(response) => response,
			(error) => this.handlerAxiosError(error)
		);
	}

	init(auth: AuthDto): void {
		this.jwt = `Bearer ${auth.vividusJwt}`;
		this.api.defaults.headers.post["Content-Type"] =
			"application/x-www-form-urlencoded";
		this.api.defaults.headers.post.Accept = "application/json";
	}

	clear(): void {
		this.jwt = "";
	}

	protected handlerAxiosErrorImp(error: AError): AError {
		return error;
	}

	private handlerAxiosError(error: any) {
		if (!error.isAxiosError || !error.response) throw error;
		this.handlerAxiosErrorImp(error);
		throw new VividusError(
			error.response.status,
			error.response.data.code,
			error.response.data.message || error.message,
			error.response.data.data
		);
	}

	protected createDebugAxios() {
		if (process.env.NODE_ENV === "production") return;

		const getURL = (config: AxiosRequestConfig) => {
			let { url } = config;
			if (config.baseURL && !require("axios/lib/helpers/isAbsoluteURL")(url)) {
				url = require("axios/lib/helpers/combineURLs")(config.baseURL, url);
			}
			return require("axios/lib/helpers/buildURL")(
				url,
				config.params,
				config.paramsSerializer
			);
		};

		const options = {
			request: (config: AxiosRequestConfig) => {
				const url = getURL(config);
				Object.defineProperty(config, "__AXIOS-DEBUG-LOG_URL__", { value: url });
				console.log(
					`(${config.method?.toUpperCase()}) ${url}`,
					this.normalizeLogRequestData(config.url || "", config.data || "")
				);
			},
			response: (response: AxiosResponse) => {
				const url = (response.config as any)["__AXIOS-DEBUG-LOG_URL__"];
				console.log(
					response.statusText,
					response.status,
					`(${response.config.method?.toUpperCase()})`,
					url,
					this.normalizeLogResponseData(
						response.config.url || "",
						response.data || ""
					)
				);
			},
			error: (error: any) => {
				if (error.config) {
					const url = error.config["__AXIOS-DEBUG-LOG_URL__"];
					console.error(
						error.name,
						error.message,
						`(${error.config.method?.toUpperCase()})`,
						url,
						error.data || ""
					);
				} else {
					console.error(error.name, error.message, error.data || "");
				}
			},
		};

		this.api.interceptors.request.use((config) => {
			options.request(config);
			return config;
		});
		this.api.interceptors.response.use(
			(response) => {
				options.response(response);
				return response;
			},
			(error) => {
				options.error(error);
				throw error;
			}
		);
	}

	protected normalizeLogRequestData(url: string, data: any) {
		return data;
	}

	protected normalizeLogResponseData(url: string, data: any) {
		return data;
	}

	protected getBasicAuth(): string {
		return `Basic ${Buffer.from(
			`${process.env.REACT_APP_BASIC_USER}:${process.env.REACT_APP_BASIC_PASS}`
		).toString("base64")}`;
	}

	protected getJWTAuth(): string {
		return this.jwt;
	}

	public getAPI(): AxiosInstance {
		return this.api;
	}
}
