import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import { maskDate } from "../../../../utils/string";
import BillingLotService from "../../../../modules/finance/billing-lot/BillingLotService";

const service = new BillingLotService();

const NewBillingLotPanel: React.FC = () => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();
	const navigate = useNavigate();

	const [formRangeDate, setFormRangeDate] = useState<[Date | null, Date | null]>(
		[null, null]
	);

	const [loading, setLoading] = useState(false);
	const [blocked, setBlocked] = useState(false);

	const handleCreateBillingLot = async () => {
		if (!hasPermission("adm-create-billing-lot")) {
			showError("Permissão negada.");
			return;
		}
		if (formRangeDate[0] === null || formRangeDate[1] === null) {
			showError("Período não informado");
			return;
		}
		setLoading(true);
		try {
			const { id } = await service.createBillingLot(
				formRangeDate[0],
				formRangeDate[1]
			);
			setLoading(false);
			navigate(`/billing/billing-lot/${id}`);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	useEffect(
		() => setBlocked(!hasPermission("adm-create-billing-lot")),
		[hasPermission]
	);

	return (
		<Container fluid>
			<DashTitle title="Novo Lote de Faturamento" />
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<Row>
								<Col md={6}>
									<Form>
										{/* Filtro por Período */}
										<Form.Label>Período:</Form.Label>
										<DatePicker
											className="form-control"
											selectsRange
											locale="pt-BR"
											onChange={(date) => setFormRangeDate(date)}
											onChangeRaw={(e) => maskDate(e.target.value)}
											startDate={formRangeDate[0]}
											endDate={formRangeDate[1]}
											dateFormat="dd/MM/yyyy"
											isClearable
											clearButtonClassName="clear-form-button"
											clearButtonTitle="Limpar"
											disabled={loading || blocked}
										/>
										{/* Botão de Gerar Lote */}
										<Button
											type="submit"
											className="btn btn-primary"
											onClick={(e) => {
												e.preventDefault();
												handleCreateBillingLot();
											}}
											style={{ marginTop: "20px" }}
											disabled={loading || blocked}
										>
											Gerar
										</Button>
									</Form>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{loading && <LoadingSpinner />}
		</Container>
	);
};

export default NewBillingLotPanel;
