import React from "react";
import { Col, Row } from "react-bootstrap";
import ExportCSVButton, {
	ExportCSVData,
} from "../../../../components/ExportCSVButton";
import ExportPDFButton from "../../../../components/ExportPDFButton";
import GoBackButton from "../../../../components/GoBackButton";

export interface DashTitleProps {
	title: string;
	dataCSV?: ExportCSVData;
	handleExportCSV?: () => boolean;
	handleExportPDF?: () => Promise<void>;
	showExportCSVButton?: boolean;
	showExportPDFButton?: boolean;
	showGoBackButton?: boolean;
}

const DashTitle: React.FC<DashTitleProps> = ({
	title,
	dataCSV,
	handleExportCSV,
	handleExportPDF,
	showExportCSVButton,
	showExportPDFButton,
	showGoBackButton,
	children,
}) => (
	<Row>
		<Col md={12}>
			<div className="page-title-box">
				{showExportCSVButton && (
					<div className="page-title-right">
						<div className="d-flex">
							<ExportCSVButton handleExport={handleExportCSV} data={dataCSV} />
						</div>
					</div>
				)}
				{showExportPDFButton && (
					<div className="page-title-right">
						<div className="d-flex">
							<ExportPDFButton handleExport={handleExportPDF} />
						</div>
					</div>
				)}
				{showGoBackButton && (
					<div className="page-title-right">
						<div className="d-flex">
							<GoBackButton />
						</div>
					</div>
				)}
				{children}
				<h4 className="page-title">{title}</h4>
			</div>
		</Col>
	</Row>
);

DashTitle.defaultProps = {
	showGoBackButton: false,
};

export default DashTitle;
