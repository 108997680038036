import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	FormControl,
	InputGroup,
	Form,
	FormSelect,
	Row,
	Card,
	Col,
	Alert,
} from "react-bootstrap";
import useToast from "../../../../../../hooks/Toast";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { Role } from "../../../../../../modules/security/permission/enum/role.enum";
import {
	isCpfOrCnpj,
	isEmail,
	isPhoneNumber,
	isZipcodeForProvider,
	maskCnpj,
	maskCpf,
	maskPercentage,
	maskPhone,
} from "../../../../../../utils/string";
import { PixKeyType } from "../../../../../../modules/marketplace/provider/enum/pix-key-type.enum";
import ProviderService from "../../../../../../modules/marketplace/provider/ProviderService";
import BasicTable from "../../../../../../components/BasicTable";
import BasicRow from "../../../../../../components/BasicTable/BasicRow";
import BasicColumn from "../../../../../../components/BasicTable/BasicColumn";
import { Provider } from "../../../../../../modules/marketplace/provider/entities/Provider";
import { User } from "../../../../../../modules/security/user/entities/User";

const providerService = new ProviderService();

const percStringToFloat = (str: string) =>
	parseFloat(String(parseFloat(str.replace(",", ".")) * 0.01).substring(0, 6));

interface TabProviderProps {
	user?: User;
	provider?: Provider;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	handleBeforeUpdate: () => Promise<void>;
}

const TabProvider: React.FC<TabProviderProps> = ({
	user,
	loading,
	setLoading,
	provider,
	handleBeforeUpdate,
}) => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [enableSaveProvider, setEnableSaveProvider] = useState(true);

	const [zipcodeFormValue, setZipcodeFormValue] = useState("");

	const [provider_id, setProvider_id] = useState<number>();
	const [provider_name, setProvider_name] = useState("");
	const [provider_address, setProvider_address] = useState("");
	const [provider_email, setProvider_email] = useState("");
	const [provider_active, setProvider_active] = useState(false);
	const [provider_zipcodes, setProvider_zipcodes] = useState<string[]>([]);
	const [provider_socialInstagram, setProvider_socialInstagram] = useState("");
	const [provider_socialWhatsapp, setProvider_socialWhatsapp] = useState("");
	const [provider_socialWebsite, setProvider_socialWebsite] = useState("");
	const [provider_softDescriptor, setProvider_softDescriptor] = useState("");
	const [provider_qrCode, setProvider_qrCode] = useState("");
	const [provider_pixKeyType, setProvider_pixKeyType] = useState(PixKeyType.CPF);
	const [provider_allowsCashPayment, setProvider_allowsCashPayment] =
		useState(false);
	const [provider_allowsAnticipation, setProvider_allowsAnticipation] =
		useState(false);
	const [provider_feeAnticipation, setProvider_feeAnticipation] = useState("");
	const [provider_feeTrading, setProvider_feeTrading] = useState("");
	const [provider_pixKey, setProvider_pixKey] = useState("");
	const [provider_phoneNotification, setProvider_phoneNotification] =
		useState("");

	const removeZipcode = async (zipcodes: string[], zipcode: string) => {
		if (!user?.id) return;
		try {
			setProvider_zipcodes(zipcodes.filter((z) => z !== zipcode));
		} catch (error) {
			showError(error);
		}
	};

	const addZipcode = async (zipcodes: string[], zipcode: string) => {
		if (!user?.id || !isZipcodeForProvider(zipcode) || zipcodes.includes(zipcode))
			return;
		try {
			setProvider_zipcodes([...zipcodes, zipcode]);
			setZipcodeFormValue("");
		} catch (error) {
			showError(error);
		}
	};

	const handleSaveProvider = async () => {
		if (!user?.id) return;
		if (!hasPermission(Role.ADM_UPD_USER)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			const getPixKey = () => {
				switch (provider_pixKeyType) {
					case PixKeyType.CPF:
					case PixKeyType.CNPJ:
						return provider_pixKey.replace(/\D/g, "").trim();
					case PixKeyType.PHONE:
						return provider_pixKey.replace(/\D/g, "")
							? `55${provider_pixKey.replace(/\D/g, "")}`.trim()
							: undefined;
					default:
						return provider_pixKey.trim();
				}
			};
			let id = provider_id;
			if (!id) {
				const providerLocal = await providerService.createProvider({
					name: provider_name,
					user_id: user.id,
				});
				if (!providerLocal?.id) {
					showError("Erro ao salvar parceiro.");
					return;
				}
				id = providerLocal.id;
			}
			await providerService.updateProvider(id, {
				name: provider_name,
				address: provider_address,
				email: provider_email,
				active: provider_active,
				socialInstagram: provider_socialInstagram,
				socialWhatsapp: provider_socialWhatsapp.replace(/\D/g, "")
					? `55${provider_socialWhatsapp.replace(/\D/g, "")}`
					: undefined,
				socialWebsite: provider_socialWebsite,
				softDescriptor: provider_softDescriptor,
				qrCode: provider_qrCode.trim(),
				pixKeyType: provider_pixKeyType,
				allowsCashPayment: provider_allowsCashPayment,
				allowsAnticipation: provider_allowsAnticipation,
				feeAnticipation: percStringToFloat(provider_feeAnticipation),
				feeTrading: percStringToFloat(provider_feeTrading),
				pixKey: getPixKey(),
				phoneNotification: provider_phoneNotification.replace(/\D/g, "")
					? `55${provider_phoneNotification.replace(/\D/g, "")}`
					: undefined,
				zipcodes: provider_zipcodes,
			});
			await handleBeforeUpdate();
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const verifyProvider = useCallback(() => {
		// E-mail
		if (provider_email && !isEmail(provider_email)) return false;

		// Social WhatsApp
		if (provider_socialWhatsapp && !isPhoneNumber(provider_socialWhatsapp))
			return false;

		// Chave Pix
		if (provider_pixKey) {
			switch (provider_pixKeyType) {
				case PixKeyType.CNPJ:
				case PixKeyType.CPF:
					if (!isCpfOrCnpj(provider_pixKey)) return false;
					break;
				case PixKeyType.EMAIL:
					if (!isEmail(provider_pixKey)) return false;
					break;
				case PixKeyType.PHONE:
					if (!isPhoneNumber(provider_pixKey)) return false;
					break;
				default:
					break;
			}
		}

		// WhatsApp para Notificação
		if (provider_phoneNotification && !isPhoneNumber(provider_phoneNotification))
			return false;

		return true;
	}, [
		provider_email,
		provider_phoneNotification,
		provider_pixKey,
		provider_pixKeyType,
		provider_socialWhatsapp,
	]);

	useEffect(() => {
		setProvider_id(provider?.id);
		setProvider_name(provider?.name || "");
		setProvider_address(provider?.address || "");
		setProvider_email(provider?.email || "");
		setProvider_active(provider?.active ?? false);
		setProvider_zipcodes(provider?.zipcodes || []);
		setProvider_socialInstagram(provider?.socialInstagram || "");
		setProvider_socialWhatsapp(maskPhone(provider?.socialWhatsapp || ""));
		setProvider_socialWebsite(provider?.socialWebsite || "");
		setProvider_softDescriptor(provider?.softDescriptor || "");
		setProvider_qrCode(provider?.qrCode || "");
		setProvider_pixKeyType(provider?.pixKeyType || PixKeyType.CPF);
		setProvider_allowsCashPayment(provider?.allowsCashPayment ?? false);
		setProvider_allowsAnticipation(provider?.allowsAnticipation ?? false);
		setProvider_feeAnticipation(
			maskPercentage(
				((provider?.feeAnticipation ?? 0) * 100).toFixed(2).toString()
			)
		);
		setProvider_feeTrading(
			maskPercentage(((provider?.feeTrading ?? 0) * 100).toFixed(2).toString())
		);
		switch (provider?.pixKeyType || PixKeyType.CPF) {
			case PixKeyType.CPF:
				setProvider_pixKey(maskCpf(provider?.pixKey || ""));
				break;
			case PixKeyType.CNPJ:
				setProvider_pixKey(maskCnpj(provider?.pixKey || ""));
				break;
			case PixKeyType.PHONE:
				setProvider_pixKey(maskPhone(provider?.pixKey || ""));
				break;
			default:
				setProvider_pixKey(provider?.pixKey || "");
				break;
		}
		setProvider_phoneNotification(maskPhone(provider?.phoneNotification || ""));
	}, [provider]);

	/**
	 * Verifica se os dados do parceiro são válidos e habilita o botão de salvar.
	 */
	useEffect(() => {
		setEnableSaveProvider(verifyProvider());
	}, [verifyProvider]);

	return (
		<Row>
			<Col md={12}>
				<Card>
					<Card.Body>
						{user?.roles &&
							provider?.id &&
							!user.roles.some((r) =>
								r.permissions.some((p) => p.code === Role.IS_PROVIDER)
							) && (
								<Alert variant="warning">
									Parceiro sem o perfil de acesso definido.
								</Alert>
							)}
						<InputGroup className="mb-3">
							{" "}
							<InputGroup.Text>Nome</InputGroup.Text>
							<FormControl
								value={provider_name}
								onChange={(e) => setProvider_name(e.target.value)}
								placeholder="Será exibido para os assinantes"
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Endereço</InputGroup.Text>
							<FormControl
								value={provider_address}
								onChange={(e) => setProvider_address(e.target.value)}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>E-mail</InputGroup.Text>
							<FormControl
								value={provider_email}
								onChange={(e) => setProvider_email(e.target.value)}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form>
								<Form.Check
									type="switch"
									label="Ativo"
									checked={provider_active}
									onChange={(e) => setProvider_active(e.target.checked)}
									disabled={loading}
								/>
							</Form>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>CEPs de atuação</InputGroup.Text>
							<FormControl
								value={zipcodeFormValue}
								onChange={(e) => setZipcodeFormValue(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										addZipcode(provider_zipcodes, zipcodeFormValue);
									}
								}}
								disabled={loading}
							/>
						</InputGroup>
						<Row>
							<Col md={12}>
								<Card>
									<Card.Body>
										<BasicTable headers={["CEP", "Remover"]}>
											{provider_zipcodes.map((zipcode) => (
												<BasicRow key={`provider-zipcode-${zipcode}`}>
													<BasicColumn>{zipcode}</BasicColumn>
													<BasicColumn>
														<div className="form-check d-flex align-items-center justify-content-center">
															<span
																className="action-icon"
																onClick={() => removeZipcode(provider_zipcodes, zipcode)}
																role="button"
																tabIndex={0}
																hidden={loading}
															>
																<i title="Cancelar" className="mdi mdi-cancel" />
															</span>
														</div>
													</BasicColumn>
												</BasicRow>
											))}
										</BasicTable>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<InputGroup className="mb-3">
							<InputGroup.Text>Social Instagram</InputGroup.Text>
							<FormControl
								value={provider_socialInstagram}
								onChange={(e) => setProvider_socialInstagram(e.target.value)}
								placeholder="Ex: vividussaude"
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Social WhatsApp</InputGroup.Text>
							<FormControl
								value={provider_socialWhatsapp}
								onChange={(e) => setProvider_socialWhatsapp(maskPhone(e.target.value))}
								placeholder="Ex: (55) 12345-6789"
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Social Site</InputGroup.Text>
							<FormControl
								value={provider_socialWebsite}
								onChange={(e) => setProvider_socialWebsite(e.target.value)}
								placeholder="Ex: www.vividus.com.br"
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Soft Descriptor</InputGroup.Text>
							<FormControl
								value={provider_softDescriptor}
								onChange={(e) => setProvider_softDescriptor(e.target.value)}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>QRCode</InputGroup.Text>
							<FormControl
								value={provider_qrCode}
								onChange={(e) => setProvider_qrCode(e.target.value.trim())}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form>
								<Form.Check
									type="switch"
									label="Permite receber pagamentos em dinheiro"
									checked={provider_allowsCashPayment}
									onChange={(e) => setProvider_allowsCashPayment(e.target.checked)}
									disabled={loading}
								/>
								<Form.Check
									type="switch"
									label="Habilitar taxa de antecipação"
									checked={provider_allowsAnticipation}
									onChange={(e) => setProvider_allowsAnticipation(e.target.checked)}
									disabled={loading}
								/>
							</Form>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Taxa de antecipação (%)</InputGroup.Text>
							<FormControl
								value={provider_feeAnticipation}
								onChange={(e) =>
									setProvider_feeAnticipation(maskPercentage(e.target.value))
								}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Taxa de comercialização (%)</InputGroup.Text>
							<FormControl
								value={provider_feeTrading}
								onChange={(e) => setProvider_feeTrading(maskPercentage(e.target.value))}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Tipo de chave PIX</InputGroup.Text>
							<FormSelect
								value={provider_pixKeyType}
								onChange={(e) => {
									setProvider_pixKeyType(e.target.value as PixKeyType);
									setProvider_pixKey("");
								}}
								disabled={loading}
							>
								<option value={PixKeyType.CPF}>CPF</option>
								<option value={PixKeyType.CNPJ}>CNPJ</option>
								<option value={PixKeyType.PHONE}>Telefone</option>
								<option value={PixKeyType.EMAIL}>E-mail</option>
								<option value={PixKeyType.RANDOM}>Chave aleatória</option>
							</FormSelect>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Número chave PIX</InputGroup.Text>
							<FormControl
								value={provider_pixKey}
								onChange={(e) => {
									switch (provider_pixKeyType) {
										case PixKeyType.CPF:
											setProvider_pixKey(maskCpf(e.target.value));
											break;
										case PixKeyType.CNPJ:
											setProvider_pixKey(maskCnpj(e.target.value));
											break;
										case PixKeyType.PHONE:
											setProvider_pixKey(maskPhone(e.target.value));
											break;
										case PixKeyType.EMAIL:
											setProvider_pixKey(e.target.value);
											break;
										default:
											setProvider_pixKey(e.target.value);
											break;
									}
								}}
								disabled={loading}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text>Nº do WhatsApp para notificação</InputGroup.Text>
							<FormControl
								value={provider_phoneNotification}
								onChange={(e) =>
									setProvider_phoneNotification(maskPhone(e.target.value || ""))
								}
								placeholder="Ex: (55) 12345-6789"
								disabled={loading}
							/>
						</InputGroup>
						<Button
							variant="danger"
							onClick={() => handleSaveProvider()}
							disabled={!enableSaveProvider || loading}
						>
							Salvar
						</Button>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default TabProvider;
