import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner: React.FC = () => (
	<div className="d-flex justify-content-center">
		<Spinner
			className="m-2"
			animation="border"
			role="status"
			style={{ color: "#e21251" }}
		>
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</div>
);

export default LoadingSpinner;
