import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Container,
	Form,
	InputGroup,
	Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import VividusCardPage from "../../components/VividusCardPage";
import { useAuth } from "../../contexts/AuthContext";
import { UserNotFoundError } from "../../errors";
import useToast from "../../hooks/Toast";
import { isEmail } from "../../utils/string";
import FooterBar from "../DashboardPage/components/FooterBar/FooterBar";

const invalidBorder = "1px solid #f44336";
const validBorder = "1px solid #4caf50";

const LoginPage: React.FC = () => {
	const { currentUser, login, loaded } = useAuth();
	const { showError } = useToast();
	const navigate = useNavigate();

	const [emailBorder, setEmailBorder] = useState("1px solid #dee2e6");
	const [passwordBorder, setPasswordBorder] = useState("1px solid #dee2e6");

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [isRememberMe, setIsRememberMe] = useState(true);
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChangeEmail = (value: string) => {
		if (!value || !isEmail(value)) {
			setEmailBorder(invalidBorder);
		} else {
			setEmailBorder(validBorder);
		}
		setEmail(value);
	};

	const handleChangePassword = (value: string) => {
		if (!value) {
			setPasswordBorder(invalidBorder);
		} else {
			setPasswordBorder(validBorder);
		}
		setPassword(value);
	};

	const signIn = async () => {
		if (!isEmail(email)) {
			showError("E-mail inválido.");
			return;
		}
		if (!password) {
			showError("Senha não informada.");
			setPasswordBorder(invalidBorder);
			return;
		}
		setLoading(true);
		try {
			if (!(await login(email, password, isRememberMe))) {
				showError("Usuário não encontrado.");
				setLoading(false);
			}
		} catch (error: any) {
			setLoading(false);
			if (error instanceof UserNotFoundError) {
				switch (error.nextAction) {
					case "registerAuth":
					case "block":
					case "registerFirestore":
					default:
						showError("Usuário não encontrado.");
						break;
				}
				return;
			}
			if (error.code === "auth/user-not-found") {
				showError("Usuário não encontrado.");
				return;
			}
			if (["auth/invalid-email", "auth/wrong-password"].includes(error.code)) {
				showError("E-mail ou senha inválidos.");
				return;
			}
			showError(error);
		}
	};

	useEffect(() => {
		if (currentUser) {
			const url = localStorage.getItem("original-path");
			if (typeof url !== "undefined" && url !== null) {
				localStorage.removeItem("original-path");
				navigate(url);
			} else {
				navigate("/");
			}
		}
	}, [currentUser, navigate]);

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "#D3D0DC" }}>
			{loading || !loaded ? (
				<Container
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: "100vh" }}
				>
					<Spinner animation="border" role="status" style={{ color: "#e21251" }}>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Container>
			) : (
				<VividusCardPage>
					<Card.Body className="p-4">
						<Form
							onSubmit={(event) => {
								event.preventDefault();
								signIn();
							}}
						>
							<div className="text-center w-75 m-auto mb-3">
								<h4 className="text-dark-50 text-center mt-0 fw-bold">Entrar</h4>
							</div>

							<InputGroup className="mb-3">
								<InputGroup.Text>E-mail</InputGroup.Text>
								<Form.Control
									type="email"
									required
									placeholder="Informe seu email"
									autoComplete="on"
									value={email}
									onChange={(e) => handleChangeEmail(e.target.value)}
									style={{
										border: emailBorder,
									}}
								/>
							</InputGroup>

							<Form.Label
								htmlFor="pass"
								onClick={() => navigate("/reset-password")}
								className="text-muted float-end"
								style={{ cursor: "pointer" }}
								role="button"
								tabIndex={-1}
							>
								<small>Esqueceu sua senha?</small>
							</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text>Senha</InputGroup.Text>
								<Form.Control
									id="pass"
									type={isShowPassword ? "text" : "password"}
									required
									placeholder="Digite sua senha"
									value={password}
									onChange={(e) => handleChangePassword(e.target.value)}
									style={{
										border: passwordBorder,
									}}
								/>
								<InputGroup.Text
									className={
										isShowPassword ? "input-group-text show-password" : "input-group-text"
									}
									data-password="false"
									onClick={() => setIsShowPassword(!isShowPassword)}
									role="button"
									tabIndex={0}
								>
									<span className="password-eye" />
								</InputGroup.Text>
							</InputGroup>

							<Form.Group className="mb-3">
								<Form.Check
									label="Manter conectado"
									onChange={(e) => setIsRememberMe(Boolean(e.target.value))}
									defaultChecked={isRememberMe}
								/>
							</Form.Group>

							<div className="mb-3 mb-0 text-center">
								<Button type="submit" variant="primary">
									Entrar
								</Button>
							</div>
						</Form>
					</Card.Body>
				</VividusCardPage>
			)}
			<FooterBar type="summary" />
		</div>
	);
};

export default LoginPage;
