export enum Status {
	OPEN = "O", // Aberto
	CLOSED = "C", // Fechado
	WRITE_OFF = "W", // Baixa
}

export const getBillingLotStatusName = (status: Status) => {
	switch (status) {
		case Status.OPEN:
			return "Aberto";
		case Status.CLOSED:
			return "Fechado";
		case Status.WRITE_OFF:
			return "Faturado";
		default:
			return "Desconhecido";
	}
};
