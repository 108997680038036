import React from "react";
import { Container } from "react-bootstrap";
import DashTitle from "../components/DashTitle";

const HomePanel: React.FC = () => (
	<Container fluid>
		<DashTitle title="Início" />
	</Container>
);

export default HomePanel;
