import React, { useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import Dinero from "dinero.js";
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from "react-bootstrap";
import { Data } from "react-csv/components/CommonPropTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import BasicTable from "../../../../components/BasicTable";
import BasicRow from "../../../../components/BasicTable/BasicRow";
import BasicColumn from "../../../../components/BasicTable/BasicColumn";
import { Role } from "../../../../modules/security/permission/enum/role.enum";
import BillingLotService from "../../../../modules/finance/billing-lot/BillingLotService";
import { BillingLot } from "../../../../modules/finance/billing-lot/entities/BillingLot";
import { BillingLotItem } from "../../../../modules/finance/billing-lot-item/entities/BillingLotItem";
import {
	getBillingLotStatusName,
	Status,
} from "../../../../modules/finance/billing-lot/enum/status.enum";
import { removeDuplicates } from "../../../../utils/array";
import { maskDate } from "../../../../utils/string";
import {
	formatToLocaleCurrency,
	parseDineroToUnitString,
} from "../../../../utils/currency";
import { Transaction } from "../../../../modules/finance/transaction/entities/Transaction";
import { Provider } from "../../../../modules/marketplace/provider/entities/Provider";

const service = new BillingLotService();

interface BillingLotItemRow {
	checked: boolean;
	transaction: Transaction;
	item?: BillingLotItem;
}

const BillingLotModalPanel: React.FC = () => {
	const navigate = useNavigate();

	const match = useMatch("/billing/billing-lot/:id");
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [loading, setLoading] = useState(true);

	const [formProvidersList, setFormProvidersList] = useState<Provider[]>([]);
	const [formProvider, setFormProvider] = useState("");

	const [showCloseModal, setShowCloseModal] = useState(false);
	const [showWriteOffModal, setShowWriteOffModal] = useState(false);

	const [isDraft, setIsDraft] = useState(false);
	const [notFinded, setNotFinded] = useState(false);

	const [billingLotCsv, setBillingLotCsv] = useState<Data>();
	const [billingLot_lotNumber, setBillingLot_lotNumber] = useState(0);
	const [billingLot_startEndDate, setBillingLot_startEndDate] = useState<
		[Date | null, Date | null]
	>([null, null]);
	const [billingLot_closeDate, setBillingLot_closeDate] = useState<Date>();
	const [billingLot_writeOffDate, setBillingLot_writeOffDate] = useState<Date>();
	const [billingLot_status, setBillingLot_status] = useState<Status>(
		Status.OPEN
	);
	const [billingLot_observation, setBillingLot_observation] = useState("");
	const [itemsOrTransactions, setItemsOrTransactions] = useState<
		BillingLotItemRow[]
	>([]);
	const [billingLot_items, setBillingLot_Items] = useState<BillingLotItem[]>([]);

	const onFormProviderChange = (value: string) => setFormProvider(value);

	const getId = useCallback(() => match?.params.id || "", [match?.params.id]);

	const composeBillingLotCsv = (billingLot: BillingLot) => {
		if (
			!billingLot ||
			![Status.WRITE_OFF, Status.CLOSED].includes(billingLot.status)
		)
			return;
		const billingLotItems = [
			[
				"numero",
				"data",
				"cashback",
				"taxa_pagamento",
				"valor_pagamento",
				"taxa_comercializacao",
				"valor_comercializacao",
				"taxa_antecipacao",
				"valor_antecipacao",
				"valor_pagamento",
			],
			...billingLot.items.map((item) => [
				item.transaction?.orderNumber || "",
				item.transaction
					? DateTime.fromISO(item.transaction.purchaseDate!).toFormat(
							"dd/MM/yyyy HH:mm"
					  )
					: null,
				parseDineroToUnitString(item.valueCashback),
				String(item.feePaymentMethod || 0).replace(".", ","),
				parseDineroToUnitString(item.valuePaymentMethod),
				String(item.feeTrading || 0).replace(".", ","),
				parseDineroToUnitString(item.valueTrading),
				String(item.feeAnticipation || 0).replace(".", ","),
				parseDineroToUnitString(item.valueAnticipation),
				parseDineroToUnitString(item.valuePayment),
			]),
		];
		setBillingLotCsv([
			["codigo", billingLot.id],
			["lote", billingLot.lotNumber],
			["status", getBillingLotStatusName(billingLot.status)],
			[
				"fechamento",
				billingLot.closeDate
					? DateTime.fromISO(billingLot.closeDate).toFormat("dd/MM/yyyy HH:mm")
					: "",
			],
			[
				"faturamento",
				billingLot.writeOffDate
					? DateTime.fromISO(billingLot.writeOffDate).toFormat("dd/MM/yyyy HH:mm")
					: "",
			],
			["observacao", billingLot.observation],
			[],
			...billingLotItems,
		]);
	};

	const setBillingLot = useCallback(
		(billingLot: BillingLot, transactions: Transaction[] = []) => {
			composeBillingLotCsv(billingLot);
			setBillingLot_lotNumber(billingLot.lotNumber);
			setBillingLot_startEndDate([
				DateTime.fromISO(billingLot.startDate).toJSDate(),
				DateTime.fromISO(billingLot.endDate).toJSDate(),
			]);
			setBillingLot_closeDate(
				billingLot.closeDate
					? DateTime.fromISO(billingLot.closeDate).toJSDate()
					: undefined
			);
			setBillingLot_writeOffDate(
				billingLot.writeOffDate
					? DateTime.fromISO(billingLot.writeOffDate).toJSDate()
					: undefined
			);
			setBillingLot_status(billingLot.status);
			setBillingLot_observation(billingLot.observation || "");
			setBillingLot_Items(billingLot.items);
			setItemsOrTransactions(
				(transactions || [])
					.map((t) => ({
						checked: !!billingLot.items.find((i) => i.transaction?.id === t.id),
						transaction: t,
						item: billingLot.items.find((i) => i.transaction?.id === t.id),
					}))
					.sort((a, b) => a.transaction.id - b.transaction.id)
			);
		},
		[]
	);

	const fetchData = useCallback(async () => {
		const id = getId();
		if (!id) {
			showError("Registro não informado.");
			return;
		}

		setLoading(true);
		await service
			.fetchById(+id, { loadItens: true, loadTransactions: true })
			.then(async (lot) => {
				try {
					if (!lot) {
						setNotFinded(true);
						return;
					}
					if (lot.status === Status.OPEN) {
						const { billingLot: billingLotLocal, transactions } =
							await service.getDraftBillingLot(+id);
						setIsDraft(true);
						setBillingLot(billingLotLocal, transactions);
					} else {
						setBillingLot(lot);
					}
				} catch (error) {
					showError(error);
				} finally {
					setLoading(false);
				}
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [getId, setBillingLot, showError]);

	const handleCloseBillingLot = async () => {
		if (!hasPermission(Role.ADM_UPD_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		if (!itemsOrTransactions.some((i) => i.checked)) {
			showError("Lote sem itens definidos.");
			return;
		}
		setLoading(true);
		try {
			await service.closeBillingLot(+getId());
			const lot = await service.fetchById(+getId(), { loadItens: true });
			setIsDraft(false);
			setBillingLot(lot);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const handleWriteOffBillingLot = async () => {
		if (!hasPermission(Role.ADM_UPD_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await service.writeOffBillingLot(+getId());
			const lot = await service.fetchById(+getId(), { loadItens: true });
			setIsDraft(false);
			setBillingLot(lot);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const handleSaveBillingLot = async () => {
		if (!hasPermission(Role.ADM_UPD_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await service.updateBillingLot(+getId(), {
				observation: billingLot_observation,
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const handleCancelBillingLot = async () => {
		if (!hasPermission(Role.ADM_UPD_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		setLoading(true);
		try {
			await service.deleteBillingLot(+getId());
			navigate("/billing/billing-lot");
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	const updateItem = async (
		transactionOrItem_id: number,
		value: boolean,
		itens: BillingLotItemRow[]
	) => {
		if (!getId()) return;
		if (!hasPermission(Role.ADM_UPD_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		try {
			setLoading(true);
			if (value) {
				const item = await service.addItemBillingLot(
					+getId(),
					transactionOrItem_id
				);

				const preList = [
					...itens,
					{
						checked: true,
						transaction: itens.find((i) => i.transaction.id === transactionOrItem_id)!
							.transaction,
						item,
					},
				];
				setItemsOrTransactions(
					[
						...new Map(preList.map((obj) => [obj.transaction.id, obj])).values(),
					].sort((a, b) => a.transaction.id - b.transaction.id)
				);
			} else {
				await service.removeItemBillingLot(+getId(), transactionOrItem_id);
				const preList = [
					...itens,
					{
						checked: false,
						transaction: itens.find((i) => i.item?.id === transactionOrItem_id)!
							.transaction,
						item: undefined,
					},
				];
				setItemsOrTransactions(
					[
						...new Map(preList.map((obj) => [obj.transaction.id, obj])).values(),
					].sort((a, b) => a.transaction.id - b.transaction.id)
				);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showError(error);
		}
	};

	useEffect(() => {
		setFormProvidersList(
			removeDuplicates<Provider>(
				itemsOrTransactions.map((i) => i.transaction.provider!)
			)
		);
	}, [itemsOrTransactions]);

	useEffect(() => {
		if (!hasPermission(Role.ADM_READ_BILLING_LOT)) {
			showError("Permissão negada.");
			return;
		}
		fetchData();
	}, [fetchData, hasPermission, showError]);

	const composeItens = () => (
		<BasicTable
			headers={[
				"Nr.",
				"Data",
				"Cashback",
				"Taxa Pagamento",
				"Valor Pagamento",
				"Taxa Comercialização",
				"Valor Comercialização",
				"Taxa Antecipação",
				"Valor Antecipação",
				"Valor para Pagamento",
			]}
		>
			{billingLot_items.map((item) => (
				<BasicRow key={`item-${item.id!}`}>
					<BasicColumn>{item.transaction?.orderNumber}</BasicColumn>
					<BasicColumn>
						{item.transaction
							? DateTime.fromISO(item.transaction.purchaseDate!).toFormat(
									"dd/MM/yyyy HH:mm"
							  )
							: null}
					</BasicColumn>
					<BasicColumn>{formatToLocaleCurrency(item.valueCashback)}</BasicColumn>
					<BasicColumn>{(item.feePaymentMethod * 100).toFixed(2)}%</BasicColumn>
					<BasicColumn>
						{formatToLocaleCurrency(item.valuePaymentMethod)}
					</BasicColumn>
					<BasicColumn>{(item.feeTrading * 100).toFixed(2)}%</BasicColumn>
					<BasicColumn>{formatToLocaleCurrency(item.valueTrading)}</BasicColumn>
					<BasicColumn>{(item.feeAnticipation * 100).toFixed(2)}%</BasicColumn>
					<BasicColumn>{formatToLocaleCurrency(item.valueAnticipation)}</BasicColumn>
					<BasicColumn>{formatToLocaleCurrency(item.valuePayment)}</BasicColumn>
				</BasicRow>
			))}
		</BasicTable>
	);

	const composeDraftItens = () => (
		<BasicTable
			headers={[
				"",
				"Nr.",
				"Data",
				"Cashback",
				"Total",
				"Taxa da Forma de Pagamento",
				"Valor da Taxa da Forma de Pagamento",
				"Taxa Comercialização",
				"Valor Comercialização",
				"Taxa Antecipação",
				"Valor Antecipação",
				"Valor para Pagamento",
			]}
		>
			{itemsOrTransactions
				.filter(({ transaction }) => {
					if (
						formProvider.length > 0 &&
						transaction.provider?.id.toString() !== formProvider
					)
						return false;
					return true;
				})
				.map(({ checked, item, transaction }) => (
					<BasicRow key={`item-${transaction.id!}`}>
						<BasicColumn>
							<Form.Check
								id={`check-${transaction.id}`}
								checked={checked}
								disabled={loading}
								onChange={(e) =>
									updateItem(
										item?.id || transaction.id,
										e.target.checked,
										itemsOrTransactions
									)
								}
							/>
						</BasicColumn>
						<BasicColumn>{transaction.orderNumber}</BasicColumn>
						<BasicColumn>
							{DateTime.fromISO(transaction.purchaseDate!).toFormat(
								"dd/MM/yyyy HH:mm"
							)}
						</BasicColumn>
						<BasicColumn>
							{formatToLocaleCurrency(transaction.totalCashback)}
						</BasicColumn>
						<BasicColumn>{formatToLocaleCurrency(transaction.total)}</BasicColumn>
						<BasicColumn>
							{((transaction.feePaymentMethod || 0) * 100).toFixed(2)}%
						</BasicColumn>
						<BasicColumn>
							{formatToLocaleCurrency(
								Math.round(transaction.total * transaction.feePaymentMethod)
							)}
						</BasicColumn>
						<BasicColumn>
							{((transaction.feeTrading || 0) * 100).toFixed(2)}%
						</BasicColumn>
						<BasicColumn>
							{formatToLocaleCurrency(
								Math.round(transaction.total * transaction.feeTrading)
							)}
						</BasicColumn>
						<BasicColumn>
							{(transaction.provider?.allowsAnticipation
								? (transaction.provider!.feeAnticipation || 0) * 100
								: 0
							).toFixed(2)}
							%
						</BasicColumn>
						<BasicColumn>
							{formatToLocaleCurrency(
								transaction.provider?.allowsAnticipation
									? Math.round(transaction.total * transaction.provider!.feeAnticipation)
									: 0
							)}
						</BasicColumn>
						<BasicColumn>
							{formatToLocaleCurrency(
								Dinero({ amount: transaction.total })
									.subtract(Dinero({ amount: transaction.totalCashback }))
									.subtract(
										Dinero({
											amount: Math.round(transaction.total * transaction.feePaymentMethod),
										})
									)
									.subtract(
										Dinero({
											amount: Math.round(transaction.total * transaction.feeTrading),
										})
									)
									.subtract(
										Dinero({
											amount: transaction.provider?.allowsAnticipation
												? Math.round(
														transaction.total * transaction.provider!.feeAnticipation
												  )
												: 0,
										})
									)
									.getAmount()
							)}
						</BasicColumn>
					</BasicRow>
				))}
		</BasicTable>
	);

	return (
		<Container fluid>
			<Modal show={showCloseModal} onHide={() => setShowCloseModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Deseja fechar o lote de faturamento?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Após o fechamento não será possível fazer modificações.
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowCloseModal(false)}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setShowCloseModal(false);
							handleCloseBillingLot();
						}}
					>
						Fechar lote
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showWriteOffModal} onHide={() => setShowWriteOffModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Deseja realizar o faturamento?</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowWriteOffModal(false)}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setShowWriteOffModal(false);
							handleWriteOffBillingLot();
						}}
					>
						Faturar
					</Button>
				</Modal.Footer>
			</Modal>
			<DashTitle
				title="Lote de Faturamento"
				showGoBackButton
				showExportCSVButton={billingLot_status && billingLot_status !== Status.OPEN}
				dataCSV={billingLotCsv}
				handleExportCSV={() => {
					if (!billingLotCsv) return false;
					return true;
				}}
			/>
			{loading && <LoadingSpinner />}
			{notFinded && <Alert variant="info">Registro não encontrado.</Alert>}
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<Form>
								<Form.Group className="mb-3">
									<Form.Label>Lote</Form.Label>
									<Form.Control
										type="text"
										required
										autoComplete="on"
										value={billingLot_lotNumber.toString() || ""}
										disabled
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Período</Form.Label>
									<DatePicker
										className="form-control"
										selectsRange
										locale="pt-BR"
										onChange={(date) => setBillingLot_startEndDate(date)}
										onChangeRaw={(e) => maskDate(e.target.value)}
										startDate={billingLot_startEndDate[0]}
										endDate={billingLot_startEndDate[1]}
										dateFormat="dd/MM/yyyy"
										clearButtonClassName="clear-form-Button"
										clearButtonTitle="Limpar"
										disabled
										readOnly
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Status</Form.Label>
									<Form.Control
										type="text"
										required
										autoComplete="on"
										value={getBillingLotStatusName(billingLot_status)}
										disabled
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Data de Fechamento</Form.Label>
									<Form.Control
										type="text"
										required
										autoComplete="on"
										value={
											billingLot_closeDate
												? DateTime.fromJSDate(billingLot_closeDate).toFormat("dd/MM/yyyy")
												: ""
										}
										disabled
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Data de Faturamento</Form.Label>
									<Form.Control
										type="text"
										required
										autoComplete="on"
										value={
											billingLot_writeOffDate
												? DateTime.fromJSDate(billingLot_writeOffDate).toFormat(
														"dd/MM/yyyy"
												  )
												: ""
										}
										disabled
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Observação</Form.Label>
									<Form.Control
										type="text"
										required
										autoComplete="on"
										onChange={(e) => {
											e.preventDefault();
											setBillingLot_observation(e.target.value);
										}}
										value={billingLot_observation}
										disabled={loading || !isDraft}
									/>
								</Form.Group>
								{isDraft && (
									<>
										<Button
											type="submit"
											variant="primary"
											onClick={(e) => {
												e.preventDefault();
												handleCancelBillingLot();
											}}
											disabled={loading}
										>
											Cancelar
										</Button>{" "}
									</>
								)}
								{isDraft && (
									<>
										<Button
											type="submit"
											variant="primary"
											onClick={(e) => {
												e.preventDefault();
												handleSaveBillingLot();
											}}
											disabled={loading}
										>
											Salvar
										</Button>{" "}
									</>
								)}
								{isDraft && (
									<>
										<Button
											type="submit"
											variant="primary"
											onClick={(e) => {
												e.preventDefault();
												setShowCloseModal(true);
											}}
											disabled={loading}
										>
											Fechar Lote
										</Button>{" "}
									</>
								)}
								{billingLot_status === Status.CLOSED && (
									<>
										<Button
											type="submit"
											variant="primary"
											onClick={(e) => {
												e.preventDefault();
												setShowWriteOffModal(true);
											}}
											disabled={loading}
										>
											Faturar
										</Button>{" "}
									</>
								)}
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							{/* <Row> */}
							<Col md={3}>
								<Form>
									<Form.Group className="mb-3">
										<Form.Label>Parceiro:</Form.Label>
										<Form.Select
											onChange={(e) => onFormProviderChange(e.target.value)}
											disabled={loading || formProvidersList.length === 0}
										>
											<option value=""> </option>
											{formProvidersList.map((provider) => (
												<option key={provider.id} value={provider.id}>
													{provider.name}
												</option>
											))}
										</Form.Select>
									</Form.Group>
									<Button
										type="submit"
										variant="primary"
										onClick={(e) => {
											e.preventDefault();
											fetchData();
										}}
										disabled={loading}
									>
										Atualizar
									</Button>
								</Form>
							</Col>
							{/* </Row> */}
							{isDraft ? composeDraftItens() : composeItens()}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default BillingLotModalPanel;
