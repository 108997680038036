import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import BasicColumn from "../../../../../components/BasicTable/BasicColumn";
import BasicRow from "../../../../../components/BasicTable/BasicRow";
import useToast from "../../../../../hooks/Toast";
import { Parameter } from "../../../../../modules/system/parameter/entities/Parameter";
import ParameterService from "../../../../../modules/system/parameter/ParameterService";

const service = new ParameterService();

interface ParameterRowProps {
	parameter: Parameter;
	onWillSave?: () => void;
	onDidSave?: () => void;
}

const ParameterRow: React.FC<ParameterRowProps> = ({
	parameter,
	onWillSave,
	onDidSave,
}) => {
	const { showError } = useToast();

	const [parameter_value, setParameter_value] = useState(parameter.value);

	const handleSaveParameter = async () => {
		try {
			if (onWillSave) onWillSave();
			await service.updateParameter(parameter.id, {
				value: parameter_value,
			});
		} catch (error) {
			showError(error);
		} finally {
			if (onDidSave) onDidSave();
		}
	};

	return (
		<BasicRow key={`profile-${parameter.id!}`}>
			<BasicColumn>{parameter.code}</BasicColumn>
			<BasicColumn>{parameter.description}</BasicColumn>
			<BasicColumn>
				<FormControl
					value={parameter_value}
					onChange={(e) => setParameter_value(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							handleSaveParameter();
						}
					}}
				/>
			</BasicColumn>
		</BasicRow>
	);
};

export default ParameterRow;
