import React, { useEffect, useCallback, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import ResponsePaginationDto from "../../../../apis/VividusAPI/dto/ResponsePaginationDto";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import PaginationTable from "../../../../components/PaginationTable";
import PaginationColumn from "../../../../components/PaginationTable/PaginationColumn";
import PaginationRow from "../../../../components/PaginationTable/PaginationRow";
import { useAuth } from "../../../../contexts/AuthContext";
import useToast from "../../../../hooks/Toast";
import ConfigurationService from "../../../../modules/system/configuration/ConfigurationService";
import { Configuration } from "../../../../modules/system/configuration/entities/Configuration";
import DashTitle from "../../components/DashTitle";

const service = new ConfigurationService();

const ConfigurationPanel: React.FC = () => {
	const { hasPermission } = useAuth();
	const { showError } = useToast();

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] =
		useState<ResponsePaginationDto<Configuration>>();

	const fetchData = useCallback(() => {
		setLoading(true);
		service
			.list({ take: limit, page, search })
			.then((documents) => {
				setPagination(documents);
				setLoading(false);
			})
			.catch((error) => {
				showError(error);
				setLoading(false);
			});
	}, [page, limit, showError, search]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {}, [limit, showError]);

	return (
		<Container fluid>
			<DashTitle title="Configurações do Sistema" />
			{loading && <LoadingSpinner />}
			{pagination && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<Row className="mb-2">
									<PaginationTable
										idTable="system-configuration-table"
										headers={["Código", "Descrição"]}
										onLimitChange={setLimit}
										onSearchChange={setSearch}
										onPageChange={(p) => setPage(p)}
										meta={pagination?.meta}
										limit={limit}
									>
										{pagination.data.map((item) => (
											<PaginationRow
												key={`${item.id!}`}
												link={`/system/configuration/${item.id}`}
											>
												<PaginationColumn>{item.code}</PaginationColumn>
												<PaginationColumn>{item.description || ""}</PaginationColumn>
											</PaginationRow>
										))}
									</PaginationTable>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default ConfigurationPanel;
