import React from "react";
import { logEvent } from "firebase/analytics";
import logo from "../../assets/images/badge-google-play.svg";
import { getAnalytics } from "../../utils/firebase";
import { composePlayStoreUrl } from "../../utils/mobile-store-util";

interface GooglePlayBadgeProps {
	referralCode?: string;
}

const GooglePlayBadge: React.FC<GooglePlayBadgeProps> = ({ referralCode }) => (
	<a
		href={composePlayStoreUrl()}
		target="_blank"
		rel="noreferrer noopener"
		onClick={() => {
			logEvent(getAnalytics(), "badge_click", {
				store: "play-store",
				referralCode: referralCode || "SITE",
			});
		}}
	>
		<img src={logo} alt="Google Play Badge" />
	</a>
);

GooglePlayBadge.defaultProps = {
	referralCode: undefined,
};

export default GooglePlayBadge;
