export class AxiosError extends Error {
	constructor(readonly status: number, message: string, readonly data: any) {
		super(message);
	}
}

export class VividusError extends AxiosError {
	constructor(
		status: number,
		readonly code: string,
		message: string,
		data: any
	) {
		super(status, message, data);
	}
}

export class UnauthorizedError extends Error {
	constructor() {
		super("Não autorizado.");
	}
}

export class UserNotFoundError extends Error {
	constructor(
		readonly nextAction: "block" | "registerFirestore" | "registerAuth"
	) {
		super("Usuário não cadastrado.");
	}
}
