import React from "react";
import { Link } from "react-router-dom";
import { useAuth, Permission } from "../../../../contexts/AuthContext";

const SideBarItem: React.FC<{
	title: string;
	url: string;
	/**
	 * Indica que não está dentro de um elemento SideBarMenu
	 */
	root?: boolean;
	icon?: string;
	permissions?: Permission;
}> = ({ title, url, icon, root, permissions }) => {
	const { hasPermission } = useAuth();

	const hasLocalPermission = () => !permissions || hasPermission(permissions);

	return hasLocalPermission() ? (
		<li className="side-nav-item">
			<Link to={url} className={`${root ? "side-nav-link" : ""}`}>
				{icon && <i className={icon} />}
				<span> {title} </span>
			</Link>
		</li>
	) : null;
};

SideBarItem.defaultProps = {
	icon: undefined,
	root: false,
	permissions: [],
};

export default SideBarItem;
