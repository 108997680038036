import VividusAPI from "../../../apis/VividusAPI";
import QueryGetAccessProfileDto from "../../../apis/VividusAPI/dto/QueryGetAccessProfileDto";
import QueryGetAccessProfilePaginatedDto from "../../../apis/VividusAPI/dto/QueryGetAccessProfilePaginatedDto";
import { Role } from "../permission/enum/role.enum";

export default class AccessProfileService {
	addPermission(id: number, role: Role) {
		return VividusAPI.postAccessProfilePermission(id, role);
	}

	removePermission(id: number, role: Role) {
		return VividusAPI.deleteAccessProfilePermission(id, role);
	}

	addRole(id: number, user_id: number) {
		return VividusAPI.postAccessProfileUser(id, user_id);
	}

	deleteRole(id: number, user_id: number) {
		return VividusAPI.deleteAccessProfileUser(id, user_id);
	}

	async fetchById(id: number, query: QueryGetAccessProfileDto = {}) {
		return VividusAPI.getAccessProfile(id, query);
	}

	async list(query: QueryGetAccessProfilePaginatedDto = {}) {
		return VividusAPI.getAccessProfilePaginated(query);
	}
}
