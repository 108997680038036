import React from "react";
import { Table } from "react-bootstrap";

const BasicTable: React.FC<{
	headers: string[];
	striped?: boolean;
	bordered?: boolean;
	hover?: boolean;
}> = ({ headers, children, striped, bordered, hover }) => (
	<Table
		responsive
		className="mb-0"
		striped={striped}
		bordered={bordered}
		hover={hover}
	>
		<thead>
			<tr>
				{headers.map((header) => (
					<th key={header}>{header}</th>
				))}
			</tr>
		</thead>
		<tbody>{children}</tbody>
	</Table>
);

export default BasicTable;
