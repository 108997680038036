export enum Role {
	// Perfil de Acesso
	ADM_CREATE_ACCESS_PROFILE = "adm-create-access-profile",
	ADM_UPD_ACCESS_PROFILE = "adm-upd-access-profile",
	ADM_READ_ACCESS_PROFILE = "adm-read-access-profile",
	ADM_DEL_ACCESS_PROFILE = "adm-del-access-profile",

	ADM_ADD_ACCESS_PROFILE = "adm-add-access-profile",
	ADM_RMV_ACCESS_PROFILE = "adm-rmv-access-profile",

	// Usuário
	ADM_CREATE_USER = "adm-create-user",
	ADM_UPD_USER = "adm-upd-user",
	ADM_READ_USER = "adm-read-user",
	ADM_DEL_USER = "adm-del-user",

	// Assinatura
	ADM_CREATE_SUBSCRIPTION = "adm-create-subscription",
	ADM_UPD_SUBSCRIPTION = "adm-upd-subscription",
	ADM_READ_SUBSCRIPTION = "adm-read-subscription",
	ADM_DEL_SUBSCRIPTION = "adm-del-subscription",

	// Lote de Faturamento
	ADM_CREATE_BILLING_LOT = "adm-create-billing-lot",
	ADM_UPD_BILLING_LOT = "adm-upd-billing-lot",
	ADM_READ_BILLING_LOT = "adm-read-billing-lot",
	ADM_DEL_BILLING_LOT = "adm-del-billing-lot",

	// Configurações do sistema
	ADM_READ_SYS_CONFIG = "adm-read-sys-config",

	// Acesso ao sistema
	APP_ACCESS = "app-access",
	WEB_APP_ACCESS = "web-app-access",
	HEALTHSTAFF_CHAT_ACCESS = "healthstaff-chat-access",
	PROVIDER_MARKETPLACE_ACCESS = "provider-marketplace-acccess",

	// Tipo de Usuário
	IS_HEALTHSTAFF = "is-healthstaff",
	IS_MEMBER = "is-member",
	IS_PROVIDER = "is-provider",
	IS_SELLER = "is-seller",

	// Relatórios
	REPORT_SELLER_COMMISSION = "report-seller-commission",
}
