import React, { useCallback, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import Dinero from "dinero.js";
import { Data } from "react-csv/components/CommonPropTypes";
import BasicTable from "../../../../components/BasicTable";
import BasicColumn from "../../../../components/BasicTable/BasicColumn";
import BasicRow from "../../../../components/BasicTable/BasicRow";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import useToast from "../../../../hooks/Toast";
import {
	formatToLocaleCurrency,
	parseDineroToUnitString,
} from "../../../../utils/currency";
import { maskCpf, maskDate } from "../../../../utils/string";
import DashTitle from "../../components/DashTitle";
import { useAuth } from "../../../../contexts/AuthContext";
import Seller from "../../../../modules/finance/seller/entities/Seller";
import SellerGroupService from "../../../../modules/finance/seller-group/SellerGroupService";
import SellerGroup from "../../../../modules/finance/seller-group/entities/SellerGroup";
import SellerCommissionReportService from "../../../../modules/report/seller-commission-report/SellerCommissionReportService";
import QueryGetSellerCommissionReportDto from "../../../../apis/VividusAPI/dto/QueryGetSellerCommissionReportDto";
import ResponseSellerCommissionReportDto from "../../../../apis/VividusAPI/dto/ResponseSellerCommissionReportDto";

const sellerGroupService = new SellerGroupService();
const service = new SellerCommissionReportService();

const SellerCommissionPanel: React.FC = () => {
	const { isMaster, hasPermission, auth } = useAuth();
	const { showError } = useToast();

	const [formRangeDate, setFormRangeDate] = useState<[Date | null, Date | null]>(
		[null, null]
	);
	const [formSellersList, setFormSellersList] = useState<Seller[]>([]);
	const [formSeller, setFormSeller] = useState("");
	const [formSellersGroupList, setFormSellersGroupList] = useState<
		SellerGroup[]
	>([]);
	const [dataCSV, setDataCSV] = useState<Data>();
	const [formSellerGroup, setFormSellerGroup] = useState("");

	const [blocked, setBlocked] = useState(false);
	const [loading, setLoading] = useState(true);
	const [reportList, setReportList] =
		useState<ResponseSellerCommissionReportDto[]>();

	const onFormSellerChange = (value: string) => setFormSeller(value);
	const onFormSellerGroupChange = (value: string) => setFormSellerGroup(value);

	/**
	 * Valida se o usuário logado possui a permissão de vendedor e não é master.
	 * Utilizado para exibir ou não o filtro de Grupo de Vendedores, assim como
	 * filtrar pelo grupo definido no usuário.
	 *
	 * @returns verdadeiro se o usuário logado for vendedor.
	 */
	const isSeller = useCallback(
		() => hasPermission("is-seller") && !isMaster(),
		[hasPermission, isMaster]
	);

	const getFilter = (): QueryGetSellerCommissionReportDto => ({
		sellerGroupId: formSellerGroup.length > 0 ? +formSellerGroup : undefined,
		sellerId: formSeller.length > 0 ? +formSeller : undefined,
		startDate: formRangeDate[0]!,
		endDate: formRangeDate[1]!,
	});

	const handleExportPdf = async () => {
		if (!reportList) return;
		if (blocked) {
			showError(
				"Vendedor sem permissão para gerar o relatório. Contate o suporte. Motivo: Vendedor sem grupo."
			);
			return;
		}
		try {
			setLoading(true);
			await service
				.constructReport(reportList)
				.open({}, window.open("", "_blank"));
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSearchData = async () => {
		if (blocked) {
			showError(
				"Vendedor sem permissão para gerar o relatório. Contate o suporte. Motivo: Vendedor sem grupo."
			);
			return;
		}
		if (formRangeDate[0] === null || formRangeDate[1] === null) {
			showError("Período não informado");
			return;
		}
		try {
			setReportList(undefined);
			setLoading(true);
			setReportList(await service.getReport(getFilter()));
		} catch (error) {
			showError(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!formSellerGroup) {
			setFormSeller("");
			setFormSellersList([]);
			return;
		}
		try {
			setFormSellersList(
				formSellersGroupList.find((g) => g.id === +formSellerGroup)?.sellers || []
			);
		} catch (error) {
			showError(error);
		}
	}, [formSellerGroup, formSellersGroupList, showError]);

	useEffect(() => {
		if (!auth?.user?.id) return;
		if (isSeller()) {
			if (!auth.seller?.group_id) {
				setBlocked(true);
				showError(
					"Vendedor sem permissão para gerar o relatório. Contate o suporte. Motivo: Vendedor sem grupo."
				);
			} else {
				const { group_id } = auth.seller;
				sellerGroupService
					.fetchById(group_id, {
						loadSellers: true,
						loadUsers: true,
					})
					.then((sellerGroup) => {
						setFormSellersGroupList([sellerGroup]);
						setFormSellerGroup(group_id.toString());
					})
					.catch((error) => {
						showError(error);
						setLoading(false);
					});
			}
			setLoading(false);
		} else {
			sellerGroupService
				.list({ loadSellers: true, loadUsers: true, take: 0 })
				.then((pagination) => {
					setFormSellersGroupList(pagination.data);
					setLoading(false);
				})
				.catch((error) => {
					showError(error);
					setLoading(false);
				});
		}
	}, [auth?.user.id, auth?.seller, isSeller, showError]);

	useEffect(() => {
		if (!reportList) {
			setDataCSV(undefined);
			return;
		}
		setDataCSV([
			[
				"nome",
				"tipo_usuario",
				"cpf",
				"vendedor",
				"data",
				"valor",
				"parcela",
				"parcela_atual",
				"tipo_plano",
				"perc_comissao",
				"valor_comissao",
			],
			...reportList.map((item) => [
				item.userName || "",
				service.getDisplayUserType(item.userType),
				maskCpf(item.userCpf),
				item.sellerName,
				DateTime.fromISO(item.registerDate).toFormat("dd/MM/yyyy HH:mm"),
				parseDineroToUnitString(item.value),
				item.installment,
				item.currentInstallment,
				item.planType,
				String(item.commission || 0).replace(".", ","),
				parseDineroToUnitString(item.valueCommission),
			]),
		]);
	}, [reportList]);

	return (
		<Container fluid>
			<DashTitle
				title="Relatório de Comissão por Vendedor"
				showExportPDFButton
				handleExportPDF={handleExportPdf}
				showExportCSVButton
				dataCSV={dataCSV}
				handleExportCSV={() => {
					if (!dataCSV) return false;
					return true;
				}}
			/>
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<Row>
								<Col md={6}>
									<Form>
										{/* Filtro por Período */}
										<Form.Group className="mb-3">
											<Form.Label>Período</Form.Label>
											<DatePicker
												className="form-control"
												selectsRange
												locale="pt-BR"
												onChange={(date) => setFormRangeDate(date)}
												onChangeRaw={(e) => maskDate(e.target.value)}
												startDate={formRangeDate[0]}
												endDate={formRangeDate[1]}
												dateFormat="dd/MM/yyyy"
												isClearable
												clearButtonClassName="clear-form-button"
												clearButtonTitle="Limpar"
												disabled={loading || blocked}
											/>
										</Form.Group>
										{/* Filtro por Grupo de Vendedor */}
										{!isSeller() && (
											<Form.Group className="mb-3">
												<Form.Label>Grupo</Form.Label>
												<Form.Select
													onChange={(e) => onFormSellerGroupChange(e.target.value)}
													disabled={loading || blocked}
												>
													<option value=""> </option>
													{formSellersGroupList.map((group) => (
														<option key={group.id} value={group.id}>
															{group.name}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										)}
										{/* Filtro por Vendedor */}
										<Form.Group className="mb-3">
											<Form.Label>Vendedor</Form.Label>
											<Form.Select
												onChange={(e) => onFormSellerChange(e.target.value)}
												disabled={loading || blocked || formSellersList.length === 0}
											>
												<option value=""> </option>
												{formSellersList.map((seller) => (
													<option key={seller.id} value={seller.id}>
														{seller.user.name}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										{/* Botão de Gerar Relatório */}
										<Button
											type="submit"
											variant="primary"
											onClick={(e) => {
												e.preventDefault();
												handleSearchData();
											}}
											disabled={loading || blocked}
										>
											Gerar Relatório
										</Button>
									</Form>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{loading && <LoadingSpinner />}
			{reportList && reportList.length === 0 && (
				<Alert variant="info">Registro não encontrado.</Alert>
			)}
			{reportList && reportList.length > 0 && (
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<BasicTable
									headers={[
										"Nome",
										"Tipo de Usuário",
										"CPF",
										"Vendedor",
										"Data",
										"Valor",
										"Parcela",
										"Parc. Atual",
										"Tipo de Plano",
										"% Comissão",
										"Valor da Comissão (R$)",
									]}
								>
									{reportList.map((item) => (
										<BasicRow
											key={`${item.userName}-${item.planType}-${item.sellerName}-${item.currentInstallment}`}
										>
											<BasicColumn>{item.userName}</BasicColumn>
											<BasicColumn>
												{service.getDisplayUserType(item.userType)}
											</BasicColumn>
											<BasicColumn>{maskCpf(item.userCpf)}</BasicColumn>
											<BasicColumn>{item.sellerName}</BasicColumn>
											<BasicColumn>
												{DateTime.fromISO(item.registerDate).toFormat("dd/MM/yyyy HH:mm")}
											</BasicColumn>
											<BasicColumn>{formatToLocaleCurrency(item.value)}</BasicColumn>
											<BasicColumn>{item.installment}</BasicColumn>
											<BasicColumn>{item.currentInstallment}</BasicColumn>
											<BasicColumn>{item.planType}</BasicColumn>
											<BasicColumn>{Math.round(item.commission * 100)}</BasicColumn>
											<BasicColumn>
												{formatToLocaleCurrency(item.valueCommission)}
											</BasicColumn>
										</BasicRow>
									))}
									{(() => {
										const { value: val, valueCommission: valCom } = reportList
											.map(({ value, valueCommission }) => ({ value, valueCommission }))
											.reduce((prev, current) => ({
												value: Dinero({ amount: prev?.value ?? 0 })
													.add(Dinero({ amount: current.value }))
													.getAmount(),
												valueCommission: Dinero({ amount: prev?.valueCommission ?? 0 })
													.add(Dinero({ amount: current.valueCommission }))
													.getAmount(),
											}));
										return (
											<BasicRow>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn>
													<strong>{formatToLocaleCurrency(val)}</strong>
												</BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn> </BasicColumn>
												<BasicColumn>
													<strong>{formatToLocaleCurrency(valCom)}</strong>
												</BasicColumn>
											</BasicRow>
										);
									})()}
								</BasicTable>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default SellerCommissionPanel;
