/* eslint-disable react/jsx-boolean-value */
import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import ReportsPanel from "../pages/DashboardPage/ReportsPanel";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import ReferralCodePage from "../pages/ReferralCodePage";
import NotFound from "../pages/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import SellerCommissionPanel from "../pages/DashboardPage/ReportsPanel/SellerCommissionPanel";
import HomePanel from "../pages/DashboardPage/HomePanel";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import AdminPanel from "../pages/DashboardPage/AdminPanel";
import AccessProfilePanel from "../pages/DashboardPage/AdminPanel/AccessProfilePanel";
import AccessProfileModalPanel from "../pages/DashboardPage/AdminPanel/AccessProfileModalPanel";
import BillingPanel from "../pages/DashboardPage/BillingPanel";
import NewBillingLotPanel from "../pages/DashboardPage/BillingPanel/NewBillingLotPanel";
import BillingLotPanel from "../pages/DashboardPage/BillingPanel/BillingLotPanel";
import BillingLotModalPanel from "../pages/DashboardPage/BillingPanel/BillingLotModalPanel";
import NewUser from "../pages/DashboardPage/AdminPanel/NewUser";
import UserPanel from "../pages/DashboardPage/AdminPanel/UserPanel";
import UserModalPanel from "../pages/DashboardPage/AdminPanel/UserModalPanel";
import ConfigurationModalPanel from "../pages/DashboardPage/SystemPanel/ConfigurationModalPanel";
import ConfigurationPanel from "../pages/DashboardPage/SystemPanel/ConfigurationPanel";
import SystemPanel from "../pages/DashboardPage/SystemPanel";
import PrivatePage from "../pages/PrivatePage";
import ForbiddenPage from "../pages/ForbiddenPage";
import { Role } from "../modules/security/permission/enum/role.enum";

const Routes: React.FC = () => {
	const { signed } = useAuth();
	return (
		<ReactRoutes>
			<Route path="*" element={<NotFound />} />

			<Route path="forbidden" element={<ForbiddenPage />} />
			<Route path="referral" element={<ReferralCodePage />} />

			<Route path="login" element={<LoginPage />} />
			<Route path="reset-password" element={<ResetPasswordPage />} />
			<Route element={<PrivateRoute signed={signed} />}>
				<Route path="" element={<DashboardPage />}>
					{/* Início */}
					<Route path="" element={<HomePanel />} />

					{/* Menu de Configurações */}
					<Route
						path="system"
						element={
							<PrivatePage permissions={[Role.ADM_READ_SYS_CONFIG]}>
								<SystemPanel />
							</PrivatePage>
						}
					>
						<Route path="configuration">
							<Route path="" element={<ConfigurationPanel />} />
							<Route path=":id" element={<ConfigurationModalPanel />} />
						</Route>
					</Route>

					{/* Menu de Administração */}
					<Route path="administration" element={<AdminPanel />}>
						<Route path="access-profile">
							<Route
								path=""
								element={
									<PrivatePage permissions={[/adm-.*-access-profile/]}>
										<AccessProfilePanel />
									</PrivatePage>
								}
							/>
							<Route
								path=":id"
								element={
									<PrivatePage permissions={[Role.ADM_READ_ACCESS_PROFILE]}>
										<AccessProfileModalPanel />
									</PrivatePage>
								}
							/>
						</Route>
						<Route path="user">
							<Route
								path=""
								element={
									<PrivatePage permissions={[/adm-.*-user/]}>
										<UserPanel />
									</PrivatePage>
								}
							/>
							<Route
								path="new"
								element={
									<PrivatePage permissions={[Role.ADM_CREATE_USER]}>
										<NewUser />
									</PrivatePage>
								}
							/>
							<Route
								path=":id"
								element={
									<PrivatePage permissions={[Role.ADM_READ_USER]}>
										<UserModalPanel />
									</PrivatePage>
								}
							/>
						</Route>
					</Route>

					{/* Menu de Faturamento */}
					<Route
						path="billing"
						element={
							<PrivatePage permissions={[/adm-.*-billing-lot/]}>
								<BillingPanel />
							</PrivatePage>
						}
					>
						<Route path="billing-lot">
							<Route path="" element={<BillingLotPanel />} />
							<Route
								path="new"
								element={
									<PrivatePage permissions={[Role.ADM_CREATE_BILLING_LOT]}>
										<NewBillingLotPanel />
									</PrivatePage>
								}
							/>
							<Route
								path=":id"
								element={
									<PrivatePage permissions={[Role.ADM_READ_BILLING_LOT]}>
										<BillingLotModalPanel />
									</PrivatePage>
								}
							/>
						</Route>
					</Route>

					{/* Menu de Relatórios */}
					<Route path="reports" element={<ReportsPanel />}>
						<Route
							path="seller-commission"
							element={
								<PrivatePage permissions={[Role.REPORT_SELLER_COMMISSION]}>
									<SellerCommissionPanel />
								</PrivatePage>
							}
						/>
					</Route>
				</Route>
			</Route>
		</ReactRoutes>
	);
};

export default Routes;
