import React from "react";
import { logEvent } from "firebase/analytics";
import logo from "../../assets/images/badge-app-store.svg";
import { getAnalytics } from "../../utils/firebase";
import { composeAppStoreUrl } from "../../utils/mobile-store-util";

interface AppStoreBadgeProps {
	referralCode?: string;
}

const AppStoreBadge: React.FC<AppStoreBadgeProps> = ({ referralCode }) => (
	<a
		href={composeAppStoreUrl()}
		target="_blank"
		rel="noreferrer noopener"
		onClick={() => {
			logEvent(getAnalytics(), "badge_click", {
				store: "app-store",
				referralCode: referralCode || "SITE",
			});
		}}
	>
		<img src={logo} alt="App Store Logo" />
	</a>
);

AppStoreBadge.defaultProps = {
	referralCode: undefined,
};

export default AppStoreBadge;
