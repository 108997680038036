import Dinero from "dinero.js";

export const formatToLocaleCurrency = (value: number): string => {
	if (typeof value === "undefined") return "";
	return Dinero({ amount: value }).toFormat();
};

export const parseDineroToUnitString = (value: number) => {
	if (typeof value === "undefined") return "";
	return String(Dinero({ amount: value }).toUnit()).replace(".", ",");
};
