import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import VividusCardLogo from "../Logo/VividusCardLogo";

const VividusCardPage: React.FC = ({ children }) => (
	<main className="authentication-bg">
		<div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
			<Container>
				<Row className="justify-content-center">
					<Col xxl={4} lg={5}>
						<Card>
							{/* Logo */}
							<VividusCardLogo />
							{/* Body */}
							<Card.Body className="p-4">{children as any}</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	</main>
);

export default VividusCardPage;
