import VividusAPI from "../../../apis/VividusAPI";

export default class AuthService {
	syncFirebaseAuth(user_id: number) {
		return VividusAPI.putSyncFirebaseAuth(user_id);
	}

	fetchToken(auth_id: string) {
		return VividusAPI.postLoginToken(auth_id);
	}

	getVerifyByEmail(email: string) {
		return VividusAPI.getVerifyMember({ email });
	}
}
