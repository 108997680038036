import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getVersion } from "../../../../config";

const getFooterTitle = () =>
	`${new Date().getFullYear()} © Vívidus | ${getVersion()}`;

const FooterBar: React.FC<{
	type?: "summary" | "dashboard";
}> = ({ type = "dashboard" }) => (
	<footer className={`footer ${type === "summary" ? "footer-alt" : ""}`}>
		{type === "summary" ? (
			getFooterTitle()
		) : (
			<Container fluid>
				<Row>
					<Col md={6}>{getFooterTitle()}</Col>
					<Col md={6}>
						<div className="text-md-end footer-links d-none d-md-block">
							<a href="https://www.vividus.com.br/" rel="noreferrer" target="_blank">
								Sobre
							</a>
							<a
								href={`https://wa.me/${process.env.REACT_APP_WPP_NUMBER || ""}`}
								rel="noreferrer"
								target="_blank"
							>
								Suporte
							</a>
						</div>
					</Col>
				</Row>
			</Container>
		)}
	</footer>
);

FooterBar.defaultProps = {
	type: "dashboard",
};

export default FooterBar;
