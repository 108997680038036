import VividusAPI from "../../../apis/VividusAPI";
import BodyPatchUserDto from "../../../apis/VividusAPI/dto/BodyPatchUserDto";
import BodyPostUserDto from "../../../apis/VividusAPI/dto/BodyPostUserDto";
import QueryGetUserPaginatedDto from "../../../apis/VividusAPI/dto/QueryGetUserPaginatedDto";

export default class UserService {
	createUser(body: BodyPostUserDto) {
		return VividusAPI.postUser(body);
	}

	updateUser(id: number, body: BodyPatchUserDto) {
		return VividusAPI.patchUser(id, body);
	}

	syncHealthmap(id: number) {
		return VividusAPI.putSyncHealthmap(id);
	}

	fetchById(id: number, accesstoken?: string) {
		return VividusAPI.getUser(
			id,
			{
				loadProvider: true,
				loadSeller: true,
				loadSellerGroup: true,
				loadAccesProfiles: true,
				loadPermissions: true,
				loadSubscription: true,
				loadPlan: true,
				loadCustomer: true,
				loadHolder: true,
				loadDependents: true,
				loadDependentPermissions: true,
			},
			accesstoken
		);
	}

	list(query: QueryGetUserPaginatedDto = {}) {
		return VividusAPI.getUserPaginated(query);
	}

	async activate(id: number) {
		return VividusAPI.postUserActivate(id);
	}

	async inactivate(id: number) {
		return VividusAPI.deleteUserInactivate(id);
	}

	fetchAddress(zipcode: string) {
		return VividusAPI.getAddress(zipcode);
	}
}
